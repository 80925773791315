import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiStore } from '../../../web-services/api/api.store';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../web-services/api/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { GoogleMapsModule } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-create-site-modal',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,CommonModule,FormsModule,ReactiveFormsModule,NgbModule,
    MatExpansionModule,NgxMaterialTimepickerModule,MatTabsModule,GoogleMapsModule,NgxGpAutocompleteModule
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
  templateUrl: './create-site-modal.component.html',
  styleUrl: './create-site-modal.component.scss'
})
export class CreateSiteModalComponent {
  userLon;
  userLat;
  ElForm: FormGroup;
  currentLang;
	translationsObj;
  rtlStr:string = "ltr";
  showVolume:boolean = true;
  isVolumeBased:boolean = false;
  rtl:boolean = false;
  workPlanDistrebutionMethods:Array<object> = [];
  customerWorkPlanCollectionschedulePolicy:Array<object> = [];
  accountsList:Array<object> = [];
  coordinates: Coordinates;
  seedData:Array<object> = [];
  dynamicForm: FormGroup;
  workPlanOpenState: boolean = false;
  advancedSettingsOpenState: boolean = false;
  alertsOpenState: boolean = false;
  chosenStartAddress: any;
  chosenFinishAddress: any;
  chosenDumpAddress: any;
  showDefaultIconsInStartPointMap:boolean = false;
  showDefaultIconsInFinishPointMap:boolean = false;
  showDefaultIconsInUnloadingPointMap:boolean = false;
  markersArrayStartPoint = [];
	markersArrayFinishPoint = [];
	markersArrayDumpPoint = [];
  mapForStartPoint:any;
  mapForFinishPoint:any;
  mapForUnloadingPoint:any;
  selectedTab:number = 0;
  user_role_id = 0;
  submitClicked : boolean = false;
  mapOptionsPc: google.maps.MapOptions = {
    streetViewControl: false,    
    disableDefaultUI:false,  
    fullscreenControl : false,    
    zoomControl : false
  };
  points : Array<object> = [
    {
      label : 'start',
      latitude : 0.00000000,
      longitude : 0.00000000
    },
  
    {
      label : 'finish',
      latitude : 0.00000000,
      longitude : 0.00000000
    },
  
    {
      label : 'dump',
      latitude : 0.00000000,
      longitude : 0.00000000
    }
  ]
  staticTheme = {
    container: {
    },
    dial: {
      dialBackgroundColor: '#28B365',
    },
    clockFace: {
      clockHandColor: '#28B365',
    }
  };
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  constructor(private translator: TranslatorService,private apiQuery:ApiQuery,
    public fb: FormBuilder,private apiService:ApiService,private apiStore:ApiStore,private modalService: NgbModal,
    private dialogRef: MatDialogRef<CreateSiteModalComponent>) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;	
      this.rtl = this.translator.isRTL()
      if (this.rtl) {
        this.rtlStr = "rtl";
      }
    })
    this.coordinates = {} as Coordinates;

    this.ElForm = this.fb.group({
      customerAccount:new FormControl('', Validators.required),
      siteName: new FormControl('', Validators.required),
      WorkPlanDistrebutionMethods:new FormControl('', Validators.required),
      customerWorkPlanCollectionschedulePolicy:new FormControl('', Validators.required),
      startLonLat: '',
      finishLonLat: '',
      dumpLonLat: '',
      useSiteMaxAllowedCapacity:new FormControl('', Validators.required),
      capacity:'',
      isVolumeBasedSort:false,
      daysGapForNoCollections:new FormControl('', Validators.required),
      minAverageVolume:new FormControl('', Validators.required),
      maxNumberDaysForecast:new FormControl('', Validators.required),
    });

    this.dynamicForm = this.fb.group({
      shifts: this.fb.array([]),
    });
   }

   ngOnInit() {
    this.seedData = [];
    this.submitClicked = false;
    this.seedFiltersFormArray();
    this.ElForm.controls["customerAccount"].setValue("");    
    this.ElForm.controls["siteName"].setValue("");    
    this.ElForm.controls["WorkPlanDistrebutionMethods"].setValue(""); 
    this.ElForm.controls["daysGapForNoCollections"].setValue("");    
    this.ElForm.controls["capacity"].setValue("");    
    this.ElForm.controls["isVolumeBasedSort"].setValue(false);
    this.ElForm.controls["minAverageVolume"].setValue("");    
    this.ElForm.controls["maxNumberDaysForecast"].setValue("");    
    this.ElForm.controls["customerWorkPlanCollectionschedulePolicy"].setValue("");   
    this.ElForm.controls["useSiteMaxAllowedCapacity"].setValue("")

    this.apiQuery.user$.subscribe(user => {
      if (!user) {
        return;
      }		
      this.user_role_id = user["user_role_id"];
      if(this.user_role_id == 5){
        this.apiService.getCustomerAccounts();
        this.apiQuery.customerAccounts$.subscribe((customerAccounts) => {
          if(!customerAccounts){return;}
          this.accountsList = customerAccounts;
        });
      }else{
        this.apiService.getUserinfo();
        this.apiQuery.accountsList$.subscribe((accountsList) => {
          if(!accountsList){return;}
          this.accountsList = accountsList;
        });
      }
    });  

    this.apiQuery.workPlanDistrebutionMethods$.subscribe((workPlanDistrebutionMethods) => {
      if(!workPlanDistrebutionMethods){return;}
      this.workPlanDistrebutionMethods = workPlanDistrebutionMethods;
    });

    this.apiQuery.customerWorkPlanCollectionschedulePolicy$.subscribe((customerWorkPlanCollectionschedulePolicy) => {
      if(!customerWorkPlanCollectionschedulePolicy){return;}
      this.customerWorkPlanCollectionschedulePolicy = customerWorkPlanCollectionschedulePolicy;
    });

     if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLon = position.coords.longitude;
        this.userLat = position.coords.latitude;
      });
    }

    this.ElForm.controls["startLonLat"].setValue("");   
    this.ElForm.controls["finishLonLat"].setValue("");   
    this.ElForm.controls["dumpLonLat"].setValue(""); 
  }

  tabChanged(index){
    this.applyFilter();
  }

  resetMarkersFromMaps(){
		_.each(this.markersArrayStartPoint, (b) => {
			b["visible"] = false;
		});
    _.each(this.markersArrayFinishPoint, (b) => {
			b["visible"] = false;
		});
    _.each(this.markersArrayDumpPoint, (b) => {
			b["visible"] = false;
		});
  }

  applyFilter(){
    this.resetMarkersFromMaps()

    this.changeStartPointLoc();
    this.changeFinishPointLoc();
    this.changeDumpPointLoc();

    this.loadAPIWrapperForStartPoint(this.mapForStartPoint);
    this.loadAPIWrapperForFinishPoint(this.mapForFinishPoint);
    this.loadAPIWrapperForUnloadingPoint(this.mapForUnloadingPoint);
  }
  
  markerStartPointDragEnd($event: any) {
    this.points[0]["latitude"] = $event.latLng.lat();
    this.points[0]["longitude"] = $event.latLng.lng();
    const lonLat = this.points[0]["latitude"].toFixed(8) + "," + this.points[0]["longitude"].toFixed(8);
    this.ElForm.controls["startLonLat"].setValue(lonLat); 
    this.applyFilter();
  }

  markerFinishPointDragEnd($event: any) {
    this.points[1]["latitude"] = $event.latLng.lat();
    this.points[1]["longitude"] = $event.latLng.lng();
    const lonLat = this.points[1]["latitude"].toFixed(8) + "," + this.points[1]["longitude"].toFixed(8);
    this.ElForm.controls["finishLonLat"].setValue(lonLat); 
    this.applyFilter();
  }

  markerDumpPointDragEnd($event: any) {
    this.points[2]["latitude"] = $event.latLng.lat();
    this.points[2]["longitude"] = $event.latLng.lng();
    const lonLat = this.points[2]["latitude"].toFixed(8) + "," + this.points[2]["longitude"].toFixed(8);
    this.ElForm.controls["dumpLonLat"].setValue(lonLat); 
    this.applyFilter();
  }
  
  setStartCurrentLocation(lon,lat) {
    this.points[0]["latitude"] = Number(parseFloat(lat).toFixed(8));
    this.points[0]["longitude"] = Number(parseFloat(lon).toFixed(8));
    const lonLat = Number(this.points[0]["latitude"]).toFixed(8) + "," + Number(this.points[0]["longitude"]).toFixed(8);
    this.ElForm.controls["startLonLat"].setValue(lonLat);    
  }

  setFinishCurrentLocation(lon,lat) {
    this.points[1]["latitude"] = Number(parseFloat(lat).toFixed(8));
    this.points[1]["longitude"] = Number(parseFloat(lon).toFixed(8));
    const lonLat = Number(this.points[1]["latitude"]).toFixed(8) + "," + Number(this.points[1]["longitude"]).toFixed(8);
    this.ElForm.controls["finishLonLat"].setValue(lonLat);    
  }


  setDumpCurrentLocation(lon,lat) {
    this.points[2]["latitude"] = Number(parseFloat(lat).toFixed(8));
    this.points[2]["longitude"] = Number(parseFloat(lon).toFixed(8));
    const lonLat = Number(this.points[2]["latitude"]).toFixed(8) + "," + Number(this.points[2]["longitude"]).toFixed(8);
    this.ElForm.controls["dumpLonLat"].setValue(lonLat);    
  }

  mapRelevantBinsByCoordinates(bins,label) {
		const binsHash = {};
		_.each(bins, b => {
      if(b.label == label){
        if (binsHash[b.latitude+","+b.longitude] !== undefined) {
          binsHash[b.latitude+","+b.longitude].push(b);
        } else {
          binsHash[b.latitude+","+b.longitude] = [b];
        }
      }
		});
    return binsHash;
	}

  mapBinsByCoordinates(bins) {
		const binsHash = {};
		_.each(bins, b => {
			if (binsHash[b.latitude+","+b.longitude] !== undefined) {
				binsHash[b.latitude+","+b.longitude].push(b);
			} else {
				binsHash[b.latitude+","+b.longitude] = [b];
			}
		});
    return binsHash;
	}

  loadAPIWrapperForStartPoint(map: any){
    this.mapForStartPoint = map;
    this.setStartCurrentLocation(this.points[0]["longitude"],this.points[0]["latitude"]);
    let binsHash;
    if(!this.showDefaultIconsInStartPointMap){
      binsHash = this.mapBinsByCoordinates(this.points);	
    }else{
      binsHash = this.mapRelevantBinsByCoordinates(this.points,'start');	
    }
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
        let icon = '';
        if(value.length == 2){
          if(this.ifStartFinish(value)){
            icon = '../../../../assets/images/inventory/start_finish_combined.svg';
          }else if(this.ifDumpFinish(value)){
            icon = '../../../../assets/images/inventory/dump_finish_combined_not_selected.svg';
          }else if(this.ifStartDump(value)){
            icon = '../../../../assets/images/inventory/start_dump_combined.svg';
          }
        }else{
          icon = '../../../../assets/images/inventory/start_finish_dump_combined.svg';
        }   				
        const marker = {
          icon: icon,
          lat: Number(value[0].latitude),
          lng: Number(value[0].longitude),				
          visible: true,
          draggable: !this.showDefaultIconsInStartPointMap ? false : true
        };	
        this.markersArrayStartPoint.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.startPointToIcon(b.label);
					
					const marker = {
						icon: icon,
						lat: Number(b.latitude),
						lng: Number(b.longitude),				
						visible: true,
            draggable: !this.showDefaultIconsInStartPointMap ? false : true
					};	
          this.markersArrayStartPoint.push(marker);
				});
			}
		});	
    this.boundMapForStartPoint(this.mapForStartPoint,this.markersArrayStartPoint);
  }

  startPointToIcon(label){
    if(label == 'start'){
      return '../../../../assets/images/inventory/start-point-yes-active.svg';
    }else if(label == 'finish'){
      return '../../../../assets/images/inventory/finish-point-no-active.svg';
    }else{
      return '../../../../assets/images/inventory/unloading-point-no-active.svg';
    }
  }

  loadAPIWrapperForFinishPoint(map: any){
    this.mapForFinishPoint = map;
    this.setFinishCurrentLocation(this.points[1]["longitude"],this.points[1]["latitude"]);
    let binsHash;
    if(!this.showDefaultIconsInFinishPointMap){
      binsHash = this.mapBinsByCoordinates(this.points);		
    }else{
      binsHash = this.mapRelevantBinsByCoordinates(this.points,'finish');	
    }
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
        let icon = '';
        if(value.length == 2){
          if(this.ifStartFinish(value)){
            icon = '../../../../assets/images/inventory/finish_start_combined.svg';
          }else if(this.ifDumpFinish(value)){
            icon = '../../../../assets/images/inventory/finish_dump_combined.svg';
          }else if(this.ifStartDump(value)){
            icon = '../../../../assets/images/inventory/start_dump_combined_not_selected.svg';
          }
        }else{
          icon = '../../../../assets/images/inventory/finish_start_dump_combined.svg';
        }   				
        const marker = {
          icon: icon,
          lat: Number(value[0].latitude),
          lng: Number(value[0].longitude),				
          visible: true,
          draggable: !this.showDefaultIconsInFinishPointMap ? false : true
        };	
        this.markersArrayFinishPoint.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.finishPointToIcon(b.label);
					
					const marker = {
						icon: icon,
						lat: Number(b.latitude),
						lng: Number(b.longitude),				
						visible: true,
            draggable: !this.showDefaultIconsInFinishPointMap ? false : true
					};	
          this.markersArrayFinishPoint.push(marker);
				});
			}
		});	
    this.boundMapForFinishPoint(this.mapForFinishPoint,this.markersArrayFinishPoint);
  }

  finishPointToIcon(label){
    if(label == 'start'){
      return '../../../../assets/images/inventory/start-point-no-active.svg';
    }else if(label == 'finish'){
      return '../../../../assets/images/inventory/finish-point-yes-active.svg';
    }else{
      return '../../../../assets/images/inventory/unloading-point-no-active.svg';
    }
  }

  loadAPIWrapperForUnloadingPoint(map: any){
    this.mapForUnloadingPoint = map;
    this.setDumpCurrentLocation(this.points[2]["longitude"],this.points[2]["latitude"]);
    let binsHash;
    if(!this.showDefaultIconsInUnloadingPointMap){
      binsHash = this.mapBinsByCoordinates(this.points);		
    }else{
      binsHash = this.mapRelevantBinsByCoordinates(this.points,'dump');	
    }
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
        let icon = '';
        if(value.length == 2){
          if(this.ifStartFinish(value)){
            icon = '../../../../assets/images/inventory/start_finish_combined_not_selected.svg';
          }else if(this.ifDumpFinish(value)){
            icon = '../../../../assets/images/inventory/dump_finish_combined.svg';
          }else if(this.ifStartDump(value)){
            icon = '../../../../assets/images/inventory/dump_start_combined.svg';
          }
        }else{
          icon = '../../../../assets/images/inventory/dump_start_finish_combined.svg';
        }   				
        const marker = {
          icon: icon,
          lat: Number(value[0].latitude),
          lng: Number(value[0].longitude),				
          visible: true,
          draggable: !this.showDefaultIconsInUnloadingPointMap ? false : true
        };	
        this.markersArrayDumpPoint.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.dumpPointToIcon(b.label);
					
					const marker = {
						icon: icon,
						lat: Number(b.latitude),
						lng: Number(b.longitude),				
						visible: true,
            draggable: !this.showDefaultIconsInUnloadingPointMap ? false : true
					};	
          this.markersArrayDumpPoint.push(marker);
				});
			}
		});	
    this.boundMapForDumpPoint(this.mapForUnloadingPoint,this.markersArrayDumpPoint);
  }

  toggleDefaultIconsInStartPointMap(){
    this.showDefaultIconsInStartPointMap = !this.showDefaultIconsInStartPointMap;
    _.each(this.markersArrayStartPoint, (b) => {
			b["visible"] = false;
		});
    this.loadAPIWrapperForStartPoint(this.mapForStartPoint);
  }

  toggleDefaultIconsInFinishPointMap(){
    this.showDefaultIconsInFinishPointMap = !this.showDefaultIconsInFinishPointMap;
    _.each(this.markersArrayFinishPoint, (b) => {
			b["visible"] = false;
		});
    this.loadAPIWrapperForFinishPoint(this.mapForFinishPoint);
  }

  toggleDefaultIconsInUnloadingPointMap(){
    this.showDefaultIconsInUnloadingPointMap = !this.showDefaultIconsInUnloadingPointMap;
    _.each(this.markersArrayDumpPoint, (b) => {
			b["visible"] = false;
		});
    this.loadAPIWrapperForUnloadingPoint(this.mapForUnloadingPoint);
  }

  ifStartFinish(arr){
    return arr[0].label == "start" && arr[1].label == "finish";
  }

  ifStartDump(arr){
    return arr[0].label == "start" && arr[1].label == "dump";
  }

  ifDumpFinish(arr){
    return arr[0].label == "finish" && arr[1].label == "dump";
  }

  dumpPointToIcon(label){
    if(label == 'start'){
      return '../../../../assets/images/inventory/start-point-no-active.svg';
    }else if(label == 'finish'){
      return '../../../../assets/images/inventory/finish-point-no-active.svg';
    }else{
      return '../../../../assets/images/inventory/unloading-point-yes-active.svg';
    }
  }

  boundMapForStartPoint(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				bounds.extend({ lat: Number(bin.lat), lng: Number(bin.lng) });					
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  boundMapForFinishPoint(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				bounds.extend({ lat: Number(bin.lat), lng: Number(bin.lng) });				
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  boundMapForDumpPoint(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				bounds.extend({ lat: Number(bin.lat), lng: Number(bin.lng) });				
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  handleStartAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenStartAddress = place;
    this.applyFilter();
  }

  handleFinishAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenFinishAddress = place;
    this.applyFilter();
  }

  handleDumpAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenDumpAddress = place;
    this.applyFilter();
  }

  changeStartPointLoc(){
    if(this.ElForm.getRawValue()['startLonLat'].length == 0){
      return;
    }
    const lonLat = this.ElForm.getRawValue()['startLonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenStartAddress.geometry){
        this.points[0]["latitude"] = Number(this.chosenStartAddress.geometry.location.lat().toFixed(8));
        this.points[0]["longitude"] = Number(this.chosenStartAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.points[0]["latitude"] = Number(parseFloat(lonLat[0]).toFixed(8));
      this.points[0]["longitude"] = Number(parseFloat(lonLat[1]).toFixed(8));
    }
  }

  changeFinishPointLoc(){
    if(this.ElForm.getRawValue()['finishLonLat'].length == 0){
      return;
    }
    const lonLat = this.ElForm.getRawValue()['finishLonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenFinishAddress.geometry){
        this.points[1]["latitude"] = Number(this.chosenFinishAddress.geometry.location.lat().toFixed(8));
        this.points[1]["longitude"] = Number(this.chosenFinishAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.points[1]["latitude"] = Number(parseFloat(lonLat[0]).toFixed(8));
      this.points[1]["longitude"] = Number(parseFloat(lonLat[1]).toFixed(8));
    }
  }

  changeDumpPointLoc(){
    if(this.ElForm.getRawValue()['dumpLonLat'].length == 0){
      return;
    }
    const lonLat = this.ElForm.getRawValue()['dumpLonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenDumpAddress.geometry){
        this.points[2]["latitude"] = Number(this.chosenDumpAddress.geometry.location.lat().toFixed(8));
        this.points[2]["longitude"] = Number(this.chosenDumpAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.points[2]["latitude"] = Number(parseFloat(lonLat[0]).toFixed(8));
      this.points[2]["longitude"] = Number(parseFloat(lonLat[1]).toFixed(8));
    }
  }

  seedFiltersFormArray() {
    this.seedData.forEach((seedDatum) => {
      const formGroup = this.createFilterGroup();
      formGroup.patchValue(seedDatum);
      this.filtersFormArray.push(formGroup);
    });
  }

  createFilterGroup() {
    return this.fb.group({
      name: [],
      start_time:[],
      duration: []
    });
  }

  addFilterToFiltersFormArray() {
    this.filtersFormArray.push(this.createFilterGroup());
  }

  removeFilterFromFiltersFormArray(index) {
    this.filtersFormArray.removeAt(index);
  }

  get filtersFormArray() {
    return <FormArray>this.dynamicForm.get('shifts');
  }

  getShiftsNames(){
    let shiftsNames = '';
    _.each(this.dynamicForm.value.shifts, (filter,index) => {
      if(filter.name){
        if(Number(index) == this.dynamicForm.value.shifts.length-1){
          shiftsNames += filter.name;
        }else{
          shiftsNames += filter.name + ',';
        }
      }
    });
    if(this.filtersFormArray.controls.length == 0){
      if(this.translationsObj){
        return this.translationsObj.MANAGE_SITE.NO_SHIFTS;
      }
    }
    return shiftsNames;
  }

  setVolumeType(){
    if(this.ElForm.getRawValue()['useSiteMaxAllowedCapacity'] == 1){
      this.showVolume = true;
      this.ElForm.controls.capacity.setValidators(this.setRequired());
      this.ElForm.controls.capacity.updateValueAndValidity();
    }else{
      this.showVolume = false;
      this.ElForm.controls.capacity.clearValidators();
      this.ElForm.controls.capacity.updateValueAndValidity();
    }   
  }

  setRequired() {
		return [Validators.required];
	}

  closeModal = () => {
		this.dialogRef.close();
  }

  toggleEditableVolume(value){
    this.isVolumeBased = value;
    this.ElForm.controls["isVolumeBasedSort"].setValue(value);
  }

  public saveChanges = () => {
    if(!this.ElForm.valid || this.submitClicked){
			return;
		}
		this.submitClicked = true;

    const request = {   
      "account_id":this.ElForm.getRawValue()['customerAccount'],
      "site_name":this.ElForm.getRawValue()['siteName'],
      "avg_capacity_in_percent_future_work_plan_alert":this.ElForm.getRawValue()['minAverageVolume'].replace("%",""),
      "customer_work_plan_collection_schedule_policy_id":this.ElForm.getRawValue()['customerWorkPlanCollectionschedulePolicy'],
      "days_gap_for_no_collection_alert":this.ElForm.getRawValue()['daysGapForNoCollections'],
      "is_use_site_max_allowed_capacity":this.ElForm.getRawValue()['useSiteMaxAllowedCapacity'],
      "site_max_allowed_capacity":this.ElForm.getRawValue()['useSiteMaxAllowedCapacity'] == 1 ? this.ElForm.getRawValue()['capacity'].replace("%","") : 0,
      "preferred_bin_distribution_method":this.ElForm.getRawValue()['WorkPlanDistrebutionMethods'],
      "site_days_gap_for_future_work_plan":this.ElForm.getRawValue()['maxNumberDaysForecast'],
      "nav_start_point_lat":this.points[0]["latitude"],
      "nav_start_point_lon":this.points[0]["longitude"],
      "nav_finish_point_lat":this.points[1]["latitude"],
      "nav_finish_point_lon":this.points[1]["longitude"],
      "nav_waste_dump_point_lat":this.points[2]["latitude"],
      "nav_waste_dump_point_lon":this.points[2]["longitude"],
      "is_do_volume_based_sort":this.ElForm.getRawValue()['isVolumeBasedSort'] ? 1 : 0,
      "shifts":this.dynamicForm.value.shifts
      }
      this.apiService.insertNewSite(request).subscribe((v:any) => {   
        this.apiStore.update({ allSitesListData: [] }); 
        this.apiService.getSitesList(null);
        this.closeModal();  
     });
  }
}
