import { Component, OnInit } from '@angular/core';
import { TranslatorService } from '../../services/translator_service';
import {ResponsiveService} from "../../services/responsive.service";
import moment from 'moment';
import { ApiService } from '../../web-services/api/api.service';
import * as _ from 'lodash';
import { ApiStore } from '../../web-services/api/api.store';
import { ApiQuery } from '../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import {MatDividerModule} from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { BinstatusComponent } from '../../widgets/binstatus/binstatus.component';
import { ActiveBinsComponent } from '../../widgets/active-bins/active-bins.component';
import { CollectionReportPieComponent } from '../collection-report-pie/collection-report-pie.component';
import { CollectionKpiComponent } from '../collection-kpi/collection-kpi.component';
import { AlertsHistoryComponent } from '../alerts-history/alerts-history.component';
import { MapLiveViewComponent } from '../map-live-view/map-live-view.component';
import { BinListViewComponent } from '../../widgets/bin-list-view/bin-list-view.component';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule,TranslateModule,MatDividerModule,
    BinstatusComponent,ActiveBinsComponent,
    CollectionReportPieComponent,CollectionKpiComponent,AlertsHistoryComponent,
    MapLiveViewComponent,BinListViewComponent,RTLDivDirectiveDirective,NgbModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  map: any;
	translate;
	isMobile: boolean = false;
	showMapCMP:boolean = false;
	showBinStatusCMP:boolean = false;
	showActiveBinsCMP:boolean = false;
	showBinListCMP:boolean = false;
	showCollectionKpisCMP:boolean = false;
	showCollectionReport:boolean = false;
	showAlertsCMP:boolean = false;	
	bins:any;
	usageType = 1;
	translationsObj:any;
	currentLang:any;

	constructor(
		private translator: TranslatorService,
		private apiQuery:ApiQuery,
		private responsiveService: ResponsiveService,
		private apiService:ApiService,
		private router: Router,
		private apiStore:ApiStore) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				this.currentLang = value;
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();

		this.apiQuery.user$.subscribe((user:any) => {
			if (!user) {
				return;
			}	

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}		

			if(user["user_role_id"] == 7){
				this.router.navigate(['/driverplans']);
			}
		});

		this.apiService?.GetWidgets()?.subscribe((widgets:any) => {  
			if(widgets.Widgets != null && widgets.Widgets.length > 0 && widgets != null){				
				_.each(widgets.Widgets, (item) => {	
					if(item.is_activate_for_current_user){				
						this.showComponent(item["widget_id"].toString(),false);
					}
				});	
			}else{
				this.showComponent(1,true);
				this.showComponent(2,true);
				this.showComponent(3,true);
				this.showComponent(4,true);
				this.showComponent(5,true);
				this.showComponent(6,true);
				this.showComponent(7,true);
			}
						
		});

			let collectionsStatsRequest = {
				site_id : 0,
				start_date : moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
				end_date : moment().format('YYYY-MM-DD 23:59:59')
			}

			let collectionsStatsRequestToday = {
				site_id : 0,
				start_date : moment().format('YYYY-MM-DD 00:00:01'),
				end_date : moment().format('YYYY-MM-DD 23:59:59')
			}

			let request = {
				start_date : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
				end_date : moment().format('YYYY-MM-DD 23:59:59')
			}
			this.apiStore.update({ allCollectionsStats: [] });
			this.apiStore.update({ collectionsStats: [] });
			this.apiService.getfilteredBinsCollectionsStats(collectionsStatsRequest,1,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allCollectionsStatsToday: [] });
			this.apiStore.update({ collectionsStatsToday: [] });
			this.apiService.getfilteredBinsCollectionsStatsToday(collectionsStatsRequestToday,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allCollectionsKpis: [] });
			this.apiStore.update({ collectionsKpis: [] });
			this.apiService.getfilteredSiteCollectionsKpis(request,JSON.parse(sessionStorage.getItem("chosenSites")));
		
	}

	showCMPBinStatusHandler($event:any){
		this.showBinStatusCMP = $event;
		this.setWidgetForUser("1",this.showBinStatusCMP)
	}

	showCMPActiveBinsHandler($event:any){
		this.showActiveBinsCMP = $event;
		this.setWidgetForUser("2",this.showActiveBinsCMP)
	}

	showCMPMapHandler($event: any){
		this.showMapCMP = $event;
		this.setWidgetForUser("3",this.showMapCMP)
	}

	showCMPCollectionKPIsHandler($event: any) {
		this.showCollectionKpisCMP = $event;
		this.setWidgetForUser("4",this.showCollectionKpisCMP)		
	}

	showCMPCollectionRepHandler($event: any) {
		this.showCollectionReport = $event;		
		this.setWidgetForUser("5",this.showCollectionReport)		
	}

	showCMPAlertsHandler($event: any) {
		this.showAlertsCMP = $event;	
		this.setWidgetForUser("6",this.showAlertsCMP)			
	}

	showCMPBinListHandler($event:any){
		this.showBinListCMP = $event;
		this.setWidgetForUser("7",this.showBinListCMP)			
	}

	showComponent(value:any,show:any){
		switch(value) {
			case '1':
				this.showBinStatusCMP = !this.showBinStatusCMP;
				if(show){
					this.setWidgetForUser("1",this.showBinStatusCMP)
				}
			  break;
			case '2':
				this.showActiveBinsCMP = !this.showActiveBinsCMP;
				if(show){
					this.setWidgetForUser("2",this.showActiveBinsCMP)
				}
			  break;
			case '3':
				this.showMapCMP = !this.showMapCMP;
				if(show){
					this.setWidgetForUser("3",this.showMapCMP)
				}
				break;
			case '4':
				this.showCollectionKpisCMP = !this.showCollectionKpisCMP;
				if(show){
					this.setWidgetForUser("4",this.showCollectionKpisCMP)		
				}
				break;		
			case '5':
				this.showCollectionReport = !this.showCollectionReport;
				if(show){
					this.setWidgetForUser("5",this.showCollectionReport)		
				}
				break;		
			case '6':
				this.showAlertsCMP = !this.showAlertsCMP;
				if(show){
					this.setWidgetForUser("6",this.showAlertsCMP)			
				}
				break;
			case '7':
				this.showBinListCMP = !this.showBinListCMP;
				if(show){
					this.setWidgetForUser("7",this.showBinListCMP)			
				}
				break;
			default:
		  }
	}

	setWidgetForUser(id:any,command:any){
		let dataToSend : any = {};
		if(command){
			dataToSend["widget_id_list"] = [id];
			dataToSend["is_widget_active"] = 1;
			this.apiService.SetUserWidgets(dataToSend);
		}else{
			dataToSend["widget_id_list"] = [id];
			dataToSend["is_widget_active"] = 0;
			this.apiService.SetUserWidgets(dataToSend);
		}		
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}
}
