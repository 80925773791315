import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiStore } from '../../../web-services/api/api.store';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslatorService } from '../../../services/translator_service';
import _ from 'lodash';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-sites-menu',
  standalone: true,
  imports: [FormsModule,CommonModule,TranslateModule,MatSelectModule,ReactiveFormsModule,MatCheckboxModule,
    RTLDivDirectiveDirective
  ],
  templateUrl: './sites-menu.component.html',
  styleUrl: './sites-menu.component.scss'
})
export class SitesMenuComponent {

  @ViewChild('allSelected',{static:false}) allSelected: MatOption;
	@ViewChild('selectSites',{static:false}) selectSites: MatSelect;

  close: any;
	searchUserForm: FormGroup;
	formValueForUser:string = '';
	sitesListFilter: any[] = [];
	sitesChosen:any;
	translationsObj:any;
  usageType = 1;
	isMobile: boolean = false;
  is_view_unexecuted_work_plans:any = 0;
	isAdmin: boolean = false;
  sitesHierarchy: any[] = [];
  siteHierarchyBackUp: any[] = [];
  constructor(private fb: FormBuilder,private apiService:ApiService,private apiQuery: ApiQuery,
    private translator: TranslatorService,private responsiveService: ResponsiveService,private apiStore:ApiStore) {
			this.searchUserForm = this.fb.group({
				userType: new FormControl("")
			});
   }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe((user:any) => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
      if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
      this.is_view_unexecuted_work_plans = user["is_view_unexecuted_work_plans"];			
		});
  }

  ngAfterViewInit() {          
    this.apiService.getSitesList(null);
    this.apiQuery.allSitesListData$.subscribe((sitesList) => {		      			
      if(sitesList.length == 0) return;
      this.sitesListFilter = sitesList;	 
      this.sitesListFilter.sort(function(a, b){
        if(a.site_name < b.site_name) { return -1; }
        if(a.site_name > b.site_name) { return 1; }
        return 0;
    });
    _.each(this.sitesListFilter, (site:any) => {
        let group:any = {
          letter : '',
          names : []
        };
        if(site.linked_sites_name){
          if (this.sitesHierarchy.some(sitesHierarchy => sitesHierarchy.letter == site.linked_sites_name)) {	
            _.each((this.sitesHierarchy), (sitesHierarchy) => {
              if(sitesHierarchy.letter == site.linked_sites_name){
                sitesHierarchy.names.push(site);
              }
             })
          }else{
            group.letter = site.linked_sites_name;
            group.names.push(site);
          } 
        }else{
          //group.letter = site.site_name;
          group.names.push(site);
        }
        if(group.names.length > 0){
          this.sitesHierarchy.push(group);
        }
    });
      this.siteHierarchyBackUp = this.sitesHierarchy;
      this.allSelected?.select();
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise();
        if(this.allSelected?.selected){
          if(this.searchUserForm.controls['userType'].value.length - 1 > 1){
            this.formValueForUser = `${this.translationsObj.TITLES.MULTI_SITES} (${this.searchUserForm.controls['userType'].value.length-1})`;
          }else if(this.searchUserForm.controls['userType'].value.length - 1 == 1){
            this.formValueForUser = this.searchUserForm.controls['userType'].value[0]["site_name"];
          }
          if(this.sitesListFilter.length == 1){
            this.searchUserForm.controls['userType'].disable();
            const boxes = Array.from(
              document.getElementsByClassName('mat-select-arrow-wrapper')
            );
            boxes.forEach((box:any) => {
              box["style"].display = 'none';
            });
          }else{
            this.searchUserForm.controls['userType'].enable();
            const boxes = Array.from(
              document.getElementsByClassName('mat-select-arrow-wrapper')
            );
            boxes.forEach((box:any) => {
              box["style"].display = 'table-cell';
            });
          }
        }else{
          if(this.searchUserForm.controls['userType'].value.length > 1){			
            this.formValueForUser = `${this.translationsObj.TITLES.MULTI_SITES} (${this.searchUserForm.controls['userType'].value.length})`;
          }else if(this.searchUserForm.controls['userType'].value.length == 1){
            this.formValueForUser = this.searchUserForm.controls['userType'].value[0]["site_name"];
          }
        }
        this.sitesChosen = this.searchUserForm.getRawValue()["userType"];
        sessionStorage.setItem("chosenSites",JSON.stringify(this.sitesChosen));
      });
      this.toggleAllSelection();
    });
    this.applySitesChosen();
	}

  
	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  toggleSelection(event: any, group: any) {
    let states = this.searchUserForm.controls['userType'].value;
    states = states ? states : [];
    if(event.checked) {
      _.each(group.names, (name) => {
        if(!states.some((state:any) => state.site_id == name.site_id)){
          states.push(name)
        }
      });
      group.selected = true;
    }else {
      group.names.forEach((x: string) => states.splice(states.indexOf(x), 1));
      _.each(group.names, (name) => {
        if(states.some((state:any) => state.site_id == name.site_id)){
          states.splice(states.findIndex((obj:any) => obj.site_id == name.site_id), 1);
        }
      });
      group.selected = false;
    }
    this.searchUserForm.controls['userType'].setValue(states);
    this.allSelected?.deselect();
    _.each(this.searchUserForm.controls['userType'].value, (value) => {
      if(value == 0){
        this.searchUserForm.controls['userType'].value.splice(this.searchUserForm.controls['userType'].value.findIndex((obj:any) => obj == 0), 1);
      }
    });
    if (this.searchUserForm.controls['userType'].value.length ==  this.sitesListFilter.length){
      this.allSelected?.select();
      if(!this.searchUserForm.controls['userType'].value.some((value:any) => value == 0)){
        this.searchUserForm.controls['userType'].value.push(0);
      }
    }
  }

  applyFilter(value: any) { 
    let filter = value['value'].toLowerCase();
    _.each(this.sitesHierarchy, (site) => {
      if(!site.names.some((name:any) => name.site_name.toLowerCase().includes(filter))){
        site.display = false;
      }else{
        site.display = true;
      }
    });
  }

    openSelection(){
      this.selectSites?.open();
    }
  
    openedChange(opened: boolean){
      if(opened){
        let allSelectedState = _.find(JSON.parse(sessionStorage.getItem("chosenSites")), function (item) {
          return (item == 0)
        });
 
        if(allSelectedState == 0){
          _.each(this.sitesHierarchy, (site) => {
            site.selected = true;
          });

          this.searchUserForm.controls['userType'].patchValue([
            ...this.sitesListFilter.map(item => item),
            0
          ]);
        }
      }else{
        // setTimeout(() => {
        //   this.apiService.getfilteredBasicBinsInfo(this.sitesChosen);
        // }, 0);
      }
    }

    tosslePerOne(selectedSite:any,all:any) {
      let allHirerachyChecked:any = null;
      _.each(this.sitesHierarchy, (site) => {
        if(site.names.length > 1){
          if(site.names.some((value:any) => value.site_id == selectedSite.site_id)){
            _.each(site.names, (name) => {
              if(this.searchUserForm.controls['userType'].value.some((value:any) => value.site_id == name.site_id)){
                if(allHirerachyChecked == null){
                  allHirerachyChecked = true;
                }
              }else{
                allHirerachyChecked = false;
              }
            });
            if(allHirerachyChecked){
              site.selected = true;
            }else{
              site.selected = false;
            }
          }
        }
      });

      this.allSelected?.deselect();
      if (this.searchUserForm.controls['userType'].value.length ==  this.sitesListFilter.length){
        this.allSelected?.select();
      }
    }
  
    toggleAllSelection() {
      if(this.allSelected?.selected){
        _.each(this.sitesHierarchy, (site) => {
          site.selected = true;
          site.display = true;
        });
        this.searchUserForm.controls['userType'].patchValue([
        ...this.sitesListFilter.map(item => item),
        0
        ]);
      }else{
        this.searchUserForm.controls['userType'].patchValue([]);
        _.each(this.sitesHierarchy, (site) => {
          site.selected = false;
        });
      }
    }
  
    applySitesChosen(){		
      this.selectSites?.close();
      let threeMonthsAgo = {
        site_id : 0,
        start_date : moment().subtract(3, 'months').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:23:59')
      }
      let userGeneratedWorkplans = {
        site_id:0
      }	
      let dailyWorkplan = {
        start_date : moment().subtract(2, 'months').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let collectionsStatsRequest = {
        site_id : 0,
        start_date : moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let fillingsStatsRequest = {
        site_id : 0,
        start_date : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let ticketManagementRequestByDate = {
        status_id : -1
      }
      let collectionsStatsTodayRequest = {
        site_id : 0,
        start_date : moment().format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let requestVolume = {
        site_id : 0,
        start_date : sessionStorage.getItem("collectionsMinDate"),
        end_date : sessionStorage.getItem("collectionsMaxDate")
      }
      let fillingsRequestVolume = {
        site_id : 0,
        start_date : sessionStorage.getItem("fillingsMinDate"),
        end_date : sessionStorage.getItem("fillingsMaxDate")
      }
      let request = {
        start_date : sessionStorage.getItem("collectionsMinDate"),
        end_date : sessionStorage.getItem("collectionsMaxDate")
      }
      let fillingsRequest = {
        start_date : sessionStorage.getItem("fillingsMinDate"),
        end_date : sessionStorage.getItem("fillingsMaxDate")
      }
      let ticketManagementRequest = {
        start_time : sessionStorage.getItem("ticketManagementMinDate"),
        end_time : sessionStorage.getItem("ticketManagementMaxDate")
      }
      let requestReports = {
        site_id : 0,
        start_time : sessionStorage.getItem("reportsMinDate"),
        end_time : sessionStorage.getItem("reportsMaxDate")
      }
      let reportsRequest = {
        site_id : 0,
        start_time : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
        end_time : moment().format('YYYY-MM-DD 23:59:59')
      }
  
      let alertsByDate = {
        site_id : 0,
        start_time : moment().format('YYYY-MM-DD 00:00:01'),
        end_time : moment().format('YYYY-MM-DD 23:59:59')
      }
  
      let exceptionReport = {
        start_time : sessionStorage.getItem("reportsMinDate"),
        end_time : sessionStorage.getItem("reportsMaxDate")
      }
      this.apiService.getfilteredUsersInfo();
      this.apiService.getfilteredBinsInfo();
      this.apiService.getUserAlertsByType();
      this.apiService.getfilteredBasicBinsInfo();      
      if(this.usageType == 2){
        if(sessionStorage.getItem("fillingsMinDate")!=null && sessionStorage.getItem("fillingsMaxDate")!=null){
          this.apiService.getfilteredSiteRefillsKpis(fillingsRequest);
          this.apiService.getfilteredBinsRefillsStats(fillingsRequestVolume);
        }else{
          this.apiService.getfilteredSiteRefillsKpis(fillingsStatsRequest);
          this.apiService.getfilteredBinsRefillsStats(fillingsStatsRequest);
        }
      }

      if(this.usageType == 1 && this.isAdmin){
        if(sessionStorage.getItem("ticketManagementMinDate")!=null && sessionStorage.getItem("ticketManagementMaxDate")!=null &&
        sessionStorage.getItem("ticketManagementMinDate") != '' && sessionStorage.getItem("ticketManagementMaxDate") != ''){
          this.apiService.getCRTicketsList(ticketManagementRequest);
          this.apiService.getInsTicketsList(ticketManagementRequest);
          this.apiService.getRmTicketsList(ticketManagementRequest);
        }else{
          this.apiService.getCRTicketsList(ticketManagementRequestByDate);
          this.apiService.getInsTicketsList(ticketManagementRequestByDate);
          this.apiService.getRmTicketsList(ticketManagementRequestByDate);
        }
      }
      this.apiService.getfilteredBinsCollectionsStats(collectionsStatsTodayRequest,3);
      this.apiService.getfilteredBinsCollectionsStats(threeMonthsAgo,0);
      if(sessionStorage.getItem("collectionsMinDate")!=null && sessionStorage.getItem("collectionsMaxDate")!=null){
        this.apiService.getfilteredSiteCollectionsKpis(request);
        this.apiService.getfilteredBinsCollectionsStats(requestVolume,5);
      }else{
        this.apiService.getfilteredSiteCollectionsKpis(collectionsStatsRequest);
        this.apiService.getfilteredBinsCollectionsStats(collectionsStatsRequest,1);
        this.apiService.getfilteredBinsCollectionsStatsToday(collectionsStatsTodayRequest);
      }
      if(sessionStorage.getItem("reportsMinDate")!=null && sessionStorage.getItem("reportsMinDate")!=null){
        this.apiService.getfilteredExceptionsReports(requestReports);
        this.apiService.getfilteredUserEventsByType(requestReports);
        this.apiService.getfilteredDriverExceptionsReports(exceptionReport.start_time,exceptionReport.end_time);
      }else{
        this.apiService.getfilteredExceptionsReports(reportsRequest);
        this.apiService.getfilteredUserEventsByType(alertsByDate);
      }
      this.apiService.getInstallationAccessoryTypes();
      this.apiService.getWorkPlanDistrebutionMethods();
      this.apiService.getUnitIssuesType();
      this.apiService.getCustomerWorkPlanCollectionschedulePolicy();
      this.apiService.getfilteredSitesClustersList(userGeneratedWorkplans);
      this.apiService.getNeighborhoodsList(userGeneratedWorkplans);
      this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans);		
      this.apiService.getfilteredActivePlans(); 
      this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans);		
      this.apiService.getDailyWorkPlanCalanderData(dailyWorkplan.start_date,dailyWorkplan.end_date,this.is_view_unexecuted_work_plans);	
      this.apiService.getRemovedBinFromExistingWorkPlanReasonType();
      this.apiService.getTicketEventTypes();
      this.apiService.getTicketEventCause();
      this.apiService.getTicketAnalysisTypes();
      this.apiService.getTicketRemoveOrReplaceTypes();	
      this.apiService.getNavigationOptimizationTypes();
      this.apiService.getClusterTypes();
      this.apiService.getUserRoles();
      this.apiService.getUsageTypes();
      this.apiService.getCapacityDisplayTypes();
      this.apiService.getUserAccounts();
      this.apiService.getTicketsList();
    }

    applySitesChosenBtn(){
      if(this.searchUserForm.getRawValue()["userType"].length == 0){
        return;
      }
      this.apiStore.update({ filteredBasicBins: [] });  
      this.apiStore.update({ filteredDailyWorkPlan: [] });     
      this.apiStore.update({ collectionsKpis: [] });
      this.apiStore.update({ collectionsStats: [] });
      this.apiStore.update({ collectionsStatsToday: [] });
      this.selectSites?.close();
      this.sitesChosen = this.searchUserForm.getRawValue()["userType"];
      sessionStorage.setItem("chosenSites",JSON.stringify(this.sitesChosen));
      let threeMonthsAgo = {
        site_id : 0,
        start_date : moment().subtract(3, 'months').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:23:59')
      }
      let userGeneratedWorkplans = {
        site_id:0
      }	
      let dailyWorkplan = {
        start_date : moment().subtract(2, 'months').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let collectionsStatsRequest = {
        site_id : 0,
        start_date : moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let fillingsStatsRequest = {
        site_id : 0,
        start_date : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let collectionsStatsTodayRequest = {
        site_id : 0,
        start_date : moment().format('YYYY-MM-DD 00:00:01'),
        end_date : moment().format('YYYY-MM-DD 23:59:59')
      }
      let requestVolume = {
        site_id : 0,
        start_date : sessionStorage.getItem("collectionsMinDate"),
        end_date : sessionStorage.getItem("collectionsMaxDate")
      }
      let fillingsRequestVolume = {
        site_id : 0,
        start_date : sessionStorage.getItem("fillingsMinDate"),
        end_date : sessionStorage.getItem("fillingsMaxDate")
      }
      let request = {
        start_date : sessionStorage.getItem("collectionsMinDate"),
        end_date : sessionStorage.getItem("collectionsMaxDate")
      }
      let fillingsRequest = {
        start_date : sessionStorage.getItem("fillingsMinDate"),
        end_date : sessionStorage.getItem("fillingsMaxDate")
      }
      let requestReports = {
        site_id : 0,
        start_time : sessionStorage.getItem("reportsMinDate"),
        end_time : sessionStorage.getItem("reportsMaxDate")
      }
      let requestWorkPlanAnalysis = {
        sites_list : [],
        capacity_in_percent :sessionStorage.getItem("blackStatusBin"),
				start_date : sessionStorage.getItem("insightsMinDate"),
				end_date : sessionStorage.getItem("insightsMaxDate")
      }
      let reportsWorkPlanAnalysis = {
        sites_list : [],
        capacity_in_percent :85,
        start_time : moment().subtract(14, 'days').format('YYYY-MM-DD 00:00:01'),
        end_time : moment().format('YYYY-MM-DD 23:59:59')
      }

      let reportsRequest = {
        site_id : 0,
        start_time : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
        end_time : moment().format('YYYY-MM-DD 23:59:59')
      }
  
      let alertsByDate = {
        site_id : 0,
        start_time : moment().format('YYYY-MM-DD 00:00:01'),
        end_time : moment().format('YYYY-MM-DD 23:59:59')
      }

      let ticketManagementRequest = {
        start_time : sessionStorage.getItem("ticketManagementMinDate"),
        end_time : sessionStorage.getItem("ticketManagementMaxDate")
      }

      let ticketManagementRequestByDate = {
        status_id : -1
      }

      let exceptionReport = {
        start_time : sessionStorage.getItem("reportsMinDate"),
        end_time : sessionStorage.getItem("reportsMaxDate")
      }
      this.apiService.getSitesList(this.sitesChosen);
      this.apiService.getfilteredUsersInfo(this.sitesChosen);
      this.apiService.getfilteredBinsInfo(this.sitesChosen);
      this.apiService.getUserAlertsByType(this.sitesChosen);
      setTimeout(() => {
        this.apiService.getfilteredBasicBinsInfo(this.sitesChosen);      
      }, 0);

      if(this.usageType == 2){
        if(sessionStorage.getItem("fillingsMinDate")!=null && sessionStorage.getItem("fillingsMaxDate")!=null){
          this.apiService.getfilteredSiteRefillsKpis(fillingsRequest,this.sitesChosen);
          this.apiService.getfilteredBinsRefillsStats(fillingsRequestVolume,this.sitesChosen);
        }else{
          this.apiService.getfilteredSiteRefillsKpis(fillingsStatsRequest,this.sitesChosen);
          this.apiService.getfilteredBinsRefillsStats(fillingsStatsRequest,this.sitesChosen);
        }
      }

      if(this.usageType == 1 && this.isAdmin){
        if(sessionStorage.getItem("ticketManagementMinDate")!=null && sessionStorage.getItem("ticketManagementMaxDate")!=null &&
        sessionStorage.getItem("ticketManagementMinDate") != '' && sessionStorage.getItem("ticketManagementMaxDate") != ''){
          this.apiService.getCRTicketsList(ticketManagementRequest,this.sitesChosen);
          this.apiService.getInsTicketsList(ticketManagementRequest,this.sitesChosen);
          this.apiService.getRmTicketsList(ticketManagementRequest,this.sitesChosen);
        }else{
          this.apiService.getCRTicketsList(ticketManagementRequestByDate,this.sitesChosen);
          this.apiService.getInsTicketsList(ticketManagementRequestByDate,this.sitesChosen);
          this.apiService.getRmTicketsList(ticketManagementRequestByDate,this.sitesChosen);
        }
      }
      this.apiService.getfilteredBinsCollectionsStats(collectionsStatsTodayRequest,3,this.sitesChosen);
      this.apiService.getfilteredBinsCollectionsStats(threeMonthsAgo,0,this.sitesChosen);
      if(sessionStorage.getItem("collectionsMinDate")!=null && sessionStorage.getItem("collectionsMaxDate")!=null){
        this.apiService.getfilteredSiteCollectionsKpis(request,this.sitesChosen);
        this.apiService.getfilteredBinsCollectionsStats(requestVolume,5,this.sitesChosen);
      }else{
        this.apiService.getfilteredSiteCollectionsKpis(collectionsStatsRequest,this.sitesChosen);
        this.apiService.getfilteredBinsCollectionsStats(collectionsStatsRequest,1,this.sitesChosen);
        this.apiService.getfilteredBinsCollectionsStatsToday(collectionsStatsTodayRequest,this.sitesChosen);
      }
      if(sessionStorage.getItem("reportsMinDate")!=null && sessionStorage.getItem("reportsMinDate")!=null){
        this.apiService.getfilteredExceptionsReports(requestReports,this.sitesChosen);
        this.apiService.getfilteredUserEventsByType(requestReports,this.sitesChosen);
        this.apiService.getfilteredDriverExceptionsReports(exceptionReport.start_time,exceptionReport.end_time,this.sitesChosen);
      }else{
        this.apiService.getfilteredExceptionsReports(reportsRequest,this.sitesChosen);
        this.apiService.getfilteredUserEventsByType(alertsByDate,this.sitesChosen);
      }
      if(sessionStorage.getItem("insightsMinDate")!=null && sessionStorage.getItem("insightsMaxDate")!=null){
        this.apiService.getWorkPlanAnalysis(requestWorkPlanAnalysis,this.sitesChosen);
      }else{
        this.apiService.getWorkPlanAnalysis(reportsWorkPlanAnalysis,this.sitesChosen);
      }
      this.apiService.getInstallationAccessoryTypes();
      this.apiService.getWorkPlanDistrebutionMethods();
      this.apiService.getUnitIssuesType();
      this.apiService.getCustomerWorkPlanCollectionschedulePolicy();
      this.apiService.getfilteredSitesClustersList(userGeneratedWorkplans,this.sitesChosen);
      this.apiService.getNeighborhoodsList(userGeneratedWorkplans,this.sitesChosen);
      this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,this.sitesChosen);		
      this.apiService.getfilteredActivePlans(this.sitesChosen); 
      this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,this.sitesChosen);		
      this.apiService.getDailyWorkPlanCalanderData(dailyWorkplan.start_date,dailyWorkplan.end_date,this.is_view_unexecuted_work_plans,this.sitesChosen);	
      this.apiService.getRemovedBinFromExistingWorkPlanReasonType();
      this.apiService.getTicketEventTypes();
      this.apiService.getTicketEventCause();
      this.apiService.getTicketAnalysisTypes();
      this.apiService.getTicketRemoveOrReplaceTypes();	
      this.apiService.getNavigationOptimizationTypes();
      this.apiService.getClusterTypes();
      this.apiService.getUserRoles();
      this.apiService.getUsageTypes();
      this.apiService.getCapacityDisplayTypes();
      this.apiService.getUserAccounts();
      this.apiService.getTicketsList(this.sitesChosen);

      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise();
        if(this.allSelected?.selected){
          if(this.searchUserForm.controls['userType'].value.length - 1 > 1){
            this.formValueForUser = `${this.translationsObj.TITLES.MULTI_SITES} (${this.searchUserForm.controls['userType'].value.length-1})`;
          }else if(this.searchUserForm.controls['userType'].value.length - 1 == 1){
            this.formValueForUser = this.searchUserForm.controls['userType'].value[0]["site_name"];
          }
          if(this.sitesListFilter.length == 1){
            this.searchUserForm.controls['userType'].disable();
            const boxes = Array.from(
              document.getElementsByClassName('mat-select-arrow-wrapper')
            );
            boxes.forEach((box:any) => {
              box["style"].display = 'none';
            });
          }else{
            this.searchUserForm.controls['userType'].enable();
            const boxes = Array.from(
              document.getElementsByClassName('mat-select-arrow-wrapper')
            );
            boxes.forEach((box:any) => {
              box["style"].display = 'table-cell';
            });
          }
        }else{
          if(this.searchUserForm.controls['userType'].value.length > 1){			
            this.formValueForUser = `${this.translationsObj.TITLES.MULTI_SITES} (${this.searchUserForm.controls['userType'].value.length})`;
          }else if(this.searchUserForm.controls['userType'].value.length == 1){
            this.formValueForUser = this.searchUserForm.controls['userType'].value[0]["site_name"];
          }
        }
      });
    }

}
