@if (!isMobile) {
  <div class="insights-black-status-pie">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{'INSIGHTS.BLACK_STATUS_PER_SITE' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div style="margin: 0 auto;height: 300px;" [chart]="donutChart"></div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}