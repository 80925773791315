import { Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-truck-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,MatInputModule,NgbModule],
  templateUrl: './manage-truck-modal.component.html',
  styleUrl: './manage-truck-modal.component.scss'
})
export class ManageTruckModalComponent {
  currentLang;
  truckName: string;
  rtlStr:string = "ltr";
  rtl:boolean = false;
  truckCapacity:any;
  truckMaxBins:string;
  truckLicensePlate:string;
	translationsObj;
  translateSub;
  routeListData: any[] = [];
  selectedRoute: any;
	sitesListData: any[] = [];
	selectedSites: any;
	@ViewChild('siteMatSelect',{static: false}) siteMatSelect;
  @ViewChild('routeTypeMatSelect',{static: false}) routeTypeMatSelect;
	exist:boolean = false;
  selectedSite: any;
	allSitesSelected: boolean;
	usageType = 1;
  PartialManagement: boolean = false;
	isAdmin: boolean = false;
  isMobile: Boolean;

  constructor(private apiService:ApiService, private translator: TranslatorService,private apiQuery:ApiQuery,
    private dialogRef: MatDialogRef<ManageTruckModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private responsiveService: ResponsiveService) {
      this.dialogRef.disableClose = true;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
        this.rtl = this.translator.isRTL()
        if (this.rtl) {
          this.rtlStr = "rtl";
        }
      })
  }

  ngOnInit() {   
    this.onResize();
	  this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
      if (user["user_role_id"] == 4) {
				this.PartialManagement = true;
			}
		});

    if(this.data.chosenTruck.tablet_id!=undefined){
      this.truckName = this.data.chosenTruck.tablet_id;
    }else{
      this.truckName = this.data.chosenTruck.truck_id;
    }   

    if(this.data.chosenTruck.max_capacity_in_liters != undefined){
      this.truckCapacity = this.data.chosenTruck.max_capacity_in_liters;
    }else{
      this.truckCapacity = this.data.chosenTruck.truck_volume;
    }

    if(this.data.chosenTruck.max_capacity_in_bins != undefined){
      this.truckMaxBins = this.data.chosenTruck.max_capacity_in_bins;
    }
    
    if(this.data.chosenTruck.assigned_truck_license_plate != undefined){
      this.truckLicensePlate = this.data.chosenTruck.assigned_truck_license_plate;
    }         

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
      if (sitesList.length > 0){
        this.subscribeSitesList(sitesList);
        this.allSitesSelected = false;
      }
    });

    this.apiQuery.optimizationTypes$.subscribe((optimizationTypes) => {
      if (optimizationTypes.length == 0){return;}      
      this.routeListData = optimizationTypes;
      if (this.routeListData.length > 0){
        _.each(this.routeListData, (item) => {
          if(this.data.chosenTruck.optimization_type_id != undefined){
            if(this.data.chosenTruck.optimization_type_id == item.optimization_type_id){
              this.selectedRoute = item;
            }
          }
        });
      }   
    });
   }

   onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
   }

   selectAllSites(select: NgModel, values, item) {
    this.selectedSites = item;
    select.update.emit(item);
 
		this.allSitesSelected = false;
		this.siteMatSelect.close();
	}

  selectRouteType(select: NgModel, values, item) {
    this.selectedRoute = item;
    select.update.emit(item);
		this.routeTypeMatSelect.close();    
	}

  validateCapacity(value){
    let max = 1000000;

    if (parseInt(value) > max) {
        this.truckCapacity = max; 
    }
  }
   
	private subscribeSitesList = (sitesList: any[]) => {
    this.sitesListData = sitesList;   
    _.each(this.sitesListData, (item) => {
      if(this.data.chosenTruck.site_id != undefined){
        if(this.data.chosenTruck.site_id == item.site_id){
          this.selectedSites = item;
        }
      }else{
        if(this.data.chosenTruck.site_name == item.site_name){
          this.selectedSites = item;
        }
      }
    });
    
    if (this.sitesListData.length > 0){
      _.each(this.sitesListData, (item) => {
        if(this.data.chosenTruck.site_id != undefined){
          if(this.data.chosenTruck.site_id == item.site_id){
            this.selectedSites = item;
          }
        }else{
          if(this.data.chosenTruck.site_name == item.site_name){
            this.selectedSites = item;
          }
        }
      });
    }
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  saveWorkplan = () => {
    let request = {};    
    request["tablet_id_list"] = [Number(this.truckName)];
    if (this.selectedSites != undefined) {
      request["new_site_id"] = this.selectedSites["site_id"];
    }
    request["new_volume"] = Number(this.truckCapacity);
    request["max_capacity_in_bins"] = Number(this.truckMaxBins);
    request["assigned_truck_license_plate"] = this.truckLicensePlate;
    if (this.selectedRoute != undefined) {
      request["route_optimization_type_id"] = this.selectedRoute["optimization_type_id"];
    }
    
    this.apiService.UpdateTrucksTabletsInfo(request).subscribe((v:any) => {      		
      if(v.updateStatus){
        let userGeneratedWorkplans = {
          site_id:0
        }
        this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));		
        this.closeModal();
      }
   });    
  }

}
