@if (!isMobile) {
  <div class="black-status-sampling">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">
          <div class="main-title">
            {{'INSIGHTS.NUM_BLACK_STATUS_EV' | translate}}
          </div>
          <!-- <div class="total-ev">
            <img src="assets/images/reports/secondPercentRange.svg" alt="" />
            {{'INSIGHTS.TOTAL_EV' | translate}}
          </div> -->
        </mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div class="content-header">
          <div class='range-type-container' rtlDiv>
            <div class='range-type-btn' (click)="changeCollectionReportRange('week')" [ngClass]="{'selected_text':selected_time === 'week'}">{{
            'SAMPLING_HISTORY_REPORT.WEEK' | translate }}</div>
            <div class='range-type-btn' rtlDiv (click)="changeCollectionReportRange('month')" [ngClass]="{'selected_text':selected_time === 'month'}">{{
            'SAMPLING_HISTORY_REPORT.MONTH' | translate }}</div>
          </div>
          <div class='graph-title-container'>
            <div class='next-prev-btns-wrapper'>
              <img class='icon-img prev' [ngClass]="{'disable': paginationIndex === 1}" (click)='moveGraphPrev()' src="../assets/images/reports/prev_icon.svg"
                alt="prev_icon" rtlDiv>
                <div class='time-range'>{{getDateTimeToShow()}}</div>
                <img class='icon-img next' [ngClass]="{'disable': isNextBtnDisable()}" (click)='moveGraphNext()' src="../assets/images/reports/next_icon.svg"
                  alt="next_icon" rtlDiv>
                </div>
          </div>
        </div>
        <div class="graph-data">
          <canvas id="binData"></canvas>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}