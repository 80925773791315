import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-confirm-password',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,FormsModule,CommonModule],
  templateUrl: './confirm-password.component.html',
  styleUrl: './confirm-password.component.scss'
})
export class ConfirmPasswordComponent {
  
  showResetApproval: boolean = false;
  passwordMatchFlag: boolean = false;
  @Input() token:any;
  @Output() showHideConfirmModal = new EventEmitter<any>();
  @Output() openSuccessModal = new EventEmitter<any>();

  password = '';
  confirmPassword = '';

  constructor(private apiService:ApiService,private apiQuery:ApiQuery) { }

  ngOnInit() {
    this.passwordMatchFlag = false;
    this.apiQuery.updatedPassword$.subscribe((updatedPassword) => {
			if (!updatedPassword) return;
      if (updatedPassword === 1) {
        this.openSuccessModal.emit(true);
        this.closeModal();
			}
    });
  }
  public closeModal = () => {
    this.passwordMatchFlag = false;
    this.password = "";
		this.confirmPassword = "";
    this.showHideConfirmModal.emit(false);
  }
  
  sendNewPassword = (password: string, confirmPassword: string) => {
    if (password === confirmPassword) {
      this.passwordMatchFlag = false;
      const dataToSend = {
        "token": this.token,
        "new_password": password
      }
      this.apiService.updateUserPassword(dataToSend);
    }
    else {
      this.passwordMatchFlag = true;
    }
  }

}
