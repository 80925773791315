import { Component, Inject, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-cluster-info-modal',
  standalone: true,
  imports: [MatDividerModule,CommonModule,TranslateModule,ScrollingModule,MatExpansionModule,RTLDivDirectiveDirective],
  templateUrl: './cluster-info-modal.component.html',
  styleUrl: './cluster-info-modal.component.scss'
})
export class ClusterInfoModalComponent {
  allExpandState: boolean = false;
  translationsObj;
	currentLang;
  isMobile: Boolean;
  chosenCluster: Array<any> = [];
  dataBackUp = [];
  allDataExpanded : boolean = false;
	usageType = 1;
  @ViewChild(MatAccordion,{static: false}) accordion: MatAccordion;

  constructor(private dialogRef: MatDialogRef<ClusterInfoModalComponent>,private translator: TranslatorService,
    private responsiveService: ResponsiveService,@Inject(MAT_DIALOG_DATA) public data: any,private apiQuery:ApiQuery) {
      this.dialogRef.disableClose = true;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      }); 
    }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe(user => {
      if (!user) {
        return;
      }
      this.usageType = user["usage_type_id"];
    });

    this.apiQuery.sitesClustersList$.subscribe(clusters => {	
			if(clusters.length == 0) return;
      this.chosenCluster = clusters.filter(cluster => cluster["site_id"] == this.data.items[0].site_id && 
        cluster["cluster_id"] == this.data.items[0].cluster_id);  
      this.dataBackUp = this.data.items;
		});
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  closeModal(){
		this.dialogRef.close();
	}

  applyFilter = (filterValue) => {	
    filterValue = filterValue.value;
    this.data.items = this.dataBackUp.filter((item) => item.bin_name.toString().includes(filterValue));
	}

  expandAllData(){
    this.allDataExpanded = !this.allDataExpanded;
  }
}
