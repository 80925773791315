import { Component } from '@angular/core';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { LanguagesMenuComponent } from '../header/languages-menu/languages-menu.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { TranslatorService } from '../../services/translator_service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiStore } from '../../web-services/api/api.store';
import { ApiQuery } from '../../web-services/api/api.query';
import { ResponsiveService } from '../../services/responsive.service';
import { transition, trigger } from "@angular/animations";
import { environment } from '../../../environments/environment';
import { LogoutValidationComponent } from './logout-validation/logout-validation.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarItemComponent,LanguagesMenuComponent,MatSidenavModule,TranslateModule,CommonModule,RTLDivDirectiveDirective],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [
		trigger("sideMenu", [
		  transition(":enter", []),
		  transition(":leave", [])
		])
	]
})
export class SidebarComponent{
  isMobile: boolean = false;
	isOpen = false;
	isSuperAdmin: boolean = false;
	isAdministrator : boolean = false;
	isAdmin: boolean = false;
	usageType = 1;
	version_number: String;
	isUserReqToLogOut: boolean = false;
	dialogRef: any;
	translationsObj:any;
	currnetLang:any;
	userInfo:any;
	user_role_id = 0;
	user = {
		name: 'some@mail.com',
		phone: '052-1234567'
	};

	constructor(
		private translator: TranslatorService,
		private dialog: MatDialog,
		private router: Router,
		private apiStore:ApiStore,
		private apiQuery: ApiQuery,
		private responsiveService: ResponsiveService
	) {
		this.translator.currentLangEmitter$
		.subscribe(async value=>{			      			
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currnetLang = value;
		})

		this.version_number = environment.appVersion;
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.apiQuery.user$.subscribe((user:any) => {
			if (!user) return;
			this.userInfo = user;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}

			if ((user["user_role_id"] == 1) || (user["user_role_id"] == 2) || (user["user_role_id"] == 3) || (user["user_role_id"] == 4) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
			
			if ((user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isSuperAdmin = true;
			}

			if (user["user_role_id"] == 1) {
				this.isAdministrator = true;
			}
			this.user_role_id = user["user_role_id"];

		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	onReqToLogOut() {
		if (this.router.url.includes('newinstallationwizard')) {
			this.dialogRef = this.dialog.open(LogoutValidationComponent, {
				width: '600px',
				disableClose: true
				});

				this.dialogRef.afterClosed().subscribe((result:any) => {
				if (result == true) {
					this.logout();
				}
			});	
		}
		else {
			this.logout();
		}
	}

	logout() {
		//emptying bin-status component
		this.apiStore.update({ allFilteredDailyWorkPlan: [] });
		this.apiStore.update({ filteredDailyWorkPlan: [] });
		this.apiStore.update({ allCollectionsStatsForWorkplanProgres: [] });                
        this.apiStore.update({ CollectionsStatsForWorkplanProgres: [] }); 
		
		//emptying active-bins component
		this.apiStore.update({ allFilteredBasicBins: [] });                    
		this.apiStore.update({ filteredBasicBins: [] }); 

		//emptying collection-kpi components
		this.apiStore.update({ allCollectionsKpis: [] });
		this.apiStore.update({ collectionsKpis: [] });

		//emptying collection-pie component
		this.apiStore.update({ collectionsStatsInCollections: [] });
		this.apiStore.update({ allCollectionsStats: [] });
		this.apiStore.update({ collectionsStats: [] });
		this.apiStore.update({ allCollectionsStatsToday: [] });
		this.apiStore.update({ collectionsStatsToday: [] });

		//emptying refill-kpi components
		this.apiStore.update({ allRefillsKpis: [] });
		this.apiStore.update({ refillsKpis: [] });

		//emptying refills-table components
		this.apiStore.update({ allRefillsStats: [] });
		this.apiStore.update({ refillsStats: [] });

		//emptying alert-history component
		this.apiStore.update({ userEventsByType: [] });

		//emptying inventory component 
		this.apiStore.update({ allFilteredBins: [] });                    
		this.apiStore.update({ filteredBins: [] });

		//emptying volume component           
		this.apiStore.update({ collectionVolume: [] });

		//emptying exception-list component
		this.apiStore.update({ exceptionsReports: [] });

		//emptying dailyworkplans calendar
		this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
		this.apiStore.update({ dailyWorkPlanCalanderData: [] });
		
		//emptying workPlanScheduler 
		this.apiStore.update({ allWorkPlanScheduler: [] });
		this.apiStore.update({ workPlanScheduler: [] });

		//emptying sitesList
		this.apiStore.update({ allSitesListData: [] });   	                                                      
		this.apiStore.update({ sitesListData: [] });

		//document.location.href="/";		
		this.router.navigate(['/login']);		

		localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
		localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);

		localStorage.removeItem('language');
		sessionStorage.removeItem('fillingsMinDate');
		sessionStorage.removeItem('fillingsMaxDate');
		sessionStorage.removeItem('fillingsStartDate');
		sessionStorage.removeItem('fillingsEndDate');


		sessionStorage.removeItem('collectionsMinDate');
		sessionStorage.removeItem('collectionsMaxDate');
		sessionStorage.removeItem('collectionsStartDate');
		sessionStorage.removeItem('collectionsEndDate');

		sessionStorage.removeItem('reportsMinDate');
		sessionStorage.removeItem('reportsMaxDate');
		sessionStorage.removeItem('reportsStartDate');
		sessionStorage.removeItem('reportsEndDate');

		sessionStorage.removeItem('ticketManagementMinDate');
		sessionStorage.removeItem('ticketManagementMaxDate');
		sessionStorage.removeItem('ticketManagementStartDate');
		sessionStorage.removeItem('ticketManagementEndDate');

		sessionStorage.removeItem('insightsMinDate');
		sessionStorage.removeItem('insightsMaxDate');
		sessionStorage.removeItem('insightsStartDate');
		sessionStorage.removeItem('insightsEndDate');
		sessionStorage.removeItem('blackStatusBin');
	}
}
