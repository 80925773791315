@if (!isMobile) {
  <div>
    <div class="sidebar-container">
      <mat-sidenav-container class="example-container">
        <mat-sidenav #sidenav mode="side" opened="true" class="example-sidenav" [fixedInViewport]="true" [fixedTopGap]="0" [fixedBottomGap]="0">
          <div class="sidebar-nav">
            <div>
              <div class="logo">
                <img class="databin-logo" src="assets/images/topmenu/databinlogo.svg" alt="" />
              </div>
              <div class="routes" [ngClass]="{'hideItems':user_role_id == 7}">
                <app-sidebar-item link="/dashboard" icon="assets/images/sidemenu/dashboard.svg" iconChosen="assets/images/sidemenu/dashboardchosen.svg" label="{{ 'SIDEBAR.DASHBOARD' | translate }}"></app-sidebar-item>
                @if (usageType == 1) {
                  <app-sidebar-item link="/workplans" icon="assets/images/sidemenu/workplans.svg" iconChosen="assets/images/sidemenu/workplanschosen.svg" label="{{ 'SIDEBAR.WORKPLANS' | translate }}"></app-sidebar-item>
                }
                @if (usageType == 2 && isAdministrator) {
                  <app-sidebar-item link="/fillableBins" icon="assets/images/sidemenu/workplans.svg" iconChosen="assets/images/sidemenu/workplanschosen.svg" label="{{ 'SIDEBAR.FILLABLETANKS' | translate }}"></app-sidebar-item>
                }
                <app-sidebar-item link="/inventory" icon="assets/images/sidemenu/inventorymanagment.svg"  iconChosen="assets/images/sidemenu/inventorymanagmentchosen.svg" label="{{ 'SIDEBAR.INVENTORY' | translate }}"></app-sidebar-item>
                @if (usageType == 1) {
                  <app-sidebar-item link="/insights" icon="assets/images/sidemenu/insights.svg" iconChosen="assets/images/sidemenu/insightsChosen.svg" label="{{ 'INSIGHTS.INSIGHTS' | translate }}"></app-sidebar-item>
                }
                <app-sidebar-item link="/reports" icon="assets/images/sidemenu/reports.svg" iconChosen="assets/images/sidemenu/reportschosen.svg" label="{{ 'SIDEBAR.REPORTS' | translate }}"></app-sidebar-item>
                @if (usageType == 1) {
                  <app-sidebar-item link="/collections" icon="assets/images/sidemenu/collections.svg" iconChosen="assets/images/sidemenu/collectionschosen.svg" label="{{ 'WIDGETS.COLLECTIONS' | translate }}"></app-sidebar-item>
                }
                @if (usageType == 2) {
                  <app-sidebar-item link="/fillings" icon="assets/images/sidemenu/collections.svg" iconChosen="assets/images/sidemenu/collectionschosen.svg" label="{{ 'WIDGETS.FILLINGS' | translate }}"></app-sidebar-item>
                }
                @if (usageType == 1 && isAdmin) {
                  @if (usageType == 1 && isAdmin) {
                    <app-sidebar-item link="/ticketManagement" icon="assets/images/sidemenu/open_ticket_indication.svg" iconChosen="assets/images/sidemenu/open_ticket_indication_chosen.svg" label="{{ 'SIDEBAR.TICKET_MANAGEMENT' | translate }}"></app-sidebar-item>
                    }                }
                    @if (isSuperAdmin) {
                      <app-sidebar-item link="/siteMangment" icon="assets/images/sidemenu/siteManagment.svg" iconChosen="assets/images/sidemenu/siteManagmentChosen.svg" label="{{ 'SIDEBAR.SITE_MANAGMENT' | translate }}"></app-sidebar-item>
                    }
                  </div>
                </div>
                <div class="profile">
                  <div class="sidebar-footer">
                    <div class="profile-img">
                      <img src="assets/images/sidemenu/profileUser.svg" alt="" />
                    </div>
                    <div class="user">
                      <span>{{ userInfo ? userInfo.user_email_address : '' }}</span>
                    </div>
                    <div class="user">
                      <span>{{ userInfo ? userInfo.user_phone_number : '' }}</span>
                      <img class="logout-img" src="assets/images/sidemenu/sign out.svg" alt="" (click)="onReqToLogOut()" />
                    </div>
                    <div class="version-details">
                      <div class="version-number">{{version_number}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </div>
    }

    @if (isMobile) {
      <div class="mobile-sidebar">
        <div class="menu-trigger">
          <img rtlDiv (click)="isOpen = !isOpen" class="logout-img margin-sides" src="assets/images/sidemenu/menu.svg" alt="" />
        </div>
        @if (isOpen) {
          <div rtlDiv class="side-menu" [@sideMenu]>
            <div class="grid-view">
              <div class="nav-header" rtlDiv>
                <app-languages-menu></app-languages-menu>
                <div [ngClass]="{'menu-close_he':currnetLang == 'iw','menu-close_en':currnetLang != 'iw'}" (click)="isOpen = !isOpen">
                  <img src="assets/images/sidemenu/close-menu.svg" alt="" />
                </div>
              </div>
              <div class="grid-view">
                <div class="menu-item" (click)="isOpen = !isOpen" [ngClass]="{'mobile-hideItems':user_role_id == 7}">
                  <app-sidebar-item class="mobile_footer_item" rtlDiv link="/dashboard" icon="assets/images/sidemenu/dashboard.svg" iconChosen="assets/images/sidemenu/dashboardchosen.svg" label="{{ 'SIDEBAR.DASHBOARD' | translate }}"></app-sidebar-item>
                </div>
                @if (usageType == 1) {
                  <div class="menu-item" (click)="isOpen = !isOpen" [ngClass]="{'mobile-hideItems':user_role_id == 7}">
                    <app-sidebar-item class="mobile_footer_item" rtlDiv link="/workplans" icon="assets/images/sidemenu/workplans.svg" iconChosen="assets/images/sidemenu/workplanschosen.svg" label="{{ 'SIDEBAR.WORKPLANS' | translate }}"></app-sidebar-item>
                  </div>
                }
                <div class="menu-item" (click)="isOpen = !isOpen" [ngClass]="{'mobile-hideItems':user_role_id == 7}">
                  <app-sidebar-item class="mobile_footer_item" rtlDiv link="/inventory" icon="assets/images/sidemenu/inventorymanagment.svg" iconChosen="assets/images/sidemenu/inventorymanagmentchosen.svg" label="{{ 'SIDEBAR.MOBILE_INVENTORY' | translate }}"></app-sidebar-item>
                </div>
                @if (usageType == 1) {
                  <div class="menu-item" (click)="isOpen = !isOpen" [ngClass]="{'mobile-hideItems':user_role_id == 7}">
                    <app-sidebar-item class="mobile_footer_item" rtlDiv link="/collections" icon="assets/images/sidemenu/collections.svg" iconChosen="assets/images/sidemenu/collectionschosen.svg" label="{{ 'WIDGETS.COLLECTIONS' | translate }}"></app-sidebar-item>
                  </div>
                }
                @if (usageType == 1 && isAdmin) {
                  <div class="menu-item" (click)="isOpen = !isOpen" [ngClass]="{'mobile-hideItems':user_role_id == 7}">
                    <app-sidebar-item class="mobile_footer_item" rtlDiv link="/ticketManagement" icon="assets/images/sidemenu/open_ticket_indication.svg" iconChosen="assets/images/sidemenu/open_ticket_indication_chosen.svg" label="{{ 'SIDEBAR.TICKET_MANAGEMENT' | translate }}"></app-sidebar-item>              </div>
                  }
                  @if (isSuperAdmin) {
                    <div class="menu-item" (click)="isOpen = !isOpen" [ngClass]="{'mobile-hideItems':user_role_id == 7}">
                      <app-sidebar-item class="mobile_footer_item" rtlDiv link="/siteMangment" icon="assets/images/sidemenu/siteManagment.svg" iconChosen="assets/images/sidemenu/siteManagmentChosen.svg" label="{{ 'SIDEBAR.MOBILE_SITE_MANAGMENT' | translate }}"></app-sidebar-item>
                    </div>
                  }
                  <div class="menu-item" (click)="isOpen = !isOpen">
                    <app-sidebar-item class="mobile_footer_item" rtlDiv link="/logout" icon="assets/images/sidemenu/profile.svg" iconChosen="assets/images/sidemenu/profilechosen.svg" label="{{ 'SIDEBAR.MOBILE_PROFILE' | translate }}"></app-sidebar-item>
                  </div>
                </div>
              </div>
              <div class="mobile-logo"><img class="databin-logo" src="assets/images/topmenu/databinlogo.svg" alt=""  /></div>
            </div>
          }
        </div>
      }