@if (!isMobile) {
  <div class="premapping-page">
    <div class="top-container">
      <div class="pre-mapping-header">{{ 'SITE_MANAGMENT.PRE_MAP_WIZARED' | translate }}</div>
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="pre-mapping-page">
      @if (spinnerActive) {
        <div class='spinner-container'>
          <div class="spinner"></div>
        </div>
      }
      @if (!spinnerActive) {
        <div class="mapping-info">
          @if (isPreMappingInfo) {
            <p>{{ 'SITE_MANAGMENT.THIS_SITE_ALREADY_HAVE_PRE_INSTALLATION_INFO' | translate }}</p>
          }
          <div [ngClass]="{'disable-click':!isEnabledEdit,'enable-click':isEnabledEdit}" (click)="insertNewRow()">
            <div [ngClass]="{'hide':!insertBinSpinnerExist}" class="spinner-border mobile-spinner" role="status">
            </div>
            @if (!insertBinSpinnerExist) {
              <div>
                <img class="insertBinIcon" src="assets/images/siteManagment/details-open.png" alt="{{ 'SITE_MANAGMENT.INSERT_NEW_BIN' | translate }}">
              </div>
            }
            <div class="insert-new-bin-text">{{ 'SITE_MANAGMENT.INSERT_NEW_BIN' | translate }}</div>
          </div>
          @if (isPreMappingInfo) {
            <div>
              <div class="bins-header-info">
                <div class="info-bins">
                  <div>{{ mappedCounter }}</div>
                  <div>{{ 'SITE_MANAGMENT.BINS_INSTALLED' | translate }}</div>
                </div>
                <div class="info-bins">
                  <div>{{ totalBinsToMap }}</div>
                  <div>{{ 'SITE_MANAGMENT.BINS_MAPPED' | translate }}</div>
                </div>
                <div class="info-bins">
                  <div>{{ mappedTodayCounter }}</div>
                  <div>{{ 'SITE_MANAGMENT.INSTALLED_TODAY' | translate }}</div>
                </div>
              </div>
            </div>
          }
        </div>
      }
      <div class="options-btns">
        @if (isShowUploadAndOverwriteButton) {
          <button type="button" class="btn btn-info overwrite-data-button" (click)="overwriteData()" >{{ 'SITE_MANAGMENT.UPLOAD_AND_OVERWRITE_DATA' | translate }}</button>
        }
        <button [hidden]="!isShowTable" type="button" class="btn btn-info overwrite-data-button" (click)="addData()" >{{ 'SITE_MANAGMENT.UPLOAD_AND_ADD_DATA' | translate }}</button>
      </div>
      @if (!isPreMappingInfo&&!spinnerActive) {
        <div [ngClass]="{'hide':isCurrentlyInserting}" class="no-mapping-info">
          @if (!isPreMappingInfo) {
            <div>
              <div class="well-done">
                <p><img class="bin-is-not-here-icon" src="assets/images/siteManagment/thumb_up_alt-24px.svg" alt=""> {{ 'SITE_MANAGMENT.WELL_DONE' | translate }}</p>
              </div>
              <div class="no-more-bins-to-map">
                <p>{{ 'SITE_MANAGMENT.NO_BINS_TO_MAP' | translate }}</p>
              </div>
            </div>
          } @else {
            <p>{{ 'SITE_MANAGMENT.THIS_SITE_HAS_NO_PRE_MAPPING_INFO' | translate }} <a href="assets/ExcelTemplates/Pre_Mapping_Template.xlsx" download>{{ 'SITE_MANAGMENT.DOWNLOAD_EXCEL_TEMPLATE' | translate }}</a></p>
          }
        </div>
      }
      @if (!spinnerActive) {
        <div class="binStartSticker_frame">
          <div>
            <div>{{ 'SITE_MANAGMENT.STICKER_ID' | translate }}</div>
            <input class="binStartSticker_input" type="text" name="value" maxlength="4" [(ngModel)]="binStartSticker" (keyup)="applySticker($event.target['value'])">
          </div>
        </div>
      }
      @if (isShowDropZone&&!spinnerActive) {
        <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
          <input type="file" class="file-input" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target['files'])" accept=".xlsx"/>
          <img src="assets/images/dnd/ic-upload-file.svg" alt="">
          <h3>{{ 'SITE_MANAGMENT.DRAG_AND_DROP_FILE' | translate }}</h3>
          <h3>{{ 'SITE_MANAGMENT.OR' | translate }}</h3>
          <label for="fileDropRef">{{ 'SITE_MANAGMENT.BROWSE_FOR_FILE' | translate }}</label>
        </div>
      }
      @if (isShowDropZoneToAdd&&!spinnerActive) {
        <div class="dropzone" appDnd (fileDropped)="onFileDroppedToAdd($event)">
          <input type="file" class="file-input" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandlerToAdd($event.target['files'])" accept=".xlsx"/>
          <img src="assets/images/dnd/ic-upload-file.svg" alt="">
          <h3>{{ 'SITE_MANAGMENT.DRAG_AND_DROP_FILE' | translate }}</h3>
          <h3>{{ 'SITE_MANAGMENT.OR' | translate }}</h3>
          <label for="fileDropRef">{{ 'SITE_MANAGMENT.BROWSE_FOR_FILE_ADD' | translate }}</label>
        </div>
      }
      <div class="map-wrapper">
        <div [style.visibility]="isShowMap ? 'visible' : 'hidden'">
          <div class='header-frame' rtlDiv>
            <div class='header-title' rtlDiv>{{
              'WIDGETS.MAP_VIEW' | translate }}
            </div>
          </div>
          <div class="filter-container">
            <div class='search-input-container' rtlDiv>
              <input class='search-input' type="text" (keyup)="applyMapMarkersFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}"
                id="search-input" rtlDiv>
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
            </div>
            <div class="drop-down-main-wrapper" rtlDiv>
              <div class="drop-down-list-container">
                @for (item of items; track item) {
                  <div class="drop-down-list-item" [ngClass]="{'selected-item' : !item.selected, 'noClick' : mapSpinnerActive , 'pointer' : !mapSpinnerActive}" [ngStyle]="{background: item.color}" (click)="selectUnselectItem(item,1)">
                    <mat-checkbox [checked]="item.selected" (click)="selectUnselectItem(item,1);$event.stopPropagation();false"></mat-checkbox>
                    <span class="colorTxt">{{item.displayName | translate}}</span>
                  </div>
                }
              </div>
            </div>
          </div>
          @if (isShowProgressBar) {
            <mat-progress-bar class="progress-bar-map"
              [color]="progressBarColor"
              [mode]="mode"
              [value]="progressBarValue">
            </mat-progress-bar>
          }
          <div class='map-spinner-container' [hidden]="!mapSpinnerActive">
            <div class="spinner"></div>
          </div>
          <google-map id="map"
            (mapClick)="mapClick()"
            [options]="mapOptionsPc"
            (mapInitialized)="onMapReady($event)">
            @if (moreThanMax) {
              <map-marker-clusterer [ignoreHidden]="true" [minimumClusterSize]= "5" [styles]="mapOptions.styles" [calculator]="mapOptions.calculator">
                @for (bin of markersArray; track bin; let i = $index) {
                  @if(isNumber(bin.lat) && isNumber(bin.lng)){
                    <map-marker #marker="mapMarker"
                    (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                    [position]="{lat: bin.lat, lng: bin.lng}"
                    [visible]="bin.visible"
                    [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                    [label]="bin.label"
                    [title]="bin.title">
                  </map-marker>
                  <map-info-window #window="mapInfoWindow">
                    <div id="iw-container">
                      <div id="binInfoWindow_multiSites"></div>
                    </div>
                  </map-info-window>
                  }
                }
              </map-marker-clusterer>
            }
            @if (!moreThanMax) {
              @for (bin of markersArray; track bin; let i = $index) {
                @if(isNumber(bin.lat) && isNumber(bin.lng)){
                  <map-marker #marker="mapMarker"
                  (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                  [position]="{lat: bin.lat, lng: bin.lng}"
                  [visible]="bin.visible"
                  [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                  [label]="bin.label"
                  [title]="bin.title">
                </map-marker>
                <map-info-window #window="mapInfoWindow">
                  <div id="iw-container">
                    <div id="binInfoWindow_singleSite"></div>
                  </div>
                </map-info-window>
                }
              }
            }
          </google-map>
        </div>
      </div>
      @if (isShowTable&&isAllAddressesValid) {
        <div class="table-wrapper">
          <mat-card>
            @if (!isEnabledEdit&&!spinnerActive) {
              <div class="apply-button-wrapper">
                <button mat-button class="apply-button" color="primary" (click)="pushToApi()">
                  <div [ngClass]="{'hide':!spinnerExist, 'hide-zero-pixel' : !spinnerExist}" class="spinner-border mobile-spinner" role="status">
                    <span class="sr-only"></span>
                  </div>
                  @if (!spinnerExist) {
                    <div>{{ 'SITE_MANAGMENT.UPDATE' | translate }}</div>
                  }
                </button>
              </div>
            }
            <mat-card-content>
              <div class="search-wrapper">
                <div class='search-input-container' rtlDiv>
                  <input [ngModel]="filteredValue" #filterInput class='search-input' type="text" (keyup)="applyFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}"
                    id="search-input-table" rtlDiv>
                  <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                </div>
                <div class='table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
                  <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                  <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                </div>
              </div>
              <div>
                <table mat-table #table [dataSource]="dataSource" matSort matSortStart="asc" matSortActive="binStickerId"
                  matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="removeBinTableHeader">
                    <th mat-header-cell [ngClass]="{'hide': !isEnabledEdit}" *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'hide': !isEnabledEdit}">
                      @if (element.isValidAddress) {
                        <div>
                          <img matTooltip="{{ 'SITE_MANAGMENT.REMOVE_BIN' | translate }}" class="removeBin" (click)="askToRemoveBin(element)" src="assets/images/siteManagment/details-close.png" alt="">
                        </div>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="binStickerId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="binStickerId">{{ 'SITE_MANAGMENT.STICKER_ID' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.binStickerId }}</td>
                  </ng-container>
                  <ng-container matColumnDef="binAddress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="binAddress">{{ 'SITE_MANAGMENT.ADDRESS' | translate }}</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div [ngClass]="{'hide-zero-pixel' : !element.isValidAddress, 'div-hidden' : !element.isValidAddress}">
                        {{ element.binAddress }}
                      </div>
                      <div [ngClass]="{'hide-zero-pixel' : element.isValidAddress, 'div-hidden' : element.isValidAddress}">
                        <mat-form-field class="address-form-field">
                          <input type="text" class="insert-new-address" matInput #binAddressInput placeholder="{{ 'SITE_MANAGMENT.INSERT_BIN_ADDRESS' | translate }}" (onAddressChange)="handleAddressChange($event, element, i)" (blur)="onAddressInputBlur($event, element, i)">
                        </mat-form-field>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="binNeighborhood">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="binNeighborhood">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.binNeighborhood }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="binComments">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.COMMENTS' | translate }}</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      @if (element.isValidAddress) {
                        <div class="new-comment-wrapper">
                          <textarea class="textarea-wrapper" value="{{ element.binComments }}" matInput [ngModel]="element.binComments" (change)="onBinCommentChange($event, element, i)"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5">
                          </textarea>
                        </div>
                      } @else {
                        {{ element.binComments }}
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="binWazeLink">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.WAZE_LINK' | translate }}</th>
                    <td mat-cell *matCellDef="let element"><a href="{{ element.binWazeLink }}"><img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt=""></a></td>
                  </ng-container>
                  <ng-container matColumnDef="linkToMappingTool">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.MAPPING_TOOL_LINK' | translate }}</th>
                    <td mat-cell *matCellDef="let element"><a routerLink="/preInstallationMapping/{{element.linkToMappingTool}}" [class.disabled]="element.binIsNotHere ? true : null">{{ 'SITE_MANAGMENT.MAPPING_TOOL' | translate }}</a></td>
                  </ng-container>
                  <ng-container matColumnDef="linkToInstallationWizard">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.NEW_INSTALLATION_WIZARED' | translate }}</th>
                    <td mat-cell *matCellDef="let element"><a routerLink="/newinstallationwizard/{{element.linkToInstallationWizard}}" [class.disabled]="element.binIsNotHere ? true : null">{{ 'SITE_MANAGMENT.NEW_INSTALLATION_WIZARED' | translate }}</a></td>
                  </ng-container>
                  <ng-container matColumnDef="binIsNotHere">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LIST_COLUMNS.STATUS_DESC' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                      <button class="exist-btn" (click)="reportBinIsNotHere(element)" mat-icon-button color="warn" aria-label="Report bin is not here">
                        @if (!element.binIsNotHere) {
                          <div>
                            <img class="bin-is-not-here-icon" src="assets/images/siteManagment/report_problem-24px.svg" alt="">
                          </div>
                        } @else {
                          @if (element.binIsNotHere) {
                            <div>
                              <img class="bin-is-not-here-icon" src="assets/images/siteManagment/undo-24px.svg" alt="">
                            </div>
                          }
                        }
                        <ng-template #undo>
                          @if (element.binIsNotHere) {
                            <div>
                              <img class="bin-is-not-here-icon" src="assets/images/siteManagment/undo-24px.svg" alt="">
                            </div>
                          }
                        </ng-template>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                  <tr mat-row [ngClass]="{'bin-not-exist-row': row.binIsNotHere}" *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      }
      <div class="paginator-container" [hidden]="spinnerActive || !isShowTable">
        <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
      </div>
      @if (!isAllAddressesValid&&isShowTable) {
        <div class="table-wrapper">
          <div class="addresses-error-msg">
            <p>{{ 'SITE_MANAGMENT.ERRORS_IN_ADDRESSES' | translate }}</p>
          </div>
          <div class="addresses-issues-table-container">
            <mat-card>
              <mat-table mat-table [dataSource]="addressesIssuesSource">
                <ng-container matColumnDef="binAddress">
                  <mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.ADDRESS' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index" class="address-issue-cell">
                    <mat-form-field [floatLabel]="options.value.floatLabel">
                      <input type="text" value="{{ element.binAddress }}" class="insert-new-address" matInput #binAddressInput placeholder="{{ 'SITE_MANAGMENT.INSERT_BIN_ADDRESS' | translate }}" (onAddressChange)="handleAddressChange($event, element, i)" (blur)="onAddressIssueAddressChange($event, element, i)">
                    </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="binComments">
                  <mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.COMMENTS' | translate }}</mat-header-cell>
                  <mat-cell class="bin-comments" *matCellDef="let element; let i = index" class="address-issue-cell">{{ element.binComments }}
                    @if (element.isValidAddress) {
                      <div>
                        <mat-form-field class="address-form-field">
                          <input type="text" value="{{ element.binComments }}" class="insert-new-comment" matInput [ngModel]="element.binComments" (change)="onBinCommentChange($event, element, i)">
                        </mat-form-field>
                      </div>
                    } @else {
                      {{ element.binComments }}
                    }
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="button">
                  <mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.ACTIONS' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index" class="update-button">
                    <button mat-raised-button [disabled]="!element.isAddressValid" class="address-buttons" color="primary" (click)="updateValidAddress(i, element,'new')">{{ 'SITE_MANAGMENT.UPDATE' | translate }}</button>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="addressesIssuesDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: addressesIssuesDisplayedColumns;"></mat-row>
              </mat-table>
              <mat-paginator [pageSize]="8" showFirstLastButtons></mat-paginator>
            </mat-card>
          </div>
        </div>
      }
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-top-container">
      <div class="mobile-management-header">
        <span class="mobile-left-title">{{ 'SITE_MANAGMENT.PRE_MAP_WIZARED' | translate }}</span>
      </div>
      <div class="mobile-separate">
        <mat-divider class="mobile-line"></mat-divider>
      </div>
      @if (!isPreMappingInfo&&!spinnerActive) {
        <div [ngClass]="{'hide':isCurrentlyInserting}" class="no-mapping-info">
          @if (!isPreMappingInfo) {
            <div>
              <div class="well-done">
                <p><img class="bin-is-not-here-icon" src="assets/images/siteManagment/thumb_up_alt-24px.svg" alt=""> {{ 'SITE_MANAGMENT.WELL_DONE' | translate }}</p>
              </div>
              <div class="no-more-bins-to-map">
                <p>{{ 'SITE_MANAGMENT.NO_BINS_TO_MAP' | translate }}</p>
              </div>
            </div>
          }
        </div>
      }
    </div>
    <div class='mobile-spinner-container' [ngClass]="{'hidden': !spinnerActive}">
      <div class="mobile-spinner"></div>
    </div>
    @if (isPreMappingInfo&&!spinnerActive) {
      <div>
        <div class="bins-header-info">
          <div class="info-bins">
            <div>{{ mappedCounter }}</div>
            <div>{{ 'SITE_MANAGMENT.BINS_INSTALLED' | translate }}</div>
          </div>
          <div class="info-bins">
            <div>{{ totalBinsToMap }}</div>
            <div>{{ 'SITE_MANAGMENT.BINS_MAPPED' | translate }}</div>
          </div>
          <div class="info-bins">
            <div>{{ mappedTodayCounter }}</div>
            <div>{{ 'SITE_MANAGMENT.INSTALLED_TODAY' | translate }}</div>
          </div>
        </div>
      </div>
    }
    @if (!spinnerActive) {
      <div class="mobile-insert-frame">
        <div [ngClass]="{'disable-click':!isEnabledEdit,'enable-click':isEnabledEdit}" (click)="insertNewRow()">
          <div [ngClass]="{'hide':!insertBinSpinnerExist}" class="spinner-border mobile-spinner" role="status">
          </div>
          @if (!insertBinSpinnerExist) {
            <div class="mapping-info-mobile">
              <img class="insertBinIcon" src="assets/images/siteManagment/details-open.png" alt="{{ 'SITE_MANAGMENT.INSERT_NEW_BIN' | translate }}"/>
              <span class="insert-new-bin-text">{{ 'SITE_MANAGMENT.INSERT_NEW_BIN' | translate }}</span>
            </div>
          }
        </div>
      </div>
    }
    <div class="map-wrapper">
      <div [style.visibility]="isShowMap ? 'visible' : 'hidden'">
        <div class='header-frame' rtlDiv>
          <div class='header-title' rtlDiv>{{
            'WIDGETS.MAP_VIEW' | translate }}
          </div>
        </div>
        <div class="mobile-filter-container">
          <div class='mobile-search-input-container' rtlDiv>
            <div class="mobile-img-search">
              <img src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
            </div>
            <input id="mobile-markers-filter" class='mobile-search-input' type="text" (keyup)="applyMapMarkersFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}"
              id="mobile-search-input" rtlDiv>
          </div>
          <div class="drop-down-main-wrapper" rtlDiv>
            <div class = "drop-down-list-container">
              @for (item of items; track item) {
                <div class="drop-down-list-item" [ngClass]="{'selected-item' : !item.selected}" [ngStyle]="{background: item.color}" (click)="selectUnselectItem(item,1)">
                  <mat-checkbox [checked]="item.selected" (click)="selectUnselectItem(item,1);$event.stopPropagation();false"></mat-checkbox>
                  <span class="colorTxt">{{item.displayName | translate}}</span>
                </div>
              }
            </div>
          </div>
        </div>
        @if (isShowProgressBar) {
          <mat-progress-bar class="progress-bar-map"
            [color]="progressBarColor"
            [mode]="mode"
            [value]="progressBarValue">
          </mat-progress-bar>
        }
        <div class='map-spinner-container' [hidden]="!mapSpinnerActive">
          <div class="spinner"></div>
        </div>
        <google-map id="map"
          (mapClick)="mapClick()"
          [options]="mobileMapOptions"
          (mapInitialized)="onMapReady($event)">
          @if (moreThanMax) {
            <map-marker-clusterer [ignoreHidden]="true" [minimumClusterSize]= "5" [styles]="mapOptions.styles" [calculator]="mapOptions.calculator">
              @for (bin of markersArray; track bin; let i = $index) {
                @if(isNumber(bin.lat) && isNumber(bin.lng)){
                  <map-marker #marker="mapMarker"
                  (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                  [position]="{lat: bin.lat, lng: bin.lng}"
                  [visible]="bin.visible"
                  [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                  [label]="bin.label"
                  [title]="bin.title">
                </map-marker>
                <map-info-window #window="mapInfoWindow">
                  <div id="iw-container">
                    <div id="binInfoWindow_multiSites"></div>
                  </div>
                </map-info-window>
                }
              }
            </map-marker-clusterer>
          }
          @if (!moreThanMax) {
            @for (bin of markersArray; track bin; let i = $index) {
              @if(isNumber(bin.lat) && isNumber(bin.lng)){
                <map-marker #marker="mapMarker"
                (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                [position]="{lat: bin.lat, lng: bin.lng}"
                [visible]="bin.visible"
                [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                [label]="bin.label"
                [title]="bin.title">
              </map-marker>
              <map-info-window #window="mapInfoWindow">
                <div id="iw-container">
                  <div id="binInfoWindow_singleSite"></div>
                </div>
              </map-info-window>
              }
            }
          }
        </google-map>
      </div>
    </div>
    <div class="table-wrapper">
      @if (isShowTable&&isAllAddressesValid) {
        <div>
          <mat-card>
            @if (!isEnabledEdit&&!spinnerActive) {
              <div class="mobile-apply-button-wrapper">
                <button mat-button class="apply-button" color="primary" (click)="pushToApi()">
                  <div [ngClass]="{'hide':!spinnerExist, 'hide-zero-pixel' : !spinnerExist}" class="spinner-border mobile-spinner" role="status">
                    <span class="sr-only"></span>
                  </div>
                  @if (!spinnerExist) {
                    <div>{{ 'SITE_MANAGMENT.UPDATE' | translate }}</div>
                  }
                </button>
              </div>
            }
            <mat-form-field>
              <mat-label>{{ 'OTHER.SEARCH' | translate }}</mat-label>
              <input matInput [ngModel]="filteredValue" (keyup)="applyFilter($event.target['value'])" #filterInput>
            </mat-form-field>
            <div>
              <table mat-table class="table-mobile" #table [dataSource]="dataSource" matSort matSortStart="asc" matSortActive="binStickerId"
                matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                <ng-container matColumnDef="binStickerId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SITE_MANAGMENT.STICKER_ID' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.binStickerId }}</td>
                </ng-container>
                <ng-container matColumnDef="binAddress">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SITE_MANAGMENT.ADDRESS' | translate }}</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <div [ngClass]="{'hide-zero-pixel' : !element.isValidAddress, 'div-hidden' : !element.isValidAddress}">
                      {{ element.binAddress }}
                    </div>
                    <div class="input-address" [ngClass]="{'hide-zero-pixel' : element.isValidAddress, 'div-hidden' : element.isValidAddress}">
                      <mat-form-field class="address-form-field-mobile">
                        <input type="text" class="insert-new-address" matInput #binAddressInput placeholder="{{ 'SITE_MANAGMENT.INSERT_BIN_ADDRESS' | translate }}" (onAddressChange)="handleAddressChange($event, element, i)" (blur)="onAddressInputBlur($event, element, i)">
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="binNeighborhood">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.binNeighborhood }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="binComments">
                  <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.COMMENTS' | translate }}</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    @if (element.isValidAddress) {
                      <div>
                        <textarea class="textarea-wrapper" value="{{ element.binComments }}" matInput [ngModel]="element.binComments" (change)="onBinCommentChange($event, element, i)"
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5">
                        </textarea>
                      </div>
                    } @else {
                      {{ element.binComments }}
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="binWazeLink">
                  <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.WAZE_LINK' | translate }}</th>
                  <td mat-cell *matCellDef="let element"><a href="{{ element.binWazeLink }}"><img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt=""></a></td>
                </ng-container>
                <ng-container matColumnDef="linkToMappingTool">
                  <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.MAPPING_TOOL_LINK' | translate }}</th>
                  <td mat-cell *matCellDef="let element"><a routerLink="/preInstallationMapping/{{element.linkToMappingTool}}" [class.disabled]="element.binIsNotHere ? true : null">{{ 'SITE_MANAGMENT.MAPPING_TOOL' | translate }}</a></td>
                </ng-container>
                <ng-container matColumnDef="linkToInstallationWizard">
                  <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.NEW_INSTALLATION_WIZARED' | translate }}</th>
                  <td mat-cell *matCellDef="let element"><a routerLink="/newinstallationwizard/{{element.linkToInstallationWizard}}" [class.disabled]="element.binIsNotHere ? true : null">{{ 'SITE_MANAGMENT.NEW_INSTALLATION_WIZARED' | translate }}</a></td>
                </ng-container>
                <ng-container matColumnDef="binIsNotHere">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LIST_COLUMNS.STATUS_DESC' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <button class="exist-btn" (click)="reportBinIsNotHere(element)" mat-icon-button color="warn" aria-label="Report bin is not here">
                      @if (!element.binIsNotHere) {
                        <div>
                          <img class="bin-is-not-here-icon" src="assets/images/siteManagment/report_problem-24px.svg" alt="">
                        </div>
                      } @else {
                        @if (element.binIsNotHere) {
                          <div>
                            <img class="bin-is-not-here-icon" src="assets/images/siteManagment/undo-24px.svg" alt="">
                          </div>
                        }
                      }
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="mobileDisplayedColumns;"></tr>
                <tr mat-row [ngClass]="{'bin-not-exist-row': row.binIsNotHere}" *matRowDef="let row; columns: mobileDisplayedColumns;"></tr>
              </table>
            </div>
          </mat-card>
        </div>
      }
      <div class="paginator-container" [hidden]="spinnerActive || !isShowTable">
        <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="15"></app-pagination>
      </div>
    </div>
  </div>
}
