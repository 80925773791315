import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartModule } from 'angular-highcharts';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import { Router } from '@angular/router';
import { ApiStore } from '../../web-services/api/api.store';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';

import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-collection-report-pie',
  standalone: true,
  imports: [TranslateModule, MatCardModule, RTLDivDirectiveDirective, MatDividerModule, MatTabsModule, ChartModule],
  templateUrl: './collection-report-pie.component.html',
  styleUrl: './collection-report-pie.component.scss'
})
export class CollectionReportPieComponent {
  isMobile: boolean = false;
  chart: Chart | undefined;
  donutChart: any;
  donutChartToday: any;
  translationsObj : any;
  currentLang: any;
  @Output() showCMP = new EventEmitter<boolean>(true);
  spinnerActive: boolean = true;
  spinnerTodayActive: boolean = true;
  @Input() showReportPieBtn: boolean = true;
  @Input() showReportPieTabs: boolean = true;
  @Input() datesDiff:any;

  constructor(
    private translator: TranslatorService,
    private responsiveService: ResponsiveService,
    private apiQuery: ApiQuery,
    private router: Router,
    private apiStore: ApiStore
  ) {
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator
        .getTranslation(value)
        .toPromise();
      this.currentLang = value;
      this.updateChart();
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.apiQuery.selectedStatusPieChart$.subscribe((status) => {
      this.updateChart();
    });
  }

  updateChart() {
	if(this.translationsObj){
		this.spinnerActive = true;
		this.spinnerTodayActive = true;
		if (this.router.url.includes("collections")) {
		  this.apiQuery.collectionsStatsInCollections$.subscribe((data: any) => {
			this.spinnerActive = true;
			if (data.length == 0) return;
			let total = 0;
			let count0_25 = 0;
			let count26_50 = 0;
			let count51_75 = 0;
			let count76_85 = 0;
			let count86_100 = 0;
			if (!_.isEmpty(data[0])) {
			  _.each(data, (item) => {
				count0_25 += item.count0_25 == undefined ? 0 : item.count0_25;
				count26_50 += item.count26_50 == undefined ? 0 : item.count26_50;
				count51_75 += item.count51_75 == undefined ? 0 : item.count51_75;
				count76_85 += item.count76_85 == undefined ? 0 : item.count76_85;
				count86_100 += item.count86_100 == undefined ? 0 : item.count86_100;
			  });
			  total =
				count0_25 + count26_50 + count51_75 + count76_85 + count86_100;
			}
			this.initDonut(total,count0_25,count26_50,count51_75,count76_85,count86_100);
		  });
		} else if (this.router.url.includes("dashboard")) {
		  this.apiQuery.collectionsStats$.subscribe((data: any) => {
        this.spinnerActive = true;
        if (data.length == 0) return;
        let total = 0;
        let count0_25 = 0;
        let count26_50 = 0;
        let count51_75 = 0;
        let count76_85 = 0;
        let count86_100 = 0;
        if (!_.isEmpty(data[0])) {
          _.each(data, (item) => {
            count0_25 += item.count0_25 == undefined ? 0 : item.count0_25;
            count26_50 += item.count26_50 == undefined ? 0 : item.count26_50;
            count51_75 += item.count51_75 == undefined ? 0 : item.count51_75;
            count76_85 += item.count76_85 == undefined ? 0 : item.count76_85;
            count86_100 += item.count86_100 == undefined ? 0 : item.count86_100;
            total += item.total_num_of_collections_per_site == undefined ? 0 : item.total_num_of_collections_per_site;
          });
        }
        this.initDonut(total,count0_25,count26_50,count51_75,count76_85,count86_100);
		  });
		}
	
		this.apiQuery.collectionsStatsToday$.subscribe((data: any) => {
		  this.spinnerTodayActive = true;
		  if (data.length == 0) return;
		  this.spinnerTodayActive = false;
		  let total = 0;
		  let count0_25 = 0;
		  let count26_50 = 0;
		  let count51_75 = 0;
		  let count76_85 = 0;
		  let count86_100 = 0;
		  _.each(data, (item) => {
        count0_25 += item.count0_25 == undefined ? 0 : item.count0_25;
        count26_50 += item.count26_50 == undefined ? 0 : item.count26_50;
        count51_75 += item.count51_75 == undefined ? 0 : item.count51_75;
        count76_85 += item.count76_85 == undefined ? 0 : item.count76_85;
        count86_100 += item.count86_100 == undefined ? 0 : item.count86_100;
        total += item.total_num_of_collections_per_site == undefined ? 0 : item.total_num_of_collections_per_site;
		  });
		  this.initDonutToday(total,count0_25,count26_50,count51_75,count76_85,count86_100);
		});
	}
  }

  closeCMP() {
    this.showCMP.emit(false);
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  initDonut(total: any, count0_25: any, count26_50: any, count51_75: any, count76_85: any, count86_100: any) {
    const donut = new Chart({
      chart: {
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 100,
        marginTop: -10,
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false,
      },
      legend: {
        align: this.currentLang != "iw" ? "left" : "right",
        layout: "vertical",
        itemMarginBottom: 5,
        itemStyle: {
          fontFamily: "Open Sans",
          fontSize: "14px",
          color: "#3A4743",
          fontWeight: "normal",
        },
      },
      title: {
        text: "<strong>" + total + "<br>" + this.translationsObj.WIDGETS.COLLECTIONS + "</strong>",
        align: "center",
        verticalAlign: "middle",
        y: -40,
        style: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: "14px",
          fontWeight: "600",
        },
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: '',
        useHTML: true,
        formatter: function() {
          return `<div style='flex-direction: row;align-items: center;padding: 4px 8px;width: max-content;height: max-content;top: 85px;background: #F5F6FA;box-shadow: 0px 2px 8px rgba(93, 120, 112, 0.2);border-radius: 3px;display: flex;'><img src='assets/images/dashboard/bin.svg' alt=''/><div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: normal;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 0;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>
		  ${this.point.name}
		  </div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: 600;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 1;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>
		  ${this.point.y} (${this.point.percentage.toFixed()}%)</div></div></div>`;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            distance: -50,
          },
          center: ["50%", "50%"],
          size: "90%",
          showInLegend: true,
        },
      },
      series: [
        {
          name: "",
          point: {
            events: {
              legendItemClick: function () {
                return false;
              },
              click: (event: any) => {
                if (this.router.url.includes("collections")) {
                  this.apiStore.update({ selectedReportPieChart: event });
                }
              }
            },
          },
          data: [
            {
              name: "0%-25%",
              color: "#CDD2D4",
              y: count0_25,
            },
            {
              name: "26%-50%",
              color: "#B1EAFF",
              y: count26_50,
            },
            {
              name: "51%-75%",
              color: "#7CDAF7",
              y: count51_75,
            },
            {
              name: "76%-85%",
              color: "#0EADE8",
              y: count76_85,
            },
            {
              name: "86%-100%",
              color: "#469BFF",
              y: count86_100,
            },
          ],
          type: "pie",
          innerSize: "50%",
        },
      ],
    });
    this.donutChart = donut;
    this.spinnerActive = false;
  }

  initDonutToday(total: any,count0_25: any,count26_50: any,count51_75: any,count76_85: any,count86_100: any) {
    const donut = new Chart({
      chart: {
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 100,
        marginTop: -10,
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false,
      },
      legend: {
        align: this.currentLang != "iw" ? "left" : "right",
        layout: "vertical",
        itemMarginBottom: 5,
        itemStyle: {
          fontFamily: "Open Sans",
          fontSize: "14px",
          color: "#3A4743",
          fontWeight: "normal",
        },
      },
      title: {
        text: "<strong>" + total + "<br>" + this.translationsObj.WIDGETS.COLLECTIONS + "</strong>",
        align: "center",
        verticalAlign: "middle",
        y: -40,
        style: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: "14px",
          fontWeight: "600",
        },
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: '',
        useHTML: true,
        formatter: function() : any {
          return `<div style='flex-direction: row;align-items: center;padding: 4px 8px;width: max-content;height: max-content;top: 85px;background: #F5F6FA;box-shadow: 0px 2px 8px rgba(93, 120, 112, 0.2);border-radius: 3px;display: flex;'><img src='assets/images/dashboard/bin.svg' alt=''/><div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: normal;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 0;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${
            this.point.name
          }</div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: 600;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 1;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${
            this.point.y
          } (${this.point.percentage.toFixed()}%)</div></div></div>`;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            distance: -50,
          },
          center: ["50%", "50%"],
          size: "90%",
          showInLegend: true,
        },
      },
      series: [
        {
          name: "",
          point: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
          data: [
            {
              name: "0%-25%",
              color: "#CDD2D4",
              y: count0_25,
            },
            {
              name: "26%-50%",
              color: "#B1EAFF",
              y: count26_50,
            },
            {
              name: "51%-75%",
              color: "#7CDAF7",
              y: count51_75,
            },
            {
              name: "76%-85%",
              color: "#0EADE8",
              y: count76_85,
            },
            {
              name: "86%-100%",
              color: "#469BFF",
              y: count86_100,
            },
          ],
          type: "pie",
          innerSize: "50%",
        },
      ],
    });
    this.donutChartToday = donut;
  }
}
