@if (!isMobile) {
  <div>
    <div class="padded inventory-page" rtlDiv>
      <div class="inventory-header" rtlDiv>
        <div class='inventory-header-left'>
          <div class='header-bold' rtlDiv>
            {{ 'TITLES.SITE_MANAGMENT' | translate }}
          </div>
        </div>
      </div>
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="inventory-view">
      <mat-tab-group mat-stretch-tabs="false">
        <mat-tab label="{{'NEW_WORKPLAN.SITES' | translate}}">
          <mat-card>
            <div class='spinner-container' [hidden]="!spinnerSitesActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="spinnerSitesActive">
              <div class='search-input-container' rtlDiv>
                <div class="search-frame">
                  <input class='search-input' id="sites_filter" type="text" (input)="applySitesFilter($event.target)" placeholder="{{
                    'OTHER.SEARCH' | translate }}" name="">
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportSitesToCsv()" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    <div class="manage-bins-btn-container" (click)="openCreateSite()" [hidden]="spinnerSitesActive" rtlDiv>
                      <a class="manage-bins-btn" rtlDiv>{{ 'SITE_MANAGMENT.CREATE_SITE' | translate }} <span><img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/></span></a>
                    </div>
                  </div>
                </div>
                <table class="table" mat-table #siteTable [dataSource]="getSitesData()" matSort matSortStart="asc"
                  matSortActive="site_name" matSortDisableClear (matSortChange)="sortSitesData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox id="inventory-header-checkbox" (change)="$event ? masterSitesToggle() : null"
                        [checked]="siteSelection.hasValue() && isSitesAllSelected()"
                        [indeterminate]="siteSelection.hasValue() && !isSitesAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? siteSelection.toggle(row) : null"
                        [checked]="siteSelection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="space th-title">
                    {{ 'TITLES.SITE_NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value">
                      <a class="link-h" routerLink="/siteConfig/{{element ? element.site_id: ''}}">{{ element ? element.site_name: '' }}</a>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_id" class="space th-title">
                    {{ 'LIST_COLUMNS.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value">
                      <span>{{element ? element.site_id: ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_num_of_installed_on_site">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_num_of_installed_on_site" class="space th-title">
                    {{ 'SITE_MANAGMENT.INSTALLED_DEVICES' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_num_of_installed_on_site: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="site_num_of_pending_swap">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_num_of_pending_swap" class="space th-title">
                    {{ 'SITE_MANAGMENT.PENDING_SWAP_DEVICES' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_num_of_pending_swap: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="site_num_of_pending_verification">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_num_of_pending_verification" class="space th-title">
                    {{ 'SITE_MANAGMENT.PENDING_VERIFICATION_DEVICES' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_num_of_pending_verification: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="site_num_of_pending_installation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_num_of_pending_installation" class="space th-title">
                    {{ 'SITE_MANAGMENT.PENDING_INSTALLATION_DEVICES' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_num_of_pending_installation: ''}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedSitesColumns"></tr>
                  <tr class="sites-row" mat-row *matRowDef="let row; columns: displayedSitesColumns;"></tr>
                </table>
              </mat-card-content>
              @if (sitesTableLength > 0 && !spinnerSitesActive) {
                <div>
                  <app-pagination [chosenBins]="filteredData" (pageChange)='onSitesPageChange($event)' [filesPerPage]="10"></app-pagination>
                </div>
              }
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-top-container">
        <div class="mobile-inventory-header">
          <div class="mobile-left-title">{{ 'TITLES.SITE_MANAGMENT' | translate }}</div>
        </div>
        <div class="mobile-separate">
          <mat-divider class="mobile-line"></mat-divider>
        </div>
      </div>
      <div class="mobile-group-tabs">
        <mat-tab-group mat-stretch-tabs="false">
          <mat-tab label="{{'NEW_WORKPLAN.SITES' | translate}}">
            <ng-template matTabContent>
              <div class="card mobile-card">
                <div class="card-body">
                  <div class="mobile-search-excel">
                    <div class='mobile-search-container'>
                      <input class='mobile-input' type="text" (keyup)="applySitesFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                        id="mobile-site-managment" rtlDiv>
                        <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                      </div>
                      <div class='mobile-table-export-container' rtlDiv>
                        <div class='mobile-table-export-btns-wrapper' (click)="exportSitesToCsv()" rtlDiv>
                          <img class='mobile-table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="spinner-border mobile-spinner" [hidden]="!spinnerSitesActive">
                      <div class="spinner"></div>
                    </div>
                    <table rtlDiv class="mobile-table" #table mat-table [dataSource]="getSitesData()" multiTemplateDataRows
                      [ngClass]="{'show':!spinnerSitesActive,'hide':spinnerSitesActive}"
                      matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortSitesData($event)" matSortDirection="desc"
                      matSortActive="site_name">
                      <ng-container matColumnDef="site_name">
                        <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="site_name" mat-header-cell *matHeaderCellDef>
                          {{ 'TITLES.SITE_NAME' | translate }}
                        </th>
                        <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element">
                          <a class="link-h" routerLink="/siteConfig/{{element ? element.site_id: ''}}">{{ element ? element.site_name: '' }}</a>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="site_id">
                        <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="site_id" mat-header-cell *matHeaderCellDef>
                        {{ 'LIST_COLUMNS.ID' | translate }} </th>
                        <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element">
                          <span class="cut-txt"> {{element ? element.site_id: ''}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="arrowDown">
                        <th class="mobile-th" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          <div rtlDiv class="mobile-status-value">
                            @if (expandedElement !== element) {
                              <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                            }
                            @if (expandedElement === element) {
                              <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                            }
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="expandedDetail">
                        <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                          <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="mobile-element-diagram" rtlDiv>
                              <div class="dFlex">
                                <div class="mobile-id-details-title" rtlDiv>{{ 'SITE_MANAGMENT.INSTALLED_DEVICES' | translate }}</div>
                                <div class="mobile-id-details-value">
                                  <span>{{element ? element.site_num_of_installed_on_site: ''}}</span>
                                </div>
                              </div>
                              <div class="dFlex">
                                <div class="mobile-id-details-title" rtlDiv>{{ 'SITE_MANAGMENT.PENDING_SWAP_DEVICES' | translate }}</div>
                                <div class="mobile-id-details-value">
                                  <span>{{element ? element.site_num_of_pending_swap: ''}}</span>
                                </div>
                              </div>
                              <div class="dFlex">
                                <div class="mobile-id-details-title" rtlDiv>{{ 'SITE_MANAGMENT.PENDING_VERIFICATION_DEVICES' | translate }}</div>
                                <div class="mobile-id-details-value">
                                  <span>{{element ? element.site_num_of_pending_verification: ''}}</span>
                                </div>
                              </div>
                              <div class="dFlex">
                                <div class="mobile-id-details-title" rtlDiv>{{ 'SITE_MANAGMENT.PENDING_INSTALLATION_DEVICES' | translate }}</div>
                                <div class="mobile-id-details-value">
                                  <span>{{element ? element.site_num_of_pending_installation: ''}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr style="display: flex;width: 100%;" mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                        (click)="expandedElement = expandedElement === element ? null : element">
                      </tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                    </table>
                    @if (sitesTableLength > 0) {
                      <div>
                        <app-pagination [chosenBins]="filteredData" (pageChange)='onSitesPageChange($event)' [filesPerPage]="10"></app-pagination>
                      </div>
                    }
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    }
