@if (!isMobile) {
  <div>
    <div class='gray-background' (click)="closeModal()"></div>
    <div class="manage-bin-modal" rtlDiv>
      <div class="manage-bin-header">{{ 'TITLES.MANAGE_TRUCKS' |  translate }}</div>
      <div class="manage-bit-body">
        <div class="bin-name-container">
          <div class="sub-title">{{ 'TITLES.TRUCK_ID' | translate }}</div>
          @if (translationsObj) {
            <div class="bin-name-input truck-name-field">
              <input disabled type="text" [(ngModel)]="truckName" class="input-bin-name" name="truckName" />
            </div>
          }
        </div>
        <div class='bin-name-container' rtlDiv>
          <div class='sub-title' rtlDiv>{{ 'TITLES.SITE_NAME' | translate }}</div>
          <mat-form-field class='workplan-input-manage' rtlDiv>
            <mat-select [disabled]="PartialManagement" #siteMatSelect panelClass="{{rtlStr}}" class="deep" name="site" [(ngModel)]="selectedSites" #siteSelect="ngModel" rtlDiv>
              @for (item of sitesListData; track item) {
                <mat-option class="no-checkbox list-item" [value]="item" (click)="selectAllSites(siteSelect,sitesListData,item)"
                rtlDiv>{{item.site_name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="bin-name-container">
          <div class="sub-title">{{'TITLES.TRUCK_CAPACITY' | translate }}</div>
          <div class="bin-name-input">
            <input [disabled]="PartialManagement" type="number" (keyup)="validateCapacity(truckCapacity)" [(ngModel)]="truckCapacity" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}"
            name="truckName" /></div>
          </div>
          <div class="bin-name-container">
            <div class="sub-title">{{'TITLES.MAX_NUM_OF_BINS_TO_COLLECT' | translate }}</div>
            <div class="bin-name-input">
              <input [disabled]="PartialManagement" type="text" [(ngModel)]="truckMaxBins" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}" name="truckName" />
            </div>
          </div>
          <div class="bin-name-container">
            <div class="sub-title">{{'TITLES.LICENSE_PLATE' | translate }}</div>
            <div class="bin-name-input"><input type="text" [(ngModel)]="truckLicensePlate" class="input-bin-name"
            name="truckName" /></div>
          </div>
          <div class="bin-name-container">
            <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.ROUTE_TYPE' | translate }}</div>
            <mat-form-field class='workplan-input-manage' rtlDiv>
              <mat-select [disabled]="PartialManagement" #routeTypeMatSelect panelClass="{{rtlStr}}" class="deep" name="route" [(ngModel)]="selectedRoute" #routeSelect="ngModel" rtlDiv>
                @for (item of routeListData; track item) {
                  <mat-option class="no-checkbox list-item" [value]="item" (click)="selectRouteType(routeSelect,routeListData,item)"
                  rtlDiv>{{currentLang != 'iw' ? item.optimization_type_name : item.optimization_type_name_hebrew}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class='main-btns-wrapper'>
            <div class='btns-container' rtlDiv>
              <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
              <div class='apply-btn' (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-modal-title" rtlDiv>
        <div class="dflex" (click)="closeModal()">
          <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
        </div>
        <div>
          <span class="main-title">{{ 'TITLES.MANAGE_TRUCKS' | translate }}</span>
        </div>
      </div>
      <div class="mobile-popup-container" rtlDiv>
        <div class="mobile-prop-frame">
          <div class="events-type-container">
            <div class="mobile-bin-name-container" rtlDiv>
              <div class="mobile-sub-title">{{ 'TITLES.TRUCK_ID' | translate }}</div>
              <div class="event-type-input event-types" rtlDiv>
                @if (translationsObj) {
                  <div class="bin-name-input">
                    <input disabled type="text" [(ngModel)]="truckName" class="input-bin-name" name="truckName" rtlDiv/>
                  </div>
                }
              </div>
            </div>
            <div rtlDiv>
              <div class='mobile-sub-title' rtlDiv>{{ 'TITLES.SITE_NAME' | translate }}</div>
              <mat-form-field class="event-type-input event-types" rtlDiv>
                <mat-select disableOptionCentering [disabled]="PartialManagement" #siteMatSelect panelClass="{{rtlStr}}" class="deep" name="site" [(ngModel)]="selectedSites" #siteSelect="ngModel" rtlDiv>
                  @for (item of sitesListData; track item) {
                    <mat-option class="list-item" [value]="item" (click)="selectAllSites(siteSelect,sitesListData,item)"
                    rtlDiv>{{item.site_name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mobile-bin-name-container">
              <div class="mobile-sub-title">{{'TITLES.TRUCK_CAPACITY' | translate }}</div>
              <div class="event-type-input event-types" rtlDiv>
                <div class="bin-name-input">
                  <input [disabled]="PartialManagement" type="number" (keyup)="validateCapacity(truckCapacity)" [(ngModel)]="truckCapacity" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}" name="truckName" rtlDiv/>
                </div>
              </div>
            </div>
            <div class="mobile-bin-name-container">
              <div class="mobile-sub-title">{{'TITLES.MAX_NUM_OF_BINS_TO_COLLECT' | translate }}</div>
              <div class="event-type-input event-types" rtlDiv>
                <div class="bin-name-input">
                  <input rtlDiv [disabled]="PartialManagement" type="text" [(ngModel)]="truckMaxBins" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}" name="truckName" />
                </div>
              </div>
            </div>
            <div class="mobile-bin-name-container">
              <div class="mobile-sub-title">{{'TITLES.LICENSE_PLATE' | translate }}</div>
              <div class="event-type-input event-types" rtlDiv>
                <div class="bin-name-input">
                  <input type="text" [(ngModel)]="truckLicensePlate" class="input-bin-name" name="truckName" rtlDiv/>
                </div>
              </div>
            </div>
            <div>
              <div class="mobile-sub-title" rtlDiv>{{ 'LIST_COLUMNS.ROUTE_TYPE' | translate }}</div>
              <mat-form-field class="event-type-input event-types" rtlDiv>
                <mat-select disableOptionCentering [disabled]="PartialManagement" #routeTypeMatSelect panelClass="{{rtlStr}}" class="deep" name="route" [(ngModel)]="selectedRoute" #routeSelect="ngModel" rtlDiv>
                  @for (item of routeListData; track item) {
                    <mat-option class="list-item" [value]="item" (click)="selectRouteType(routeSelect,routeListData,item)"
                    rtlDiv>{{currentLang != 'iw' ? item.optimization_type_name : item.optimization_type_name_hebrew}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class='mobile-main-btns-wrapper'>
          <div class='btns-container' rtlDiv>
            <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
            <div class='apply-btn' (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  }
