<div class="bin-list-view">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title class="title">{{ datesDiffTxt }}</mat-card-title>
    </mat-card-header>
    <mat-divider class="top-divider"></mat-divider>
    <div class='spinner-container' [hidden]="!spinnerActive">
      <div class="spinner"></div>
    </div>
    <mat-card-content [hidden]="spinnerActive">
      <div class='search-input-container'>
        <div>
          <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
            name="" rtlDiv>
            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
          </div>
          <div class='table-export-container' rtlDiv>
            <div class='table-export-btns-wrapper' (click)="exportToCsv()">
              <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
              <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
            </div>
          </div>
        </div>
        <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_name"
          matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">

          <ng-container matColumnDef="binName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="list-title th-title">
              {{ usageType == 1 ? (translationsObj ? translationsObj.SITE_MANAGMENT.BIN_NAME : '') : (translationsObj ? translationsObj.SITE_MANAGMENT.TANK_NAME : '')}}
            </th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'reports',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="cut-txt-name binNameHref"> {{element ? element.bin_name : ''}}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="siteName">
            <th [hidden]="usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="list-title space th-title">
            {{ 'TRUCK_MAP.SITE' | translate }}</th>
            <td [hidden]="usageType == 2" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.site_name: ''}} </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="list-title space th-title">
            {{ 'LIST_COLUMNS.ADDRESS' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{element ? element.bin_address : ''}}" class="td-value space" [ngClass]="{'cut-txt-name' : element && element.bin_address && element.bin_address.length > 11}"> {{element ? element.bin_address : ''}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="event_time" class="list-title space th-title">
            {{ 'LIST_COLUMNS.EVENT_TIME' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.event_time: ''}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="event_name" class="list-title space th-title">
            {{ 'LIST_COLUMNS.EVENT_NAME' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="td-value space">
              @if (currentLang != 'iw') {
                <span>{{element ? element.event_name: ''}}</span>
              }
              @if (currentLang == 'iw') {
                <span>{{element ? element.event_name_he: ''}}</span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="information">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="event_body" class="list-title space th-title">{{
            'LIST_COLUMNS.EVENT_INFO' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="td-value space">
              @if (currentLang != 'iw') {
                <span>{{element ? element.event_body: ''}}</span>
              }
              @if (currentLang == 'iw') {
                <span>{{element ? element.event_body_he: ''}}</span>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
      @if (dataTableLength > 0) {
        <div>
          <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="7"></app-pagination>
        </div>
      }
    </mat-card>
  </div>