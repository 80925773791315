@if(!isMobile){
    <div>
        <div class="top-container">
            <div class="flex">
                <div class="dashboard-header">{{ 'TITLES.DASHBOARD' | translate }}</div>
                <div class="menu-Cmp dropdown">
                    <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="editImg" alt="" src="../../../assets/images/dashboard/EditDashboard.svg">
                        <span rtlDiv class="menu-txt-btn">{{ 'TITLES.EDIT_DASHBOARD' | translate }}</span>
                    </div>
                    <div class="dropdown-menu list-items" rtlDiv>
                        <div class="menuItem">     
                            @if(showBinStatusCMP){
                                <div class="exist-item">
                                    <span class="item-txt">{{ 'WIDGETS.WORKPLAN_PROGRESS' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">    
                                    <button (click)="showComponent('1',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                </div> 
                            }@if(!showBinStatusCMP){
                                <div class="notExist-item">
                                    <span class="item-txt">{{ 'WIDGETS.WORKPLAN_PROGRESS' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="assets/images/dashboard/notSelected.svg"/>
                                    <button (click)="showComponent('1',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                </div>  
                            }                
                        </div>
                        <div class="menuItem">
                            @if(showActiveBinsCMP){
                                <div class="exist-item">
                                    @if(usageType == 1){
                                        <span class="item-txt">{{ 'WIDGETS.TOTAL_ACTIVE' | translate }}</span>
                                    }@if(usageType != 1){
                                        <span class="item-txt">{{ 'WIDGETS.TOTAL_ACTIVE_TANKS' | translate }}</span>
                                    }
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                    <button (click)="showComponent('2',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                </div>
                            }@if(!showActiveBinsCMP){
                                <div class="notExist-item">
                                    <span class="item-txt">{{ 'WIDGETS.TOTAL_ACTIVE' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/notSelected.svg">
                                    <button (click)="showComponent('2',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                </div>
                            }
                        </div>
                        <div class="menuItem">
                            @if(showMapCMP){
                                <div class="exist-item">
                                    <span class="item-txt">{{ 'WIDGETS.MAP_VIEW' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                    <button (click)="showComponent('3',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                </div>
                            }
                            @if(!showMapCMP){
                                <div class="notExist-item">
                                    <span class="item-txt">{{ 'WIDGETS.MAP_VIEW' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/notSelected.svg">
                                    <button (click)="showComponent('3',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                </div>
                            }
                        </div>
                        @if(usageType == 1){
                            <div class="menuItem">
                                @if(showCollectionKpisCMP){
                                    <div class="exist-item">
                                        <span class="item-txt">{{ 'TITLES.COLLECTION_KPI_EDIT' | translate }}</span>
                                        <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                        <button (click)="showComponent('4',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                    </div>
                                }@if(!showCollectionKpisCMP){
                                    <div class="notExist-item">
                                        <span class="item-txt">{{ 'TITLES.COLLECTION_KPI_EDIT' | translate }}</span>
                                        <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/notSelected.svg">
                                        <button (click)="showComponent('4',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                    </div>  
                                }                    
                            </div>
                        }
                        @if(usageType == 1){
                            <div class="menuItem">
                                @if(showCollectionReport){
                                    <div class="exist-item">
                                        <span class="item-txt">{{ 'TITLES.COLLECTION_REPORT' | translate }}</span>
                                        <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                        <button (click)="showComponent('5',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                    </div>
                                }@if(!showCollectionReport){
                                    <div class="notExist-item">
                                        <span class="item-txt">{{ 'TITLES.COLLECTION_REPORT' | translate }}</span>
                                        <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/notSelected.svg">
                                        <button (click)="showComponent('5',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                    </div>
                                }
                            </div>
                        }
                        <div class="menuItem">
                            @if(showAlertsCMP){
                                <div class="exist-item">
                                    <span class="item-txt">{{ 'TITLES.ALERTS' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                    <button (click)="showComponent('6',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                </div>
                            }@if(!showAlertsCMP){
                                <div class="notExist-item">
                                    <span class="item-txt">{{ 'TITLES.ALERTS' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/notSelected.svg">
                                    <button (click)="showComponent('6',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                </div>
                            }
                        </div>
                        <div class="menuItem">
                            @if(showBinListCMP){
                                <div class="exist-item">
                                    <span class="item-txt">{{ 'TITLES.LIST_VIEW' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                    <button (click)="showComponent('7',true)" rtlDiv class="removeItem">{{ 'SITE_MANAGMENT.REMOVE' | translate }}</button>
                                </div>
                            }@if(!showBinListCMP){
                                <div class="notExist-item">
                                    <span class="item-txt">{{ 'TITLES.LIST_VIEW' | translate }}</span>
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/notSelected.svg">
                                    <button (click)="showComponent('7',true)" rtlDiv class="addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                </div> 
                            }                   
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider class="top-divider"></mat-divider>
        </div>
        <div class="padded dashboard-page">
            <div class="dashboard-container">
                @if(showBinStatusCMP || showActiveBinsCMP){
                    <div class="first-row" id="first-row">
                        <div class="status-bins-container">
                            @if(showBinStatusCMP){
                                <app-bin-status rtlDiv (showCMP)="showCMPBinStatusHandler($event)"></app-bin-status>
                            }@if(showActiveBinsCMP){
                                <app-active-bins rtlDiv (showCMP)="showCMPActiveBinsHandler($event)"></app-active-bins>
                            }
                        </div>
                    </div>
                }
                <div class="second-row" [ngClass]="{'hideCMP' : !showMapCMP}">
                    <app-map-live-view (showCMP)="showCMPMapHandler($event)"></app-map-live-view>
                </div>
                @if(usageType == 1){
                    <div class="kpi-row" [ngClass]="{'hideCMP' : !showCollectionKpisCMP}">
                        <app-collection-kpi [datesDiff]="' - ' + ('WIDGETS.MONTH_AGO' | translate )" (showCMP)="showCMPCollectionKPIsHandler($event)"></app-collection-kpi>
                    </div>
                }
                @if(showCollectionReport || showAlertsCMP){
                    <div class="third-row">
                        @if(showCollectionReport && usageType == 1){
                            <app-collection-report-pie rtlDiv [datesDiff]="('WIDGETS.COLLECTION_REPORT' | translate )" (showCMP)="showCMPCollectionRepHandler($event)"></app-collection-report-pie>
                        }@if(showAlertsCMP){
                            <app-alerts-history rtlDiv (showCMP)="showCMPAlertsHandler($event)"></app-alerts-history>
                        }
                    </div>
                }
                <div class="fourth-row" [ngClass]="{'hideCMP' : !showBinListCMP}" rtlDiv>
                    <app-bin-list-view (showCMP)="showCMPBinListHandler($event)"></app-bin-list-view>
                </div>
            </div>
        </div>
    </div>
}


@if(isMobile){
    <div>
        <div class="mobile-top-container">
            <div class="mobile-dashboard-header">
                <div class="mobile-left-title">{{ 'TITLES.MOBILE_DASHBOARD' | translate }}</div>      
                <div class="mobile-menu-Cmp dropdown" rtlDiv>
                    <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class="editImg" alt="" src="../../../assets/images/dashboard/EditDashboard.svg">                
                    </div>    
                    <div class="dropdown-menu list-items" rtlDiv>
                        <div class="menuItem">
                            <span class="item-txt">{{ 'WIDGETS.WORKPLAN_PROGRESS' | translate }}</span>
                            @if(showBinStatusCMP){
                                <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                            }
                            @if(!showBinStatusCMP){
                                <button (click)="showComponent('1',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                            }
                        </div>
                        <div class="menuItem">
                            @if(usageType == 1){
                                <span class="item-txt">{{ 'WIDGETS.TOTAL_ACTIVE' | translate }}</span>
                            }@if(usageType != 1){
                                <span class="item-txt">{{ 'WIDGETS.TOTAL_ACTIVE_TANKS' | translate }}</span>
                            }@if(showActiveBinsCMP){
                                <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                            }@if(!showActiveBinsCMP){
                                <button (click)="showComponent('2',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                            }
                        </div>
                        <div class="menuItem">
                            <span class="item-txt">{{ 'WIDGETS.MAP_VIEW' | translate }}</span>
                            @if(showMapCMP){
                                <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                            }@if(!showMapCMP){
                                <button (click)="showComponent('3',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                            }
                        </div>
                        
                        @if(usageType == 1){
                            <div class="menuItem">
                                <span class="item-txt">{{ 'TITLES.COLLECTION_KPI_EDIT' | translate }}</span>
                                @if(showCollectionKpisCMP){
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                }@if(!showCollectionKpisCMP){
                                    <button (click)="showComponent('4',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                }
                            </div>
                            <div class="menuItem">
                                <span class="item-txt">{{ 'TITLES.COLLECTION_REPORT' | translate }}</span>
                                @if(showCollectionReport){
                                    <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                                }@if(!showCollectionReport){
                                    <button (click)="showComponent('5',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                                }
                            </div>
                        }
                        <div class="menuItem">
                            <span class="item-txt">{{ 'TITLES.ALERTS' | translate }}</span>
                            @if(showAlertsCMP){
                                <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                            }@if(!showAlertsCMP){
                                <button (click)="showComponent('6',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                            }
                        </div>
        
                        <div class="menuItem">
                            <span class="item-txt">{{ 'TITLES.LIST_VIEW' | translate }}</span>
                            @if(showBinListCMP){
                                <img rtlDiv class="selectedItem" alt="" src="../../../assets/images/dashboard/selected.svg">
                            }@if(!showBinListCMP){
                                <button (click)="showComponent('7',true)" rtlDiv class="mobile-addItem">{{ 'SITE_MANAGMENT.ADD' | translate }}</button>
                            }
                        </div>
                      </div>    
                </div>
            </div>
            <div class="mobile-separate">
                <mat-divider class="mobile-line"></mat-divider>
              </div>
    
        </div>
        @if(showActiveBinsCMP){
            <app-active-bins (showCMP)="showCMPActiveBinsHandler($event)"></app-active-bins>
        }@if(showBinStatusCMP){
            <app-bin-status (showCMP)="showCMPBinStatusHandler($event)"></app-bin-status>
        }@if(showMapCMP){
            <app-map-live-view (showCMP)="showCMPMapHandler($event)"></app-map-live-view>
        }@if(showCollectionKpisCMP && usageType == 1){
            <app-collection-kpi [datesDiff]="('WIDGETS.COLLECTION_REPORT' | translate )" (showCMP)="showCMPCollectionKPIsHandler($event)"></app-collection-kpi>
        }@if(showCollectionReport && usageType == 1){
            <app-collection-report-pie [datesDiff]="('WIDGETS.COLLECTION_REPORT' | translate )" (showCMP)="showCMPCollectionRepHandler($event)"></app-collection-report-pie>
        }@if(showAlertsCMP){
            <app-alerts-history (showCMP)="showCMPAlertsHandler($event)"></app-alerts-history>
        }@if(showBinListCMP){
            <app-bin-list-view (showCMP)="showCMPBinListHandler($event)"></app-bin-list-view>
        }
    </div>
}

