<div class='gray-background' (click)="closeModal()"></div>
<div class='create-reset-password-modal-wrapper'>
  <div class='main-title'>{{ 'LOGIN.RESET_PASSWORD' | translate }}</div>
  <div class='data-wrapper'>
    <div class="email-title">{{ 'LOGIN.PASSWORD' | translate }}</div>
    <input type="password" class="email-input" placeholder="{{ 'LOGIN.INSERT_PASSWORD' | translate }}" [(ngModel)]="password"/>
    <div class="email-title">{{ 'LOGIN.CONFIRM_PASS' | translate }}</div>
    <input type="password" class="email-input" placeholder="{{ 'LOGIN.RETYPE_PASS' | translate }}" [(ngModel)]="confirmPassword" [ngClass]="{'error-input': passwordMatchFlag}"/>
    @if (passwordMatchFlag) {
      <div class="error-flag">
        <img class='error-icon' src="assets/images/login/error.png" alt="" />
        <div>Incorrect password</div>
      </div>
    }
  </div>
  <div class='main-btns-wrapper'>
    <div class='btns-container'>
      <div class='cancel-btn' (click)="closeModal()">{{ 'LOGIN.CANCEL' | translate }}</div>
      <div class='apply-btn' (click)='sendNewPassword(password, confirmPassword )'>{{ 'LOGIN.SEND' | translate }}</div>
    </div>
  </div>
</div>