import { Component, ViewChild } from '@angular/core';
import { TableUtil } from '../../../tableUtil';
import _ from 'lodash';
import { ClusterInfoModalComponent } from '../cluster-info-modal/cluster-info-modal.component';
import moment from 'moment';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { TranslatorService } from '../../../services/translator_service';
import { MatDialog } from '@angular/material/dialog';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { PaginationComponent } from '../../pagination/pagination.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ApiStore } from '../../../web-services/api/api.store';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-black-status-list-view',
  standalone: true,
  imports: [MatCardModule, RTLDivDirectiveDirective, TranslateModule, MatTableModule, MatSortModule, MatDividerModule,
    PaginationComponent,NgbModule,RouterModule,CommonModule],
  templateUrl: './black-status-list-view.component.html',
  styleUrl: './black-status-list-view.component.scss'
})
export class BlackStatusListViewComponent {
  expandedElement: any;
  isMobile: Boolean;
  currentLang;
  translationsObj;
  spinnerActive: boolean = true;
  binCapacityAnalysisData;
  dataTableLength = 0;
  dataSource = new MatTableDataSource<any>();
  innerDataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  startIndexTableData = 0;
  endIndexTableData = 6;
  tempFilter = "";
  displayedColumns = ["binName","cluster_id","siteName","numOfBlackStatusEvents","eventOpen","blackStatusDuration","numOfCollections","avgCollectionVolume"];
  binsBackUp: Array<any> = [];
  capacityDisplayType: number = 0;
  usageType = 1;
  not_same_linked_sites_id: boolean = true;
  //submitClicked: boolean = false;
  clusterBlackStatusDuration: number = 0;
  clusterBins: Array<any> = [];
  filteredData: Array<object> = [];
  filterTableAccordigToStatus : any = {
    text : '',
    value : ''
  };
  backUpBinCapacityAnalysisArr: Array<any> = [];
  binCapacityAnalysisArr: Array<any> = [];

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
	private apiQuery: ApiQuery,private dialog: MatDialog,private apiStore:ApiStore) {
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator.getTranslation(value).toPromise();
      this.currentLang = value;
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    //this.submitClicked = false;

    this.apiQuery.user$.subscribe((user) => {
      if (!user) {
        return;
      }

      this.capacityDisplayType = user["user_default_capacity_type_id"];
      this.usageType = user["usage_type_id"];

      combineLatest(
        this.apiQuery.binCapacityAnalysis$,
        this.apiQuery.CollectionsStatsForInsights$,
        this.apiQuery.filteredBins$,
        this.apiQuery.sitesClustersList$,
      )
        .pipe()
        .subscribe(([data,collectionsStatsForInsights,bins,clusters])=>{
          this.binCapacityAnalysisArr = [];
          this.spinnerActive = true;
          if (data.length == 0) return;
          
          this.apiStore.update({ selectedInsightsPieChart: {} });     
          this.apiStore.update({ selectedInsightsSamplingChart: {} });     
                                      
          this.filterTableAccordigToStatus["text"] = '';
          this.filterTableAccordigToStatus["value"] = '';		
          
          if (collectionsStatsForInsights.length == 0) return;
          let groups = [];
          _.each(collectionsStatsForInsights, (site) => {
            if(site["site_bins_collections"] != undefined){
              _.each(site["site_bins_collections"]["0_25"], (item) => {    
                item.site_name = site["site_name"];   
                groups.push(item);
              });    
              _.each(site["site_bins_collections"]["26_50"], (item) => {   
                item.site_name = site["site_name"];          
                groups.push(item);
              });
              _.each(site["site_bins_collections"]["51_75"], (item) => {   
                item.site_name = site["site_name"];        
                groups.push(item);
              });
              _.each(site["site_bins_collections"]["76_85"], (item) => {          
                item.site_name = site["site_name"];   
                groups.push(item);
              });
              _.each(site["site_bins_collections"]["86_100"], (item) => {         
                item.site_name = site["site_name"];    
                groups.push(item);
              });
            }
          });
          let res = _.each(_.groupBy(groups, "bin_id"), (item) => {   
            item["avg_volume"] = 0;   
            item["all_volume"] = 0;     
            _.each((item), (collection) => {    
              item["bin_name"] = collection["bin_name"];
              item["site_name"] = collection["site_name"];
              item["bin_id"] = collection["bin_id"];
              item["all_volume"] += collection["bin_fill_level_before_collection"];
            });
            item["avg_volume"] = item["all_volume"]/item.length;       
          });
          const binsCollectionFiltered = _.map(res, b => {
            return {
              bin_name: b["bin_name"],
              site_name:b["site_name"],
              bin_id: b["bin_id"],
              avg_volume: b["avg_volume"]
            };
          });
          _.each(data, (binCapacityAnalysis) => {
            _.each(binCapacityAnalysis['binsWorkPlanCapacityAnalysis'],(binsWorkPlanCapacityAnalysis) => {
                _.each(binsWorkPlanCapacityAnalysis.over_flow_dates,(over_flow_dates) => {
                    let pushedObj = {
                      bin_name: binsWorkPlanCapacityAnalysis.bin_name,
                      num_of_black_status_events: binsWorkPlanCapacityAnalysis.total_num_of_over_flow_dates,
                      black_status_duration: over_flow_dates.event_num_of_day,
                      num_of_collections: binsWorkPlanCapacityAnalysis.num_of_collection_in_requested_period,
                      analysis_time_in_days: binCapacityAnalysis['analysis_time_span_in_days'],
                      site_id: binCapacityAnalysis['site_id'],
                      cluster_id: binsWorkPlanCapacityAnalysis.cluster_id,
                      bin_id: binsWorkPlanCapacityAnalysis.bin_id,
                      site_name: binCapacityAnalysis['site_name'],
                      event_start_date: over_flow_dates.event_start_date
                    };
                    this.binCapacityAnalysisArr.push(pushedObj);                    
                  }
                );
              }
            );
          });
          if (bins.length == 0) return;
          this.spinnerActive = false;
          this.binsBackUp = bins;
          _.each(_.groupBy(this.binsBackUp,(item) => `${item.cluster_id},${item.site_id}`), (clusters) => {
              let cluster_avg = 0;
              _.each(clusters, (bin) => {
                if (this.capacityDisplayType != 0) {
                  cluster_avg += (bin["Bin_live_Data"].fill_level_percent * bin["Bin_thresholds"].bin_max_capacity_in_liters) / 100;
                } else {
                  cluster_avg += bin["Bin_live_Data"].fill_level_percent;
                }
              });
              _.each(clusters, (bin) => {
                bin["cluster_avg"] = cluster_avg / clusters.length;
                bin["number_of_bins_in_cluster"] = clusters.length;
              });
            }
          );
          _.each(this.binCapacityAnalysisArr, (binCapacityAnalysis) => {
            let relevantBin = this.binsBackUp.filter(
              (bin) =>
                bin["site_id"] == binCapacityAnalysis.site_id &&
                bin["cluster_id"] == binCapacityAnalysis.cluster_id &&
                bin["bin_id"] == binCapacityAnalysis.bin_id
            );

            let chosenCluster = clusters.filter(
              (cluster) =>
                cluster["site_id"] == binCapacityAnalysis.site_id &&
                cluster["cluster_id"] == binCapacityAnalysis.cluster_id
            );

            if (relevantBin.length > 0) {
              binCapacityAnalysis["cluster_avg"] = relevantBin[0]["cluster_avg"];
              binCapacityAnalysis["number_of_bins_in_cluster"] = relevantBin[0]["number_of_bins_in_cluster"];
              binCapacityAnalysis["bin_collection_or_refill_rate"] = relevantBin[0]["Bin_live_Data"].bin_collection_or_refill_rate;
            }
            if (chosenCluster.length > 0) {
              binCapacityAnalysis["chosenCluster"] = chosenCluster[0];

              let binsInCluster = this.binsBackUp.filter(
                (bin) =>
                  bin["site_id"] == chosenCluster[0]["site_id"] &&
                  bin["cluster_id"] == chosenCluster[0]["cluster_id"]
              );
              let collection_rate = 0;
              let avg_collection_rate = 0;
              _.each(binsInCluster, (bin) => {
                collection_rate += bin["Bin_live_Data"].bin_collection_or_refill_rate;
              });
              avg_collection_rate = collection_rate / binsInCluster.length;
              binCapacityAnalysis["chosenCluster"]["avg_collection_rate"] = avg_collection_rate;
            }
          });

          this.not_same_linked_sites_id = true;
          if (JSON.parse(sessionStorage.getItem("chosenSites"))) {
            let sitesToCompare = [];
            let isChildsSites = false;
            _.each(JSON.parse(sessionStorage.getItem("chosenSites")),(site) => {
                if (site != 0) {
                  sitesToCompare.push(site);
                }
              }
            );
            isChildsSites = sitesToCompare.some((site) => site.linked_sites_id != 0);
            if (sitesToCompare.length == 1) {
              this.not_same_linked_sites_id = false;
            } else {
              if (isChildsSites) {
                this.not_same_linked_sites_id = sitesToCompare.some((site) => site.linked_sites_id != sitesToCompare[0].linked_sites_id);
              }
            }
          }
          _.each(this.binCapacityAnalysisArr, (binCapacityAnalysis) => {
            let relevantBin = binsCollectionFiltered.filter(
              (bin) =>
                bin["site_name"] == binCapacityAnalysis.site_name &&
                bin["bin_id"] == binCapacityAnalysis.bin_id
            );
            if (relevantBin.length > 0) {
              binCapacityAnalysis["avg_volume"] = relevantBin[0]["avg_volume"];
            }else{
              binCapacityAnalysis["avg_volume"] = 0;
            }
          });
          this.backUpBinCapacityAnalysisArr = this.binCapacityAnalysisArr;
          this.initTableData();
      });
    });

    this.apiQuery.selectedInsightsSamplingChart$.subscribe(status => {		
      let tempFilter = '';
      let eventDurationFilter = [];
      if (!_.isEmpty(status) && this.backUpBinCapacityAnalysisArr.length > 0) {
        if(status['event_type'] == 0){
          this.filterTableAccordigToStatus["text"] = "1-3" + " " + this.translationsObj.COLLECTION_REPORT.DAYS;
          this.filterTableAccordigToStatus["value"] = "#ADE8F4";
        }else if(status['event_type'] == 1){
          this.filterTableAccordigToStatus["text"] = "4-7" + " " + this.translationsObj.COLLECTION_REPORT.DAYS;
          this.filterTableAccordigToStatus["value"] = "#48CAE4";
        }else if(status['event_type'] == 2){
          this.filterTableAccordigToStatus["text"] = "8-14" + " " + this.translationsObj.COLLECTION_REPORT.DAYS;
          this.filterTableAccordigToStatus["value"] = "#0077B6";
        }else if(status['event_type'] == 3){
          this.filterTableAccordigToStatus["text"] = "15+" + " " + this.translationsObj.COLLECTION_REPORT.DAYS;
          this.filterTableAccordigToStatus["value"] = "#023E8A";
        }
        tempFilter = this.filterTableAccordigToStatus["text"].replace(this.translationsObj.COLLECTION_REPORT.DAYS,'').trim().split('-');
        if(tempFilter.length > 1){
          eventDurationFilter = _.filter(this.backUpBinCapacityAnalysisArr, (item) => {
            return item.black_status_duration >= Number(tempFilter[0]) && item.black_status_duration <= Number(tempFilter[1]) && moment(status['event_date']).isBetween(item.event_start_date, moment(item.event_start_date).add(item.black_status_duration-1,'days').format('YYYY-MM-DD'),'days', '[]');
          });
        }else{
          eventDurationFilter = _.filter(this.backUpBinCapacityAnalysisArr, (item) => {
            return item.black_status_duration >= Number(tempFilter[0].replace('+','')) && moment(status['event_date']).isBetween(item.event_start_date, moment(item.event_start_date).add(item.black_status_duration-1,'days').format('YYYY-MM-DD'),'days', '[]');
          });
        }
        this.binCapacityAnalysisArr = eventDurationFilter;	
      }
      this.initTableData();	
    });

    this.apiQuery.selectedInsightsPieChart$.subscribe(status => {		
      let tempFilter = '';
      let tempGroups = [];
      if (!_.isEmpty(status) && this.backUpBinCapacityAnalysisArr.length > 0) {
        if(!status["point"].selected){
          this.filterTableAccordigToStatus["text"] = status["point"].name;
          this.filterTableAccordigToStatus["value"] = status["point"].color;
          tempFilter = this.filterTableAccordigToStatus["text"].replace(this.translationsObj.COLLECTION_REPORT.DAYS,'').trim().split('-');
          if(tempFilter.length > 1){
            tempGroups = _.filter(this.backUpBinCapacityAnalysisArr, (item) => {
              return item.black_status_duration >= Number(tempFilter[0]) && item.black_status_duration <= Number(tempFilter[1]);
            });
          }else{
            tempGroups = _.filter(this.backUpBinCapacityAnalysisArr, (item) => {
              return item.black_status_duration >= Number(tempFilter[0].replace('+',''));
            });
          }
          this.binCapacityAnalysisArr = tempGroups;
        }else{
          this.filterTableAccordigToStatus["text"] = '';
          this.filterTableAccordigToStatus["value"] = '';		
          this.binCapacityAnalysisArr = this.backUpBinCapacityAnalysisArr;	
        }
      }
      this.initTableData();	
    });
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  initTableData(){
    let binsFiltered = [];
    if (!this.not_same_linked_sites_id) {
      binsFiltered = _.map(this.binCapacityAnalysisArr, (b) => {
        return {
          bin_name: b["bin_name"],
          cluster_id_csv: b["number_of_bins_in_cluster"] > 1 ? b["cluster_id"] : 0,
          num_of_black_status_events: b["num_of_black_status_events"],
          event_start_date: b["event_start_date"],
          black_status_duration: b["black_status_duration"],
          num_of_collections: b["num_of_collections"],
          avg_volume_csv: "",
          avg_volume: b["avg_volume"],
          cluster_avg_csv: "",
          cluster_avg: b["cluster_avg"],
          number_of_bins_in_cluster: b["number_of_bins_in_cluster"],
          analysis_time_in_days: b["analysis_time_in_days"],
          site_id: b["site_id"],
          cluster_id: b["cluster_id"],
          bin_id: b["bin_id"],
          events: b["events"],
          site_name: b["site_name"],
          chosenCluster: b["chosenCluster"],
          bin_collection_or_refill_rate: b["bin_collection_or_refill_rate"]
        };
      });
    } else {
      binsFiltered = _.map(this.binCapacityAnalysisArr, (b) => {
        return {
          bin_name: b["bin_name"],
          cluster_id_csv: b["number_of_bins_in_cluster"] > 1 ? b["cluster_id"] : 0,
          site_name: b["site_name"],
          num_of_black_status_events: b["num_of_black_status_events"],
          event_start_date: b["event_start_date"],
          black_status_duration: b["black_status_duration"],
          num_of_collections: b["num_of_collections"],
          avg_volume_csv: "",
          avg_volume: b["avg_volume"],
          cluster_avg_csv: "",
          cluster_avg: b["cluster_avg"],
          number_of_bins_in_cluster: b["number_of_bins_in_cluster"],
          analysis_time_in_days: b["analysis_time_in_days"],
          site_id: b["site_id"],
          cluster_id: b["cluster_id"],
          bin_id: b["bin_id"],
          events: b["events"],
          chosenCluster: b["chosenCluster"],
          bin_collection_or_refill_rate: b["bin_collection_or_refill_rate"]
        };
      });
    }
    this.binCapacityAnalysisData = binsFiltered;
    this.dataTableLength = this.binCapacityAnalysisData.length;
    this.dataSource = new MatTableDataSource<any>(binsFiltered);
    this.dataSource.sort = this.sort;
    this.sortData({ direction: "desc", active: "bin_name" });
  }

  onPageChange = (dataTableIndexes: any) => {
    this.startIndexTableData = dataTableIndexes.startIndex;
    this.endIndexTableData = dataTableIndexes.endIndex;
  };

  sortData(sort: Sort) {
    const data = this.binCapacityAnalysisData.slice();
    if (!sort.active || sort.direction === "") {
      this.binCapacityAnalysisData = data;
      return;
    }
    this.binCapacityAnalysisData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getTableData = () => {
    const tableData = [];
    const tempSearch = new MatTableDataSource<any>(this.binCapacityAnalysisData);
    tempSearch.filter = this.tempFilter;
	this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData;index <= this.endIndexTableData;index++) {
      if (tempSearch.filteredData[index]) {
        tableData.push(tempSearch.filteredData[index]);
      }
    }
    this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);
    return this.dataSource;
  };

  applyFilter = (filterValue: any) => {
    filterValue = filterValue.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.tempFilter = filterValue;
    this.dataSource.filter = filterValue;
  }

  openClusterInfo(el) {
    this.clusterBins = [];
    let capacityAnalysisBins = this.binCapacityAnalysisData.filter((bin) => bin["site_id"] == el.site_id && bin["cluster_id"] == el.cluster_id);
    let binsInfoSameCluster = this.binsBackUp.filter((bin) =>
        bin["site_id"] == el.site_id && bin["cluster_id"] == el.cluster_id
    );

    _.each(binsInfoSameCluster, (bin) => {
      if (!capacityAnalysisBins.some((item) => item.bin_id == bin["bin_id"])) {
        this.clusterBins.push(bin);
      }
    });
    this.clusterBins = this.clusterBins.concat(capacityAnalysisBins);
    let maxEventsObj = {};
    let maxEvents = 0;
    let allBinsAreCapacityAnalysisBins = false;
    this.clusterBlackStatusDuration = 0;
    let clusteredBinsWithoutMaxEventsObj = [];
    _.each(this.clusterBins, (bin) => {
      let allDatesEvents = [];
      if (bin.events) {
        _.each(bin.events, (ev) => {
          for (let i = 1; i < ev.event_num_of_day; i++) {
            allDatesEvents.push(moment(ev.event_start_date).add(i, "days").format("YYYY-MM-DD"));
          }
          allDatesEvents.unshift(moment(ev.event_start_date).format("YYYY-MM-DD"));
        });
        bin["allDatesEvents"] = allDatesEvents;
      } else {
        bin["allDatesEvents"] = allDatesEvents;
      }
    });

    _.each(this.clusterBins, (bin) => {
      if (bin.allDatesEvents.length == 0) {
        allBinsAreCapacityAnalysisBins = false;
        maxEventsObj = {};
        maxEvents = 0;
        return false;
      } else {
        if (bin.allDatesEvents.length > maxEvents) {
          maxEvents = bin.allDatesEvents.length;
          maxEventsObj = bin;
          allBinsAreCapacityAnalysisBins = true;
        }
      }
    });

    _.each(this.clusterBins, (bin) => {
      if (bin.bin_id != maxEventsObj["bin_id"]) {
        clusteredBinsWithoutMaxEventsObj.push(bin);
      }
    });

    if (allBinsAreCapacityAnalysisBins && maxEventsObj["events"]) {
      _.each(maxEventsObj["allDatesEvents"], (event) => {
        if (clusteredBinsWithoutMaxEventsObj.every((bin) =>
            bin.allDatesEvents.some((ev) =>
                moment(moment(event).format("YYYY-MM-DD HH:mm:ss")).valueOf() ==
                moment(moment(ev).format("YYYY-MM-DD HH:mm:ss")).valueOf()
            )
          )
        ){
          this.clusterBlackStatusDuration++;
        }
      });
    } else {
      this.clusterBlackStatusDuration = 0;
    }
    this.openClusterInfoPopup();
  }

  openClusterInfoPopup() {
    // if (this.submitClicked) return;
    // this.submitClicked = true;
    this.dialog.open(ClusterInfoModalComponent, {
      panelClass: `${this.currentLang != "iw" ? "clusterInfo-container-ltr" : "clusterInfo-container-rtl"}`,
      data: {
        items: this.clusterBins,
        clusterBlackStatusDuration: this.clusterBlackStatusDuration,
      },
    });
  }

  // getSubmitClicked() {
  //   if (this.submitClicked) {
  //     return "cursor-no-drop";
  //   } else {
  //     return "cursor-pointer";
  //   }
  // }

  changeSubmitClickedStatus(el) {
    //this.submitClicked = false;
    this.openClusterInfo(el);
  }

  removeFilter(){
    this.apiStore.update({ selectedInsightsPieChart: {} });     	
    this.apiStore.update({ selectedInsightsSamplingChart: {} });     
														
    this.filterTableAccordigToStatus["text"] = '';
    this.filterTableAccordigToStatus["value"] = '';		
    this.binCapacityAnalysisArr = this.backUpBinCapacityAnalysisArr;	
    this.initTableData();	
  }

  exportToCsv() {
    let dataToExport = null;
    dataToExport = _.cloneDeep(this.binCapacityAnalysisData);
    _.each(dataToExport, (item) => {
      if (item.cluster_id_csv == 0) {
        item.cluster_id_csv = "";
      }
      if (this.usageType == 1) {
        item.avg_volume_csv = item.avg_volume != 0 ? Number(item.avg_volume).toFixed(0) + "%" : '';
      } else {
        item.avg_volume_csv = item.avg_volume != 0 ? Number(item.avg_volume).toFixed(0) + "L" : '';
      }
    });
    let header = {};
    if (!this.not_same_linked_sites_id) {
      header = {
        "0": this.translationsObj.INSIGHTS.BIN_NAME,
        "1": this.translationsObj.LIST_COLUMNS.CLUSTERID,
        "2": this.translationsObj.INSIGHTS.NUM_BLACK_STATUS_EV,
        "3" : this.translationsObj.INSIGHTS.EVENT_START,
        "4": this.translationsObj.INSIGHTS.BLACK_STATUS_DURATION,
        "5": this.translationsObj.INSIGHTS.NUM_OF_COLLECTIONS,
        "6": this.translationsObj.INSIGHTS.AVG_COLLECTIONS_VOL
      };
    } else {
      header = {
        "0": this.translationsObj.INSIGHTS.BIN_NAME,
        "1": this.translationsObj.LIST_COLUMNS.CLUSTERID,
        "2": this.translationsObj.TITLES.SITE_NAME,
        "3": this.translationsObj.INSIGHTS.NUM_BLACK_STATUS_EV,
        "4" : this.translationsObj.INSIGHTS.EVENT_START,
        "5": this.translationsObj.INSIGHTS.BLACK_STATUS_DURATION,
        "6": this.translationsObj.INSIGHTS.NUM_OF_COLLECTIONS,
        "7": this.translationsObj.INSIGHTS.AVG_COLLECTIONS_VOL
      };
    }

    const flatData = dataToExport.reduce((b, a) => {
      let res = _.slice(_.values(a), 0, Object.keys(header).length);
      b.push(res);
      return b;
    }, []);

    let source = {
      flatData,
      header,
    };

    let result = source.flatData.map((details) =>
      Object.keys(details).reduce(
        (a, b) => ((a[source.header[b]] = details[b]), a),
        {}
      )
    );
    if (this.currentLang != "iw") {
      TableUtil.exportArrayToExcel(result, "Black Status");
    } else {
      TableUtil.exportArrayToExcel(result, "ימים בנפח חריג");
    }
  }
}
