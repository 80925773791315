import { Component } from '@angular/core';
import { Chart } from 'chart.js';
import _ from 'lodash';
import moment from 'moment';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslatorService } from '../../../services/translator_service';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ApiStore } from '../../../web-services/api/api.store';

@Component({
  selector: 'app-black-status-sampling',
  standalone: true,
  imports: [MatCardModule, RTLDivDirectiveDirective,MatDividerModule,TranslateModule,CommonModule],
  templateUrl: './black-status-sampling.component.html',
  styleUrl: './black-status-sampling.component.scss'
})
export class BlackStatusSamplingComponent {
	isMobile: Boolean;
	chart: Chart = null;
	binCapacityData: any;
	selected_time = 'month';
	daysObj;
	paginationIndex = 1;
	minDate = '';
	maxDate = '';
	showDatePicker: Boolean = false;
	lastMonthDay: any = moment(this.minDate).format('DD.MM.YY');
	binsData;
	showBinsListDropDownList: Boolean = false;
	dataType: String = 'Capacity';
	currentLang;
	translationsObj;
	translate;
	spinnerActive:boolean = true;
	timeDateFormat = 'DD.MM.YY';

	constructor(private translator: TranslatorService,private apiQuery:ApiQuery,private responsiveService: ResponsiveService,
		private apiStore: ApiStore) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		  if(this.currentLang != 'iw'){
			this.timeDateFormat = 'MM.DD.YY';
		  }  
		})
	}

	ngOnInit() {	
		this.onResize();
		this.responsiveService.checkWidth();

		this.spinnerActive = true;
		this.apiQuery.binCapacityAnalysis$.subscribe((data:any) => {
			let binCapacityAnalysisArray = {};
			let over_flow_date_arr = [];
			this.spinnerActive = true;		
			if(data.length == 0) return;
			this.spinnerActive = false;	
			_.each(data, (binCapacityAnalysis) => {
				_.each(binCapacityAnalysis.binsWorkPlanCapacityAnalysis, (binsWorkPlanCapacityAnalysis) => {
					_.each(binsWorkPlanCapacityAnalysis.over_flow_dates, (over_flow_date) => {
						over_flow_date_arr.push(over_flow_date);
					});
				});
			});
			_.each(over_flow_date_arr, (over_flow_date) => {
				for(let i=0; i<over_flow_date.event_num_of_day; i++){
					let overFrlowDate = {
						event_start_date: moment(over_flow_date.event_start_date).add(i,'days').format('YYYY-MM-DD'),
						events_num:0,
						firstType: 0,
						secondType: 0,
						thirdType: 0,
						forthType: 0
					};
					if(binCapacityAnalysisArray[overFrlowDate.event_start_date]){
						binCapacityAnalysisArray[overFrlowDate.event_start_date].events_num ++;
						if(over_flow_date.event_num_of_day >= 1 && over_flow_date.event_num_of_day <= 3){
							binCapacityAnalysisArray[overFrlowDate.event_start_date].firstType ++;
						}else if(over_flow_date.event_num_of_day >= 4 && over_flow_date.event_num_of_day <= 7){
							binCapacityAnalysisArray[overFrlowDate.event_start_date].secondType ++;
						}else if(over_flow_date.event_num_of_day >= 8 && over_flow_date.event_num_of_day <= 14){
							binCapacityAnalysisArray[overFrlowDate.event_start_date].thirdType ++;
						}else if(over_flow_date.event_num_of_day >= 15){
							binCapacityAnalysisArray[overFrlowDate.event_start_date].forthType ++;
						}
					}else{
						overFrlowDate.events_num++;
						if(over_flow_date.event_num_of_day >= 1 && over_flow_date.event_num_of_day <= 3){
							overFrlowDate.firstType ++;
						}else if(over_flow_date.event_num_of_day >= 4 && over_flow_date.event_num_of_day <= 7){
							overFrlowDate.secondType ++;
						}else if(over_flow_date.event_num_of_day >= 8 && over_flow_date.event_num_of_day <= 14){
							overFrlowDate.thirdType ++;
						}else if(over_flow_date.event_num_of_day >= 15){
							overFrlowDate.forthType ++;
						}
						binCapacityAnalysisArray[overFrlowDate.event_start_date] = overFrlowDate;
					}
				}
			});

			this.minDate = sessionStorage.getItem("insightsMinDate");
			this.maxDate = sessionStorage.getItem("insightsMaxDate");
			this.changeCollectionReportRange(this.selected_time);	
			this.initCollectionReport();
			this.parseBinCapacityData(binCapacityAnalysisArray);
		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	getDateTimeToShow = () => {
		if(this.daysObj){
			if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
				if (this.currentLang !== 'iw') {
					return `${moment(this.daysObj.minMaxTime.minTime).format(this.timeDateFormat)} - ${moment(this.daysObj.minMaxTime.maxTime).format(this.timeDateFormat)}`;
				}
				else {
					return `${moment(this.daysObj.minMaxTime.maxTime).format(this.timeDateFormat)} - ${moment(this.daysObj.minMaxTime.minTime).format(this.timeDateFormat)}`;
				}
			}
		}
	}

	moveGraphPrev = () => {
		if (this.paginationIndex - 1 === 0) return;
		this.paginationIndex--;
		this.initChart();
	}

	moveGraphNext = () => {
		this.paginationIndex++;
		if (this.isSelectedRangeIsWeek() && !this.isWeeksPaginationValid()) {
			return;
		}
		if (this.isSelectedRangeIsMonth() && !this.isWeeksPaginationValid()) {
			return;
		}
		this.initChart();
	}

	isWeeksPaginationValid = () => {
		if (this.paginationIndex > this.daysObj.days.length) {
			this.paginationIndex--;
			return false;
		}
		return true;
	}

	isNextBtnDisable = () => {
		if(this.daysObj){
			if (this.isSelectedRangeIsMonth() || this.isSelectedRangeIsWeek()) {
				return (this.paginationIndex + 1) > this.daysObj.days.length;
			}
		}
	}

	parseBinCapacityData = (binCapacityData: any) => {
		this.binCapacityData = binCapacityData;		
		if (this.translationsObj) {
			this.buildChart();
		}
	}

	initCollectionReport = () => {
		this.paginationIndex = 1;
		this.resetDaysObject();
		this.daysObj = this.getDatesObjPerRange();
	}

	changeCollectionReportRange = (time_range: string) => {				
		this.selected_time = time_range;
		this.lastMonthDay = moment(this.minDate).format('DD.MM.YY');
		this.initCollectionReport();
		this.buildChart();
	}

	resetDaysObject = () => {
		return {
			days: [],
			daysHashSampling: {
				'Capacity': 0
			},
			minMaxTime: [],
			daysArr: [],
			firstType: [],
			secondType: [],
			thirdType: [],
			forthType: []
		};
	}

	isSelectedRangeIsWeek = () => {
		return this.selected_time == 'week';
	}

	isSelectedRangeIsMonth = () => {
		return this.selected_time == 'month';
	}

	getDiffBetweenDates = () => {
		if (this.isSelectedRangeIsWeek()) {
			return moment(this.maxDate).diff(this.minDate, 'weeks') + 1;
		}
	}

	getMonthLabel = (date: string) => {
		let startDate = moment(date).format('DD.MM.YY');
		let endDate: any = moment(date).endOf('month');
		if (endDate >= moment(this.maxDate)) {
			endDate = moment(this.maxDate).format('DD.MM.YY');
		} else {
			endDate = moment(date).endOf('month').format('DD.MM.YY');
		}
		return {
			fullFormat: `${startDate} - ${endDate}`
		};
	}

	getDatesRangeToRun = () => {
		let minDay = 1;
		let maxDay = this.getDiffBetweenDates();
		return {
			minDay: minDay,
			maxDay: maxDay,
			dateStart: moment(this.minDate),
			dateEnd: moment(this.maxDate),
		};
	}

	checkIfDayIsNotSunday = (date: string) => {
		return moment(date).weekday() !== 7;
	}

	checkIfEndDayInNextMonth = (date: string) => {
		let nextDays = moment(date, 'DD.MM.YY').add(6, 'days').month();
		return nextDays !== moment(date, 'DD.MM.YY').month();
	}

	buildDaysObjByWeeks = (daysRange: any, daysObj: any) => {
		let dayFormatStart = moment(daysRange.dateStart).format('DD.MM.YY');
		let dayFormatEnd = moment(daysRange.dateStart).add(6, 'days').format('DD.MM.YY');

		if (this.checkIfDayIsNotSunday(daysRange.dateStart)) {
			dayFormatEnd = moment(daysRange.dateStart).day(6).format('DD.MM.YY'); 
			daysRange.dateStart = moment(daysRange.dateStart).day(7); 
		} else {
			daysRange.dateStart = moment(daysRange.dateStart).add(7, 'days');
		}

		if (this.checkIfEndDayInNextMonth(dayFormatEnd)) {
			dayFormatEnd = moment(dayFormatEnd, 'DD.MM.YY').endOf('month').format('DD.MM.YY');
			daysRange.dateStart = moment(dayFormatEnd, 'DD.MM.YY').endOf('month').add(1, 'day');
		}
		if (moment(dayFormatEnd, 'DD.MM.YY') >= moment(this.maxDate)) {
			dayFormatEnd = moment(this.maxDate).format('DD.MM.YY');
		}
		let label = `${dayFormatStart} - ${dayFormatEnd}`;
		daysObj.days.push(label);
	}

	buildDaysObjByMonth = (daysRange: any, daysObj: any) => {
		let labelForObj = this.getMonthLabel(daysRange.dateStart);
		daysObj.days.push(labelForObj.fullFormat);
		daysRange.dateStart = moment(daysRange.dateStart).endOf('month').add(1, 'day');
	}

	getDatesObjPerRange() {
		let daysRange = this.getDatesRangeToRun();
		let daysObj = this.resetDaysObject();
		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			while (daysRange.dateStart <= daysRange.dateEnd) {
				if (this.isSelectedRangeIsWeek()) { this.buildDaysObjByWeeks(daysRange, daysObj); }
				if (this.isSelectedRangeIsMonth()) { this.buildDaysObjByMonth(daysRange, daysObj); }
			}
		}
		return daysObj;
	}

	buildChart = () => {
		this.initChart();
	}

	resetDayData = () => {
		this.daysObj.daysHashSampling['Capacity'] = [];
		this.daysObj.firstType = [];
		this.daysObj.secondType = [];
		this.daysObj.thirdType = [];
		this.daysObj.forthType = [];
	}

	getDayArrData = (binData: any) => {
		if (_.isEmpty(binData)) return;
		
		const sorted = Object.keys(binData)
		.sort()
		.reduce((accumulator, key) => {
		  accumulator[key] = binData[key];
	  
		  return accumulator;
		}, {});

		this.daysObj.daysHashSampling['Capacity'] = _.map(sorted, (data) => {
			return data["events_num"];					
		});	
		
		this.daysObj.firstType = _.map(sorted, (data) => {
			return data["firstType"];	
		});

		this.daysObj.secondType = _.map(sorted, (data) => {
			return data["secondType"];	
		});

		this.daysObj.thirdType = _.map(sorted, (data) => {
			return data["thirdType"];	
		});

		this.daysObj.forthType = _.map(sorted, (data) => {
			return data["forthType"];	
		});	
	}

	getWeekMinMaxDayTime = (minWeekDate, maxWeekDate, binData) => {
		let minTime = `${moment(minWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;
		let maxTime = `${moment(maxWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;

		this.daysObj.minMaxTime = {
			minTime: minTime,
			maxTime: maxTime
		};
		
		if (_.isEmpty(binData)) {
			this.daysObj.daysArr = [minTime, maxTime];
			return;
		}		

		const sorted = Object.keys(binData)
		.sort()
		.reduce((accumulator, key) => {
		  accumulator[key] = binData[key];
	  
		  return accumulator;
		}, {});
	  
		this.daysObj.daysArr = _.map(sorted, (data) => {
			return data["event_start_date"];	
		});
	}

	getWeekData = () => {
		this.resetDayData();
		let weekDateRange = this.daysObj.days[this.paginationIndex - 1];
		let minWeekDate;
		let maxWeekDate;
		if (weekDateRange) {
			minWeekDate = moment(weekDateRange.split(' ')[0], 'DD.MM.YY'); 
			maxWeekDate = moment(weekDateRange.split(' ')[2], 'DD.MM.YY').add(1, 'day');
		}

		let binData = _.each(this.binCapacityData, (bin) => {
			let binMeasurementTime = moment(bin.event_start_date);
			return binMeasurementTime >= minWeekDate && binMeasurementTime <= maxWeekDate;
		});

		this.getWeekMinMaxDayTime(minWeekDate, maxWeekDate, binData);
		this.getDayArrData(binData);
		return {
			daysLabel: this.daysObj.daysArr,
			typeData: this.daysObj.daysHashSampling[`${this.dataType}`],
			firstType: this.daysObj.firstType,
			secondType: this.daysObj.secondType,
			thirdType: this.daysObj.thirdType,
			forthType: this.daysObj.forthType
		};
	}

	getPaginationLabelsData = () => {
		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			return this.getWeekData();
		}
	}

	isMinTimeEqualsToMaxTime = () => {
		return this.daysObj.minMaxTime.minTime === this.daysObj.minMaxTime.maxTime;
	}

	buildTimeLineConfig = () => {
		if (this.isMinTimeEqualsToMaxTime()) {
			return {
				unit: 'hour',
				unitStepSize: 2,
				displayFormats: { 'hour': 'HH:mm' }
			};
		}
		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			return {
				unit: 'day',
				unitStepSize: 1,
				displayFormats: { 'day': this.timeDateFormat }
			};
		}
	}

	initChart = () => {		
		if (this.chart) {
			this.chart.destroy();
		}
		if (!this.translationsObj) {
			return;
		}
		let graphData = this.getPaginationLabelsData();		
		if(graphData.typeData.length == 0){
			return;
		}
		
		let barTimeConfig = this.buildTimeLineConfig();

		var canvas: any = document.getElementById("binData");
		if(canvas != undefined){
			var ctx = canvas.getContext('2d');
			this.chart = new Chart(ctx, {
				type: "bar",
				data: {
					labels: graphData.daysLabel,
					datasets: [{
						data: graphData.firstType,
						backgroundColor: '#ADE8F4',
						borderWidth: graphData.secondType.length > 0 ? { top: 3 } : { top: 0 },
						borderColor: '#FFFFFF',
						maxBarThickness: 50
					}, 
					{
						data: graphData.secondType,
						backgroundColor: '#48CAE4',
						borderWidth: graphData.thirdType.length > 0 ? { top: 3 } : { top: 0 },
						borderColor: '#FFFFFF',
						maxBarThickness: 50
					}, 
					{
						data: graphData.thirdType,
						backgroundColor: '#0077B6',
						borderWidth: graphData.forthType.length > 0 ? { top: 3 } : { top: 0 },
						borderColor: '#FFFFFF',
						maxBarThickness: 50
					},
					{
						data: graphData.forthType,
						backgroundColor: '#023E8A',
						borderColor: '#FFFFFF',
						maxBarThickness: 50
					}]
				},
				options: {
					onClick: (evt) => {
						var item = this.chart.getElementAtEvent(evt);
						const selectedPart = item[0];
						if(selectedPart._datasetIndex == 0){
							let event = {
								"event_date": graphData.daysLabel[selectedPart._index],
								"event_type": 0
							};
							this.apiStore.update({ selectedInsightsSamplingChart: event });
						}else if(selectedPart._datasetIndex == 1){
							let event = {
								"event_date": graphData.daysLabel[selectedPart._index],
								"event_type": 1
							};
							this.apiStore.update({ selectedInsightsSamplingChart: event });
						}else if(selectedPart._datasetIndex == 2){
							let event = {
								"event_date": graphData.daysLabel[selectedPart._index],
								"event_type": 2
							};
							this.apiStore.update({ selectedInsightsSamplingChart: event });
						}else if(selectedPart._datasetIndex == 3){
							let event = {
								"event_date": graphData.daysLabel[selectedPart._index],
								"event_type": 3
							};
							this.apiStore.update({ selectedInsightsSamplingChart: event });
						}
					},
					maintainAspectRatio: false,
					legend: {
						display: false
					},
					animation: false,
					tooltips: {
						enabled: false,
						custom: function (tooltip) {
							tooltip.intersect = false;
							tooltip.datasets = [{
								data: graphData.firstType
							}, 
							{
								data: graphData.secondType
							}, 
							{
								data: graphData.thirdType
							},
							{
								data: graphData.forthType
							}];
													
							let tooltipEl = document.getElementById('chartjs-tooltip');

							// Create element on first render
							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.id = 'chartjs-tooltip';
								tooltipEl.className = 'chartjs-tooltip';
								document.body.appendChild(tooltipEl);
							}
	
							// Hide if no tooltip
							if (tooltip.opacity === 0) {
								tooltipEl.style.opacity = "0";
								return;
							}
	
							// Set caret position
							tooltipEl.classList.remove('above', 'below', 'no-transform');
							if (tooltip.yAlign) {
								tooltipEl.classList.add(tooltip.yAlign);
							} else {
								tooltipEl.classList.add('no-transform');
							}
	
							// Set text in the tooltip
							if (tooltip.body) {
								let innerHtml = '';
								innerHtml += '<div style="flex-direction:column;display:flex;gap:6px">';

								if(tooltip.dataPoints){
									let first_type_day = tooltip.datasets[0].data[tooltip.dataPoints[0].index];
									let second_type_day = tooltip.datasets[1].data[tooltip.dataPoints[0].index];
									let third_type_day = tooltip.datasets[2].data[tooltip.dataPoints[0].index];
									let forth_type_day = tooltip.datasets[3].data[tooltip.dataPoints[0].index];
									let total = first_type_day + second_type_day + third_type_day + forth_type_day;
									let body = '';

									body += `									
									<div style="display: flex; gap: 6px;align-items:center;padding: 0px 6px;font-size: 14px;font-weight: 600;font-style: normal;font-family: Open Sans, sans-serif">
										<span>Total</span>
										<span>|</span>
										<span>${total}</span>
									</div>

									<div style="display: flex; gap: 8px;align-items:center;padding: 2px 6px;border-radius: 4px;${tooltip.dataPoints[0].datasetIndex == 3 ? 'background : #4F4F4F' : ''}">
										<svg height="8" width="8">
											<ellipse cx="4" cy="4" rx="4" ry="4" style="fill:#023E8A"/>
										</svg>
										<span>${forth_type_day}</span>
									</div>
									
									<div style="display: flex; gap: 8px;align-items:center;padding: 2px 6px;border-radius: 4px;${tooltip.dataPoints[0].datasetIndex == 2 ? 'background : #4F4F4F' : ''}">
										<svg height="8" width="8">
											<ellipse cx="4" cy="4" rx="4" ry="4" style="fill:#0077B6"/>
										</svg>
										<span>${third_type_day}</span>
									</div>
									
									<div style="display: flex; gap: 8px;align-items:center;padding: 2px 6px;border-radius: 4px;${tooltip.dataPoints[0].datasetIndex == 1 ? 'background : #4F4F4F' : ''}">
										<svg height="8" width="8">
											<ellipse cx="4" cy="4" rx="4" ry="4" style="fill:#48CAE4"/>
										</svg>
										<span>${second_type_day}</span>
									</div>
									
									<div style="display: flex; gap: 8px;align-items:center;padding: 2px 6px;border-radius: 4px;${tooltip.dataPoints[0].datasetIndex == 0 ? 'background : #4F4F4F' : ''}">
										<svg height="8" width="8">
											<ellipse cx="4" cy="4" rx="4" ry="4" style="fill:#ADE8F4"/>
										</svg>
										<span>${first_type_day}</span>
									</div>`;

									innerHtml += body;
								}
								innerHtml += '</div>';
	
								tooltipEl.innerHTML = innerHtml;
							}
	
							// Display, position, and set styles for the tooltip
							const position = this._chart.canvas.getBoundingClientRect();
	
							tooltipEl.style.opacity = "1";
							tooltipEl.style.position = 'absolute';
							tooltipEl.style.color = 'white';
							tooltipEl.style.left = position.left + tooltip.caretX + 'px';
							tooltipEl.style.top = position.top + tooltip.caretY + 'px';
							tooltipEl.style.fontFamily = 'Open Sans, sans-serif';
							tooltipEl.style.fontSize = '14px';
							tooltipEl.style.fontStyle = 'normal';
							tooltipEl.style.padding = '8px';
							tooltipEl.style.pointerEvents = 'none';							
							tooltipEl.style.backgroundColor = '#2A3338';
							tooltipEl.style.boxShadow = '2px 6px 28px 0px rgba(42, 51, 56, 0.30)';
							tooltipEl.style.borderRadius = '6px';
						}
					},
					scales: {
						yAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true
							}
						}],
						xAxes: [{
							stacked: true,
							gridLines: {
								display: false
							},
							display: true,
							type: 'time',
							time: {
								min: new Date(this.daysObj.minMaxTime.minTime),
								max: new Date(this.daysObj.minMaxTime.maxTime),
								unit: barTimeConfig.unit,
								unitStepSize: barTimeConfig.unitStepSize,
								displayFormats: barTimeConfig.displayFormats
							}
						}]
					},
          			annotation: {
						drawTime: 'afterDatasetsDraw'
					}
				}
			});
		}	
	} 
}
