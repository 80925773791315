@if (!isMobile) {
  <div class="black-status-duration">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{'INSIGHTS.AVG_EV_DUR' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div>
          <img src="assets/images/binInfoPage/duration.svg" alt=""/>
          <div class="duration-txt">{{duration | number: '1.0-0'}} {{'COLLECTION_REPORT.DAYS' | translate }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}