import { Component, ViewChild } from '@angular/core';
import { EditTicketModalComponent } from '../../ticket-management/edit-ticket-modal/edit-ticket-modal.component';
import _ from 'lodash';
import { TableUtil } from '../../../tableUtil';
import { ResolveTicketModalComponent } from '../../ticket-management/resolve-ticket-modal/resolve-ticket-modal.component';
import { ViewTicketModalComponent } from '../../ticket-management/view-ticket-modal/view-ticket-modal.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { AddTicketModalComponent } from '../../ticket-management/add-ticket-modal/add-ticket-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Papa } from 'ngx-papaparse';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { UtilService } from '../../../services/util.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translator_service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import { Subject, combineLatest } from 'rxjs';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { PaginationComponent } from '../../pagination/pagination.component';

@Component({
  selector: 'app-bin-tickets-list',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatCardModule,MatTabsModule,
	MatTableModule,MatSortModule,PaginationComponent,RouterModule],
  templateUrl: './bin-tickets-list.component.html',
  styleUrl: './bin-tickets-list.component.scss'
})
export class BinTicketsListComponent {
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	usageType = 1;
	currentLang;
	translationsObj;
  	isMobile: Boolean;
	minDate = '';
  	maxDate = '';
	lastMaxDate:any;
	lastMinDate:any;

	checkOrReplacementTicketsArr:Array<object> = [];
	checkOrReplacementTicketsTableLength = 0;
	checkOrReplacementDataSource = new MatTableDataSource<any>();
	tempCheckOrReplacementFilter:any = '';
	startIndexTableDataCheckOrReplacement = 0;
	endIndexTableDataCheckOrReplacement = 9;
	displayedCheckOrReplacementColumns = ['ticket_id','ticket_status','event_cause','priority','site_name','bin_name','bin_address','comment','reporter','created','arrival_date','closed','manage'];
	checkOrReplacementSpinnerActive:boolean = true;
	filteredCheckOrReplacementData:Array<object> = [];

	installation:Array<object> = [];
	installationTicketsTableLength = 0;
	installationDataSource = new MatTableDataSource<any>();
	tempInstallationFilter:any = '';
	startIndexTableDataInstallation = 0;
	endIndexTableDataInstallation = 9;
	displayedInstallationColumns = ['ticket_id','ticket_status','event_cause','priority','site_name','bin_name','bin_address','comment','reporter','created','arrival_date','closed','manage'];
	installationSpinnerActive:boolean = true;
	filteredInstallationData:Array<object> = [];

	cancellation:Array<object> = [];
	cancellationTicketsTableLength = 0;
	cancellationDataSource = new MatTableDataSource<any>();
	tempCancellationFilter:any = '';
	startIndexTableDataCancellation = 0;
	endIndexTableDataCancellation = 9;
	displayedCancellationColumns = ['ticket_id','ticket_status','event_cause','priority','site_name','bin_name','bin_address','comment','reporter','created','arrival_date','closed','manage'];
	cancellationSpinnerActive:boolean = true;
	filteredCancellationData:Array<object> = [];

	private readonly destroy$ = new Subject();
	routeParamsSub:any;
	user_role_id = 0;
	user_id = 0;
	openedTickets:Array<object> = [];
	closedTickets:Array<object> = [];
	selectedTab:number = 0;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private dialog: MatDialog,
	private apiService:ApiService,private apiStore:ApiStore,private apiQuery:ApiQuery,public utilService: UtilService,
	private papa: Papa,private route: ActivatedRoute) {}
  
  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();

	this.apiQuery.user$.subscribe(user => {
		if (!user) {
			return;
		}		
		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}	
		this.user_role_id = user["user_role_id"];
		this.user_id = user["user_id"];
	});   

	this.translator.currentLangEmitter$
	.subscribe(async value=>{						
	  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
	  this.currentLang = value;	
	  this.routeParamsSub = this.route
	  .queryParams
	  .subscribe(params => {
		  let binId = params['binId'];
		  combineLatest(
			  this.apiQuery.cRTicketsList$,
			  this.apiQuery.insTicketsList$,
			  this.apiQuery.rmTicketsList$,
			  this.apiQuery.filteredBasicBins$
			  )
			  .pipe(takeUntil(this.destroy$))
			  .subscribe(([cRTicketsList,insTicketsList,rmTicketsList,bins])=>{	
				  let checkOrReplacementTickets = [];	
				  let installationTickets = [];
				  let cancellationTickets = [];
  
				  this.openedTickets = [];
				  this.closedTickets = [];
  
				  this.checkOrReplacementSpinnerActive = true;
				  this.installationSpinnerActive = true;
				  this.cancellationSpinnerActive = true;
				  if(cRTicketsList.length == 0 && insTicketsList.length == 0 && rmTicketsList.length == 0){return;}
				  if(cRTicketsList.length > 0){this.checkOrReplacementSpinnerActive = false;}
				  if(insTicketsList.length > 0){this.installationSpinnerActive = false;}
				  if(rmTicketsList.length > 0){this.cancellationSpinnerActive = false;}
  
				  _.each(cRTicketsList, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  let relevantBinId = this.getRelevantBin(bins,ticket,"1");
								  if(binId == relevantBinId){
									  checkOrReplacementTickets.push(ticket);		
								  }
							  });		
						  }
						  if(item["TicketsList"]["close"]!=null){			
							  _.each(item["TicketsList"]["close"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 4;
								  let relevantBinId = this.getRelevantBin(bins,ticket,"4");
								  if(binId == relevantBinId){
									  checkOrReplacementTickets.push(ticket);		
								  }	
							  });		
						  }		
					  }		
				  });
  
				  _.each(insTicketsList, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  let relevantBinId = this.getRelevantBin(bins,ticket,"1");
								  if(binId == relevantBinId){
									  installationTickets.push(ticket);	
								  }
							  });		
						  }
						  if(item["TicketsList"]["close"]!=null){
							  _.each(item["TicketsList"]["close"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 4;
								  let relevantBinId = this.getRelevantBin(bins,ticket,"4");
								  if(binId == relevantBinId){
									  installationTickets.push(ticket);	
								  }
							  });	
						  }									
					  }		
				  });
  
				  _.each(rmTicketsList, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  let relevantBinId = this.getRelevantBin(bins,ticket,"1");
								  if(binId == relevantBinId){
									  cancellationTickets.push(ticket);	
								  }
							  });		
						  }
						  if(item["TicketsList"]["close"]!=null){
							  _.each(item["TicketsList"]["close"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 4;
								  let relevantBinId = this.getRelevantBin(bins,ticket,"4");
								  if(binId == relevantBinId){
									  cancellationTickets.push(ticket);	
								  }	
							  });	
						  }									
					  }		
				  });
  
				  const checkOrReplacementTicketsFiltered = _.map(checkOrReplacementTickets, ticket => {
					  return {
						  ticket_id : ticket["ticket_id"],
						  status_txt : ticket["status"] == 1 ? this.translationsObj.TICKET_MANAGEMENT.OPENED : this.translationsObj.TICKET_MANAGEMENT.CLOSED_STATUS,
						  eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
						  priorityEn : ticket["ticketData"].priority_name,
						  site_name : ticket["site_name"],
						  bin_name : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_name) : ''),
						  bin_address : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_address) : ''),
						  comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment : '',
						  reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  created : ticket["ticket_id_info"][0].action_time,
						  expected_arrival_date : this.utilService.convertDateEnglishWithoutTime(ticket["ticketData"].expected_arrival_date == '0000-00-00 00:00:00' ? (ticket["ticketData"].priority_id == 3 ? moment().add(1,'weeks').format('YYYY-MM-DD HH:mm:ss') : moment().add(2,'weeks').format('YYYY-MM-DD HH:mm:ss')) : moment(ticket["ticketData"].expected_arrival_date).format('YYYY-MM-DD HH:mm:ss')),
						  closed : ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : ''),
						  resolvedDescription : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment : '',
						  eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
						  priorityHe : ticket["ticketData"].priority_name_hebrew,
						  site_id : ticket["site_id"],
						  ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
						  priority_id : ticket["ticketData"].priority_id,
						  ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
						  ticketInfo : ticket["ticket_id_info"],
						  pic_url : ticket["ticketData"].pic_url,
						  first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
						  last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  user_id : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : ''),
						  bin_id : ticket["bin_id"],
						  eventTypeEn : ticket["ticketData"].ticket_event_type_name,
						  eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
						  status : ticket["status"],
						  expected_arrival_date_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticketData"].expected_arrival_date == '0000-00-00 00:00:00' ? (ticket["ticketData"].priority_id == 3 ? moment().add(1,'weeks').format('YYYY-MM-DD HH:mm:ss') : moment().add(2,'weeks').format('YYYY-MM-DD HH:mm:ss')) : moment(ticket["ticketData"].expected_arrival_date).format('YYYY-MM-DD HH:mm:ss')),
						  created_convertDateEnglish : ticket["ticket_id_info"].length > 0 ? this.utilService.convertDateEnglish(ticket["ticket_id_info"][0].action_time) : '',
						  created_convertDateHebrew : ticket["ticket_id_info"].length > 0 ? this.utilService.convertDateHebrew(ticket["ticket_id_info"][0].action_time) : ''
					  };
				  });	
		  
				  const installationTicketsFiltered = _.map(installationTickets, ticket => {
					  return {
						  ticket_id : ticket["ticket_id"],
						  status_txt : ticket["status"] == 1 ? this.translationsObj.TICKET_MANAGEMENT.OPENED : this.translationsObj.TICKET_MANAGEMENT.CLOSED_STATUS,
						  eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
						  priorityEn : ticket["ticketData"].priority_name,
						  site_name : ticket["site_name"],
						  bin_name : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_name) : ''),
						  bin_address : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_address) : ''),
						  comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment : '',
						  reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  created : ticket["ticket_id_info"][0].action_time,
						  expected_arrival_date : this.utilService.convertDateEnglishWithoutTime(ticket["ticketData"].expected_arrival_date),
						  closed : ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : ''),
						  resolvedDescription : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment : '',
						  eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
						  priorityHe : ticket["ticketData"].priority_name_hebrew,
						  site_id : ticket["site_id"],
						  ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
						  priority_id : ticket["ticketData"].priority_id,
						  ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
						  ticketInfo : ticket["ticket_id_info"],
						  pic_url : ticket["ticketData"].pic_url,
						  first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
						  last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  user_id : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : ''),
						  bin_id : ticket["bin_id"],
						  eventTypeEn : ticket["ticketData"].ticket_event_type_name,
						  eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
						  status : ticket["status"],
						  expected_arrival_date_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticketData"].expected_arrival_date),
						  created_convertDateEnglish : this.utilService.convertDateEnglish(ticket["ticket_id_info"][0].action_time),
						  created_convertDateHebrew : this.utilService.convertDateHebrew(ticket["ticket_id_info"][0].action_time)
					  };
				  });	
				  
				  const cancellationTicketsFiltered = _.map(cancellationTickets, ticket => {
					  return {
						  ticket_id : ticket["ticket_id"],
						  status_txt : ticket["status"] == 1 ? this.translationsObj.TICKET_MANAGEMENT.OPENED : this.translationsObj.TICKET_MANAGEMENT.CLOSED_STATUS,
						  eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
						  priorityEn : ticket["ticketData"].priority_name,
						  site_name : ticket["site_name"],
						  bin_name : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_name) : ''),
						  bin_address : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_address) : ''),
						  comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment : '',
						  reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  created : ticket["ticket_id_info"][0].action_time,
						  expected_arrival_date : this.utilService.convertDateEnglishWithoutTime(ticket["ticketData"].expected_arrival_date),
						  closed : ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : ''),
						  resolvedDescription : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment : '',
						  eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
						  priorityHe : ticket["ticketData"].priority_name_hebrew,
						  site_id : ticket["site_id"],
						  ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
						  priority_id : ticket["ticketData"].priority_id,
						  ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
						  ticketInfo : ticket["ticket_id_info"],
						  pic_url : ticket["ticketData"].pic_url,
						  first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
						  last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  user_id : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : ''),
						  bin_id : ticket["bin_id"],
						  eventTypeEn : ticket["ticketData"].ticket_event_type_name,
						  eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
						  status : ticket["status"],
						  expected_arrival_date_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticketData"].expected_arrival_date),
						  created_convertDateEnglish : this.utilService.convertDateEnglish(ticket["ticket_id_info"][0].action_time),
						  created_convertDateHebrew : this.utilService.convertDateHebrew(ticket["ticket_id_info"][0].action_time)
					  };
				  });	
		  
				  this.checkOrReplacementTicketsArr = checkOrReplacementTicketsFiltered;			
				  this.checkOrReplacementTicketsTableLength = this.checkOrReplacementTicketsArr.length;
				  this.checkOrReplacementDataSource = new MatTableDataSource<any>(checkOrReplacementTicketsFiltered);
				  this.checkOrReplacementDataSource.sort = this.sort;
		  
				  this.installation = installationTicketsFiltered;			
				  this.installationTicketsTableLength = this.installation.length;
				  this.installationDataSource = new MatTableDataSource<any>(installationTicketsFiltered);
				  this.installationDataSource.sort = this.sort;
  
				  this.cancellation = cancellationTicketsFiltered;			
				  this.cancellationTicketsTableLength = this.cancellation.length;
				  this.cancellationDataSource = new MatTableDataSource<any>(cancellationTicketsFiltered);
				  this.cancellationDataSource.sort = this.sort;
  
				  this.sortCheckOrReplacementTicketsData({ direction: 'desc', active: 'created' });
				  this.sortInstallationTicketsData({ direction: 'desc', active: 'created' });
				  this.sortCancellationTicketsData({ direction: 'desc', active: 'created' });
  
				  this.openedTickets = this.openedTickets.concat(_.groupBy(this.checkOrReplacementTicketsArr, "status")["1"],_.groupBy(this.installation, "status")["1"],_.groupBy(this.cancellation, "status")["1"]);
				  this.closedTickets = this.closedTickets.concat(_.groupBy(this.checkOrReplacementTicketsArr, "status")["4"],_.groupBy(this.installation, "status")["4"],_.groupBy(this.cancellation, "status")["4"]);
  
				  if(!_.isEmpty(this.apiStore.getValue()["selectedTicket"]) && cRTicketsList.length != 0 && insTicketsList.length != 0 && rmTicketsList.length != 0 && this.openedTickets.length > 0){			
					  const relevantTicket = _.filter(this.openedTickets, (res) => {      
						  return res["ticket_id"] == this.apiStore.getValue()["selectedTicket"]["ticket_id"];
					  });  		
					  this.dialog.closeAll();
					  if(relevantTicket.length > 0){
						  if(relevantTicket[0]["ticket_event_type_id"] == 1){
							  this.resolveAction(relevantTicket[0],1,0);
						  }else if(relevantTicket[0]["ticket_event_type_id"] == 3){
							  this.resolveAction(relevantTicket[0],1,1);
						  }else if(relevantTicket[0]["ticket_event_type_id"] == 4){
							  this.resolveAction(relevantTicket[0],1,2);
						  }
					  }
				  }	
			  })
	  });  
	})
  }

  onResize() {
	this.responsiveService.getMobileStatus().subscribe(isMobile => {
		this.isMobile = isMobile;
	});
  }

  getRelevantBin(bins,ticket,status){
	if(_.groupBy(ticket["ticket_id_info"], "status_id")[status]){
		if(ticket["ticketData"].ticket_event_type_id == 3){
			if(_.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name != ''){
				const relevant_bin = bins.filter(bin => bin["site_id"] == ticket.site_id && bin["bin_name"] == _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name);
				if(relevant_bin.length > 0){
					return relevant_bin[0]["bin_id"];
				}else{
					return 0;
				}
			}else{
				return 0;
			}
		}else{
			return _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_id;
		}
	}
  }

  deleteOwnEvent(event){
	if(event.user_id == this.user_id && this.user_role_id == 4){
		return true;
	}else{
		return false;
	}
  }

  tabChanged(tabChangeEvent){
	if(tabChangeEvent.index == 0){
		this.selectedTab = 0;
	}else if(tabChangeEvent.index == 1){
		this.selectedTab = 2;
	}
  }

  openAddTicket(){
	this.dialog.open(AddTicketModalComponent, { panelClass: 'bulkBins-container' , data: {selectedTab:this.selectedTab} });
  }

  ngOnDestroy() {
	this.destroy$.next(true);
	this.destroy$.complete();
  }

  sortCheckOrReplacementTicketsData(sort: Sort) {
	const data = this.checkOrReplacementTicketsArr.slice();
	if (!sort.active || sort.direction === '') {
		this.checkOrReplacementTicketsArr = data;
		return;
	}
	this.checkOrReplacementTicketsArr = data.sort((a, b) => {
		const isAsc = sort.direction === 'asc';
		return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	});
  }

  sortInstallationTicketsData(sort: Sort) {
	const data = this.installation.slice();
	if (!sort.active || sort.direction === '') {
		this.installation = data;
		return;
	}
	this.installation = data.sort((a, b) => {
		const isAsc = sort.direction === 'asc';
		return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	});
  }

  sortCancellationTicketsData(sort: Sort) {
	const data = this.cancellation.slice();
	if (!sort.active || sort.direction === '') {
		this.cancellation = data;
		return;
	}
	this.cancellation = data.sort((a, b) => {
		const isAsc = sort.direction === 'asc';
		return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	});
  }

  compare(a, b, isAsc) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  applyCheckOrReplacementFilter = (filterValue) => {
	filterValue = filterValue.value;
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.tempCheckOrReplacementFilter = filterValue;
	this.checkOrReplacementDataSource.filter = filterValue;
  }

  applyInstallationFilter = (filterValue: string) => {
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.tempInstallationFilter = filterValue;
	this.installationDataSource.filter = filterValue;
  }

  applyCancellationFilter = (filterValue) => {
	filterValue = filterValue.value;
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.tempCancellationFilter = filterValue;
	this.cancellationDataSource.filter = filterValue;
  }

  getCheckOrReplacementData = () => {
	const tableData = [];
	const tempSearch = new MatTableDataSource<any>(this.checkOrReplacementTicketsArr);
	tempSearch.filter = this.tempCheckOrReplacementFilter;
	this.filteredCheckOrReplacementData = tempSearch.filteredData;
	for (let index = this.startIndexTableDataCheckOrReplacement; index <= this.endIndexTableDataCheckOrReplacement; index++) {
		if (tempSearch.filteredData[index]) {
			tableData.push(tempSearch.filteredData[index]);
		}
	}
	this.checkOrReplacementDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
	return this.checkOrReplacementDataSource;
  }

  getInstallationData = () => {
	const tableData = [];
	const tempSearch = new MatTableDataSource<any>(this.installation);
	tempSearch.filter = this.tempInstallationFilter;
	this.filteredInstallationData = tempSearch.filteredData;
	for (let index = this.startIndexTableDataInstallation; index <= this.endIndexTableDataInstallation; index++) {
		if (tempSearch.filteredData[index]) {
			tableData.push(tempSearch.filteredData[index]);
		}
	}
	this.installationDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
	return this.installationDataSource;
  }

  getCancellationData = () => {
	const tableData = [];
	const tempSearch = new MatTableDataSource<any>(this.cancellation);
	tempSearch.filter = this.tempCancellationFilter;
	this.filteredCancellationData = tempSearch.filteredData;
	for (let index = this.startIndexTableDataCancellation; index <= this.endIndexTableDataCancellation; index++) {
		if (tempSearch.filteredData[index]) {
			tableData.push(tempSearch.filteredData[index]);
		}
	}
	this.cancellationDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
	return this.cancellationDataSource;
  }

  onCheckOrReplacementPageChange = (dataTableIndexes: any) => {
	this.startIndexTableDataCheckOrReplacement = dataTableIndexes.startIndex;
	this.endIndexTableDataCheckOrReplacement = dataTableIndexes.endIndex;
	this.getCheckOrReplacementData();
  }

  onInstallationPageChange = (dataTableIndexes: any) => {
	this.startIndexTableDataInstallation = dataTableIndexes.startIndex;
	this.endIndexTableDataInstallation = dataTableIndexes.endIndex;
	this.getInstallationData();
  }

  onCancellationPageChange = (dataTableIndexes: any) => {
	this.startIndexTableDataCancellation = dataTableIndexes.startIndex;
	this.endIndexTableDataCancellation = dataTableIndexes.endIndex;
	this.getCancellationData();
  }

  viewAction(item,status,tab){
	this.dialog.open(ViewTicketModalComponent, { panelClass: 'bulkBins-container' , data: {chosenTicket : item,status: status,selectedTab:tab} });
  }

  deleteAction(item,status){	
	const request = {   
		"event_cause_id": item.ticket_event_cause_id,
		"event_type_id":item.ticket_event_type_id,
		"priority_id":item.priority_id,
		"ticket_id":item.ticket_id,
		"comment":_.groupBy(item["ticketInfo"], "status_id")[status] ? _.groupBy(item["ticketInfo"], "status_id")[status][0]["comment"] : '',
		"status_id":5,
		"site_id":item.site_id,
		"expected_arrival_date":item.expected_arrival_date
	  }
	  
	  if(item.ticket_event_type_id == "1"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["analysis_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].analysis_id;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
		request["is_replaced"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_replaced;
	  }else if(item.ticket_event_type_id == "3"){
		request["is_installed"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_installed;
		request["bin_install_address"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_address;
		request["lat"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lat;
		request["lon"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lon;
		request["installation_type_id"] = 0;
		request["installed_bin_neighborhood"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_neighborhood;
	  }else if(item.ticket_event_type_id == "4"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["is_removed_from_bin"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_bin;
		request["is_removed_from_site"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_site;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
	  }
	  
	  this.apiService.insertNewTicket(request).subscribe((v:any) => {     				
		let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
	  });
  }

  resolveAction(item,status,tab){
	this.dialog.open(ResolveTicketModalComponent, { panelClass: 'bulkBins-container' , data: {chosenTicket : item,status: status,selectedTab:tab} });
  }

  exportToCsv(status){
	let dataToExport = null;
	if(status == 0){
		dataToExport = _.cloneDeep(this.checkOrReplacementTicketsArr);
	}else if(status == 1){
		dataToExport = _.cloneDeep(this.installation);
	}else if(status == 2){
		dataToExport = _.cloneDeep(this.cancellation);
	}
	_.each(dataToExport, (item) => {
		if(this.currentLang == 'iw'){
			item.eventCauseEn = item.eventCauseHe;
			item.priorityEn = item.priorityHe;
		}
	});

	let header = {};
	if(status == 0){
		header = {
			'0' : this.translationsObj.TICKET_MANAGEMENT.EVENT_ID,
			'1' : this.translationsObj.TICKET_MANAGEMENT.STATUS,
			'2' : this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE, 
			'3' : this.translationsObj.TICKET_MANAGEMENT.PRIORITY,
			'4' : this.translationsObj.TICKET_MANAGEMENT.SITE,
			'5' : this.translationsObj.TICKET_MANAGEMENT.BIN_NAME, 
			'6' : this.translationsObj.TICKET_MANAGEMENT.BIN_ADDRESS, 
			'7' : this.translationsObj.TICKET_MANAGEMENT.EVENT_DESCRIPTION,
			'8' : this.translationsObj.TICKET_MANAGEMENT.REPORTER,
			'9' : this.translationsObj.TICKET_MANAGEMENT.CREATED,
			'10' : this.translationsObj.TICKET_MANAGEMENT.ARRIVAL_DATE,
			'11' : this.translationsObj.TICKET_MANAGEMENT.CLOSED,
			'12' : this.translationsObj.TICKET_MANAGEMENT.INSTALLER_DESC
		}
	}else if(status == 1){
		header = {
			'0' : this.translationsObj.TICKET_MANAGEMENT.EVENT_ID,
			'1' : this.translationsObj.TICKET_MANAGEMENT.STATUS,
			'2' : this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE, 
			'3' : this.translationsObj.TICKET_MANAGEMENT.PRIORITY,
			'4' : this.translationsObj.TICKET_MANAGEMENT.SITE,
			'5' : this.translationsObj.TICKET_MANAGEMENT.BIN_NAME, 
			'6' : this.translationsObj.TICKET_MANAGEMENT.BIN_ADDRESS, 
			'7' : this.translationsObj.TICKET_MANAGEMENT.EVENT_DESCRIPTION,
			'8' : this.translationsObj.TICKET_MANAGEMENT.REPORTER,
			'9' : this.translationsObj.TICKET_MANAGEMENT.CREATED,
			'10' : this.translationsObj.TICKET_MANAGEMENT.ARRIVAL_DATE,
			'11' : this.translationsObj.TICKET_MANAGEMENT.CLOSED,
			'12' : this.translationsObj.TICKET_MANAGEMENT.INSTALLER_DESC
		}
	}else if(status == 2){
		header = {
			'0' : this.translationsObj.TICKET_MANAGEMENT.EVENT_ID,
			'1' : this.translationsObj.TICKET_MANAGEMENT.STATUS,
			'2' : this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE, 
			'3' : this.translationsObj.TICKET_MANAGEMENT.PRIORITY,
			'4' : this.translationsObj.TICKET_MANAGEMENT.SITE,
			'5' : this.translationsObj.TICKET_MANAGEMENT.BIN_NAME, 
			'6' : this.translationsObj.TICKET_MANAGEMENT.BIN_ADDRESS, 
			'7' : this.translationsObj.TICKET_MANAGEMENT.EVENT_DESCRIPTION,
			'8' : this.translationsObj.TICKET_MANAGEMENT.REPORTER,
			'9' : this.translationsObj.TICKET_MANAGEMENT.CREATED,
			'10' : this.translationsObj.TICKET_MANAGEMENT.ARRIVAL_DATE,
			'11' : this.translationsObj.TICKET_MANAGEMENT.CLOSED,
			'12' : this.translationsObj.TICKET_MANAGEMENT.INSTALLER_DESC
		}
	}

	const flatData = dataToExport.reduce((b, a) => {	
		let res = _.slice(_.values(a), 0, Object.keys(header).length)									
		b.push(res);
		return b;
	}, [])
	
	let source = {
		flatData,
		header
	};	

	let result = source.flatData.map((details) => 
	Object.keys(details).reduce((a, b) => 
	(a[source.header[b]] = details[b], a), {}));			
	if(status == 0){
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "check or replacement");
		}else{
			TableUtil.exportArrayToExcel(result, "בדיקה או החלפה");
		}
	}else if(status == 1){
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "installation");
		}else{
			TableUtil.exportArrayToExcel(result, "התקנה");
		}
	}else if(status == 2){
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "cancellation");
		}else{
			TableUtil.exportArrayToExcel(result, "הסרה");
		}
	}
  }

  closeAction(item,status){	
	const request = {   
		"event_cause_id": item.ticket_event_cause_id,
		"event_type_id":item.ticket_event_type_id,
		"priority_id":item.priority_id,
		"ticket_id":item.ticket_id,
		"comment":_.groupBy(item["ticketInfo"], "status_id")[status] ? _.groupBy(item["ticketInfo"], "status_id")[status][0]["comment"] : '',
		"status_id":4,
		"site_id":item.site_id,
		"expected_arrival_date":item.expected_arrival_date
	  }
	  
	  if(item.ticket_event_type_id == "1"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["analysis_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].analysis_id;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
		request["is_replaced"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_replaced;
	  }else if(item.ticket_event_type_id == "3"){
		request["is_installed"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_installed;
		request["bin_install_address"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_address;
		request["lat"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lat;
		request["lon"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lon;
		request["installation_type_id"] = 0;
		request["installed_bin_neighborhood"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_neighborhood;
	  }else if(item.ticket_event_type_id == "4"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["is_removed_from_bin"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_bin;
		request["is_removed_from_site"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_site;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
	  }
	  this.apiService.insertNewTicket(request).subscribe((v:any) => { 								
		let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
	  });
  }

  editAction(item,status){
	this.dialog.open(EditTicketModalComponent, { panelClass: 'bulkBins-container' , data: {chosenTicket : item,status: status,selectedTab:this.selectedTab} });
  }
}
