import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, ViewChildren, ViewRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { API } from '../../../types.api';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ApiService } from '../../../web-services/api/api.service';
import { UtilService } from '../../../services/util.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { GenericConfirmDialogComponent } from '../../generic-confirm-dialog/generic-confirm-dialog.component';
import { TableUtil } from '../../../tableUtil';
import { ManageBinModalComponent } from '../../inventory/manage-bin-modal/manage-bin-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule, ProgressBarMode } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { PaginationComponent } from '../../pagination/pagination.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GoogleMap, GoogleMapsModule,MapInfoWindow,MapMarker,MapMarkerClusterer } from '@angular/google-maps';

@Component({
  host: {
		'(document:click)': 'handleClick($event)',
	},
  selector: 'app-pre-mapping',
  standalone: true,
  imports: [TranslateModule,FormsModule,ReactiveFormsModule,CommonModule,MatFormFieldModule,MatDividerModule,
    RTLDivDirectiveDirective,MatCheckboxModule,MatProgressBarModule,MatCardModule,MatSortModule,
    MatTableModule,PaginationComponent,MatTooltipModule,MatInputModule,RouterModule,MatPaginatorModule,
    MapMarker,GoogleMapsModule,MapMarkerClusterer,GoogleMap,MapInfoWindow
  ],
  templateUrl: './pre-mapping.component.html',
  styleUrl: './pre-mapping.component.scss'
})
export class PreMappingComponent {
	@ViewChildren(MapInfoWindow) infoWindows!: QueryList<MapInfoWindow>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  // @ts-ignore
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild('binAddressInput', {static: false}) binAddressInput: ElementRef
  mode: ProgressBarMode = 'determinate';
  options: FormGroup;
  files: any[] = [];
  translateSub;
  translationsObj;
  currentLang;
  binStickerId: string;
  binAddress: string;
  binComments: string;
  binWazeLink: string;
  linkToMappingTool: string;
  linkToInstallationWizard:string;
  spinnerActive: boolean = true;
  spinnerExist: boolean = false;
  insertBinSpinnerExist: boolean = false;
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
  addressesIssuesDisplayedColumns: string[] = ['binAddress', 'binComments', 'button'];
  displayedColumns: string[] = ['removeBinTableHeader', 'binStickerId', 'binAddress', 'binNeighborhood', 'binComments', 'binWazeLink','linkToMappingTool','linkToInstallationWizard', 'binIsNotHere'];
  mobileDisplayedColumns: string[] = ['binStickerId', 'binAddress', 'binNeighborhood', 'binComments', 'binWazeLink','linkToMappingTool','linkToInstallationWizard', 'binIsNotHere'];
  dataSource = new MatTableDataSource<any>();
  addressesIssuesSource = new MatTableDataSource<any>();
  tableData = [];
  dataToDisplayPerPage = [];
  routeParamsSub;
  siteId: any;
  progressBarColor: ThemePalette = 'primary';
  progressBarValue = 10;
  arrayBuffer:any;
  file:File;
  mapSpinnerActive: boolean = true;
  xlsxContentAsArray: API.IPreInstallationXlsxObj[] = [];
  timeToDelay: number = 0;
  numOfGoogleApiResults: number = 0;
  map: any;
  mapLat = 31.253072;
  mapLng = 34.796209;
  markersArray: any[] = [];
  siteObject: any;
  isSiteInfoReady: boolean = false;
  isPreMappingInfo: boolean = false;
  isPreMappingInfoResponseReceived: boolean = false;
  isEnabledEdit: boolean = true;
  isShowMap: boolean = false;
  isShowProgressBar: boolean = false;
  isShowDropZone: boolean = true;
  isShowDropZoneToAdd: boolean = false;
  isShowUploadAndOverwriteButton: boolean = false;
  isShowTable: boolean = false;
  isAllAddressesValid: boolean = true;
  addressesIssuesArray: any = [];
  currentUserSelectedAddress: any;
  directionsConfigArray: any = [];
  directionsResponseArray: any = [];
  sortedArray: any = [];
  finalArray: any = [];
  isMobile: boolean;
  currentBinToRemove: any;
  isCurrentlyInserting: boolean = false;
  totalBinsToMap: number  = 0;
  mappedCounter: number  = 0;
  mappedTodayCounter: number  = 0;
  totalBinsToAdd = 0;
  filteredValue: string = "";
  binStartSticker: string = "";
  startPoint : object = {
    bin_lng : "",
    bin_lat : ""
  }
  bins: any = [];
  backUpBins : API.Bin[];
	clustersArrDic = {};
	markersArrayDic = {};
	binsHash: {};
	moreThanMax:boolean = false;
  capacityDisplayType: number;
  private readonly destroy$ = new Subject();
  clickedBin: any;
  items = [];
  types = [];
  tempFilter = '';
	filteredData:Array<object> = [];
	startIndexTableData = 0;
	endIndexTableData = 9;
  displayedFields = {
		isAll : false,
    bin_Name : true,
    bin_address : true,
    bin_description : true,
    bin_neighborhood : true,
    bin_Type : true
	};
  usageType = 1;
  mobileMapOptions: google.maps.MapOptions = {
		streetViewControl: true,
		mapTypeControl : true,
    disableDefaultUI:false,
		fullscreenControl : true,
		clickableIcons : false
	};
	mapOptionsPc: google.maps.MapOptions = {
		streetViewControl: true,
		mapTypeControl : true,
		disableDefaultUI:false,
		fullscreenControl : true,
		clickableIcons : false
	};
	mapOptions = {
		styles: [{
			height: 53,
			url: "../../assets/images/bins/all/cluster_icon.png",
			width: 52
		},
		{
			height: 53,
			url: "",
			width: 52
		}],
		calculator: (markers) => {
		  let count = 0;
		  let count1 = 0;
		  let count2 = 0;
		  let count3 = 0;
		  let count4 = 0;

		  for (let i = 0; i < markers.length; i++) {
			if(markers[i].visible){
				if(markers[i].icon.toString().includes("Cluster1")){
					count1++;				  
				}else if(markers[i].icon.toString().includes("Cluster2")){
					count2++;
				}else if(markers[i].icon.toString().includes("Cluster3")){
					count3++;
				}else if(markers[i].icon.toString().includes("Cluster4")){
					count4+= Number(markers[i].title);
				}else{
					count++;
				}
			}
		  }
		  if(count1*2 + count2*3 + count3*4 + count4 + count > 0){
			return {
				text: (count1*2 + count2*3 + count3*4 + count4 + count).toString(),
				index:1,
        title: (count1*2 + count2*3 + count3*4 + count4 + count).toString()
			  };
		  }else{
			return {
				text: "",
				index:2,
        title: ""
			  }; 
		  }
		}
	};

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private apiService:ApiService, 
    private apiQuery: ApiQuery, private translator: TranslatorService, 
    private responsiveService: ResponsiveService,private apiStore:ApiStore,private utilService: UtilService,
    private cd: ChangeDetectorRef,private fb: FormBuilder,private router: Router) {
    this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
        this.siteId = this.route.snapshot.paramMap.get("id");
      });

      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;	
      })

      this.options = fb.group({
        floatLabel: 'never'
      });
  }

  ngOnInit() {
    this.initParams();
    this.onResize();
    this.responsiveService.checkWidth();
    this.apiService.getPendingPreMappingInfoBySiteId(this.siteId);
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
      if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
      this.capacityDisplayType = user["user_default_capacity_type_id"];
		});
    this.apiQuery.siteInfo$.subscribe((siteInfo) => {
      if (Object.keys(siteInfo).length === 0) {
        this.apiService.getSiteInfo(this.siteId);
      }
      else {
        this.siteObject = siteInfo;
        this.isSiteInfoReady = true;
        if (this.map) {
          this.map.setCenter(new google.maps.LatLng(this.siteObject.start_nav_point_lat, this.siteObject.start_nav_point_lng));
        }
      }
    });
    this.apiQuery.filteredBins$.subscribe(bins => {
      this.mapClick();
      this.mapSpinnerActive = true;
      if(!bins || bins.length == 0){return;}
      this.mapSpinnerActive = false;
      const binData = _.filter(bins, (bin) => {
        return bin["site_id"] == this.siteId;
      });
      this.mappedCounter = binData.length;

      const binInstalledTodayData = _.filter(bins, (bin) => {
        return bin["site_id"] == this.siteId && moment().format("YYYY-MM-DD 00:00:00").valueOf() == moment(bin["bin_installation_date"]).format("YYYY-MM-DD 00:00:00").valueOf();
      });
      this.mappedTodayCounter = binInstalledTodayData.length;
    });
  }

  initParams() {
    this.spinnerActive = true;
    this.spinnerExist = false;
    this.insertBinSpinnerExist= false;
    this.isPreMappingInfo = false;
    this.isEnabledEdit = true;
    this.isShowMap = false;
    this.isShowProgressBar = false;
    this.isShowDropZone = true;
    this.isShowDropZoneToAdd = false;
    this.isShowUploadAndOverwriteButton = false;
    this.isShowTable= false;
    this.isAllAddressesValid = true;
    this.isCurrentlyInserting = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
		this.destroy$.complete();
    if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
		});
  }

  onMapReady(event) {
    this.map = event;
		this.addYourLocationButton(this.map);
    combineLatest(
      this.apiQuery.filteredBins$,
      this.apiQuery.preMappingInfo$
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(([bins,preMappingInfo])=>{	
        this.mapClick();
        this.mapSpinnerActive = true;
        if(!bins || bins.length == 0){return;}
				this.mapSpinnerActive = false;
        const binData = _.filter(bins, (bin) => {
          return bin["site_id"] == this.siteId;
        });
      
        let tmpMappingInfo: any = preMappingInfo;
        if(preMappingInfo.length > 0){
          this.isPreMappingInfoResponseReceived = true;
          if (tmpMappingInfo[0].bin_letter != '0') {
            const totalBinsToMapWithoutNotFoundBins = _.filter(preMappingInfo, (info) => {
              return info["is_bin_not_here"] == 0;
            });
            this.totalBinsToMap = totalBinsToMapWithoutNotFoundBins.length;
            this.isPreMappingInfo = true;
            this.isEnabledEdit = true;
            this.isShowDropZone = false;
            this.isShowDropZoneToAdd = false;
            this.finalArray = preMappingInfo;
          }else {
            this.isShowDropZone = true;
            this.isShowDropZoneToAdd = false;
            this.finalArray = [];
            this.setContentVisibility(true);
          }
        }
        this.displayData(); 
        this.bins = binData.concat(this.finalArray);
        this.backUpBins = this.bins;
        if(this.bins.length > 0){
          this.isShowMap = true;
          this.initializeBinsMap();
        }else{
          this.isShowMap = false;
        }

        if(this.items.length == 0){
          this.items = [
            { displayName: 'SITE_MANAGMENT.BINS_MAPPED', name: 'Red Bins', selected: true, color:"#EF8378"},
            { displayName: 'SITE_MANAGMENT.BINS_INSTALLED', name: 'Green Bins', selected: false, color:"#66D699" },
            { displayName: 'WIDGETS.NOT_EXIST', name: 'Non Active', selected: false, color:"#9DA9AD" }
          ];
        }
        
				this.selectUnselectItem(this.items[0],0);
				this.selectUnselectItem(this.items[1],0);
				this.selectUnselectItem(this.items[2],0);	

        this.cd.detectChanges();
      });
  }

  addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-premapping';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
      if(document.getElementById('center-premapping')){
        document.getElementById('center-premapping').style.backgroundPosition = '0px 0px';
      }
		});

		firstChild.addEventListener('click', function() {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
        if(document.getElementById('center-premapping')){
          document.getElementById('center-premapping').style.backgroundPosition = imgX+'px 0px';
        }
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
				map.setZoom(15);
				clearInterval(animationInterval);
        if(document.getElementById('center-premapping')){
          document.getElementById('center-premapping').style.backgroundPosition = '-144px 0px';
        }
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
        if(document.getElementById('center-premapping')){
          document.getElementById('center-premapping').style.backgroundPosition = '0px 0px';
        }
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

  selectUnselectItem(item,flag) {
    if(this.mapSpinnerActive){return;}
		this.mapClick();
    if(flag == 1){
      item.selected = !item.selected;
    }
		let items = [];
		items = this.items.filter(item => item.selected).map((obj) => {return obj.name});
		this.types = items;
		this.filterClustersAndColors();	
    this.loadAPIWrapperInternal(this.map, null,this.backUpBins);
	}

	filterClustersAndColors(){	
		this.fliterMarkers();
		let prevZoom = this.map.getZoom();
		this.map.setZoom(-12);
		this.map.setZoom(prevZoom);
	}

  fliterMarkers() {				
		let categories = ['Red Bins', 'Green Bins', 'Non Active'];		
		categories = this.types;		
		_.each(this.markersArray, (marker) => {			
			if (categories.includes(marker["category"])) {
				marker.visible = true;
			} else {
				marker.visible = false;
			}
		});
	}

  convertMarkerToColor(icon){
		if(icon.toLowerCase().includes("red")){
			return "Red Bins";
		}else if(icon.toLowerCase().includes("green")){
			return "Green Bins";
		}else{
			return "Non Active";
		}
	}

  applyMapMarkersFilter = (filterValue: string) => {
		let filteredArr = [];
		if(this.backUpBins != undefined && this.backUpBins.length > 0){
			filterValue = filterValue.trim(); 
			if (filterValue === "") {
				const location = {
					lat: Number(this.backUpBins[0].Bin_location.bin_latitude.toString()),
					lng: Number(this.backUpBins[0].Bin_location.bin_longitude.toString())
				};
	
				this.loadAPIWrapperInternal(this.map, location,this.backUpBins);
			}
			else{
				_.each(this.backUpBins, (item) => {								
					if((item["bin_id"] && item["bin_id"].toString().includes(filterValue)) || 
          (item["bin_name"].toString().includes(filterValue)) || 
            ((item["Bin_location"]["bin_neighbourhood"]) && 
            (item["Bin_location"]["bin_neighbourhood"].toString().includes(filterValue))) ||
          (item["Bin_location"]["bin_address"].toString().includes(filterValue))){
						filteredArr.push(item);	
					}			
				});

				this.bins = [];
				this.bins = filteredArr;				

				if(this.bins != undefined && this.bins.length > 0){
					const location = {
						lat: Number(this.bins[0].Bin_location.bin_latitude),
						lng: Number(this.bins[0].Bin_location.bin_longitude)
					};					
					this.loadAPIWrapperInternal(this.map, location,this.bins);
				}else{
					this.loadAPIWrapperInternal(this.map, null,this.backUpBins);
				}
			}

		}
	}

  loadAPIWrapperInternal(map: any, customLocation: any,bins) {
		if (!map) return;
		this.map.setZoom(-12);
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (customLocation) {
			userPosition.lat = Number(customLocation.lat);
			userPosition.lng = Number(customLocation.lng);
		}

		if (bins!=undefined && bins.length > 0) {
      let filteredBins = [];
      _.each(bins, (bin) => {
        this.BinToFilter(bin);
        if(this.types.includes(bin["filterCategory"])){
          filteredBins.push(bin);
        }
      });
      const bounds = new google.maps.LatLngBounds();				
      filteredBins.map(bin => {
        bounds.extend({ lat: bin.Bin_location.bin_latitude, lng: bin.Bin_location.bin_longitude });
      });
      this.map.fitBounds(bounds);
    }else{
      this.map.panTo(userPosition);
      this.map.setZoom(12);
    }
	}

  initializeBinsMap = () => {
    this.resetMarkers();			
    this.setMarkersByStatus(this.map, this.bins);
	}

	mapBinsByCoordinates(bins: API.Bin[]) {
		const binsHash = {};
		_.each(bins, b => {
      if(!b["Bin_location"]){
        b["Bin_location"] = {
          bin_latitude : Number(b["bin_lat"]),
          bin_longitude : Number(b["bin_lng"]),
          bin_address : b["bin_address"]
        };

        b["linkToInstallationWizard"] = "newinstallationwizard" + '/' + this.siteId + '/' + b["bin_letter"] + b["bin_number"];
      }
      if(!b["bin_name"]){
        b["bin_name"] = b["bin_letter"] + b["bin_number"];
      }
      if(!b["bin_description"]){
        b["bin_description"] = b["comments"];
      }
			if (binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]] !== undefined) {
				binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]].push(b);
			} else {
				binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]] = [b];
			}
		});
		return binsHash;
	}

  mapBinsByClusterUsingSystem(bins: API.Bin[]) {
		const binsHashUsingSystem = {};
		_.each(bins, b => {
			if (binsHashUsingSystem[b.cluster_id+b["site_id"]] !== undefined) {
				binsHashUsingSystem[b.cluster_id+b["site_id"]].push(b);
			} else {
				binsHashUsingSystem[b.cluster_id+b["site_id"]] = [b];
			}
		});
		return binsHashUsingSystem;
	}

	mapClick() {	
    if(!this.infoWindows){return;}
		this.infoWindows.forEach((infoWindow: MapInfoWindow ) => {
			infoWindow.close();
		});
	}

  clusterToIcon(cluster){				
		let icon = '';		
    if(cluster[0].Bin_live_Data){
      if(cluster.length == 2){
        icon = `../../assets/images/bins/all/defaultGreenCluster1.svg`;
      }else if(cluster.length == 3){
        icon = `../../assets/images/bins/all/defaultGreenCluster2.svg`;
      }else if(cluster.length == 4){
        icon = `../../assets/images/bins/all/defaultGreenCluster3.svg`;
      }else{
        icon = `../../assets/images/bins/all/defaultGreenCluster4.svg`;
      }		
    }else{
      let nonActiveCluster = _.find(cluster, function (item) {
        return (item.is_bin_not_here == "1")
      });	
      if(nonActiveCluster != null){			
        if(cluster.length == 2){
          icon = `../../assets/images/bins/all/nonActiveWithIconCluster1.png`;
        }else if(cluster.length == 3){
          icon = `../../assets/images/bins/all/nonActiveWithIconCluster2.png`;
        }else if(cluster.length == 4){
          icon = `../../assets/images/bins/all/nonActiveWithIconCluster3.png`;
        }else{				
          icon = `../../assets/images/bins/all/nonActiveWithIconCluster4.png`;
        }			
      }else{
        if(cluster.length == 2){
          icon = `../../assets/images/bins/all/defaultRedCluster1.svg`;
        }else if(cluster.length == 3){
          icon = `../../assets/images/bins/all/defaultRedCluster2.svg`;
        }else if(cluster.length == 4){
          icon = `../../assets/images/bins/all/defaultRedCluster3.svg`;
        }else{
          icon = `../../assets/images/bins/all/defaultRedCluster4.svg`;
        }		
      }
    }
    return icon;
	}
  
	setMarkersByStatus(map, bins: API.Bin[]): void {
		this.binsHash = this.mapBinsByCoordinates(bins);
		_.forOwn(this.binsHash, (value :any, key) => {
			if(value.length > 1){
				let isPartOfCluster = false;
				const icon = this.clusterToIcon(value);	
				const checkIfCluster = 	this.mapBinsByClusterUsingSystem(value);	
				_.forOwn(checkIfCluster, (value :any, key) => {
					if(value.length > 1){
						isPartOfCluster = true;
					}					
				})
				const marker = {
					icon: icon,
          category : this.convertMarkerToColor(icon),
					lat: Number(value[0].Bin_location.bin_latitude),
					lng: Number(value[0].Bin_location.bin_longitude),
					label:'',
					bin_name : value[0].bin_name,
					bin_id : value[0].bin_id,
					cluster_id: value[0].cluster_id,
					cluster : true,
					item:value,
          fill_level_percent : value[0]["Bin_live_Data"] ? value[0]["Bin_live_Data"].fill_level_percent : 0,
					isInfoWindowOpen : false,
					visible: true,
          title : value.length.toString(),
					site_id: value[0].site_id,
					bin_address : value[0].Bin_location.bin_address,
					bin_neighbourhood : value[0].Bin_location["bin_neighbourhood"],
					last_collection_time : value[0]["Bin_live_Data"] ? value[0]["Bin_live_Data"]["last_collection_time"] : null,
					color_status_name : value[0]["Bin_live_Data"] ? value[0]["Bin_live_Data"]["color_status_name"] : null,
					draggable : false,
					partOfCluster : isPartOfCluster,
          isPreMapping : value[0]["Bin_live_Data"] ? false : true
				};

				if(!this.clustersArrDic[value[0].Bin_location.bin_latitude+","+value[0].Bin_location.bin_longitude]){			
					this.clustersArrDic[value[0].Bin_location.bin_latitude+","+value[0].Bin_location.bin_longitude] = true;
					this.markersArray.push(marker);
				} 
			}else{
				value.forEach((element, index, array) => {
					const b = element;					
					const icon = this.BinToIcon(b);	
					const label = '';				
					const marker = {
						icon : icon,
            category : this.convertMarkerToColor(icon),
						lat :  Number(b.Bin_location.bin_latitude),
						lng : Number(b.Bin_location.bin_longitude),	
						label:label,
            bin_name : b.bin_name ? b.bin_name : b.bin_letter + b.bin_number,
						bin_id : b.bin_id,
						cluster_id: element.cluster_id,
						cluster : false,
						fill_level_percent : element["Bin_live_Data"] ? element["Bin_live_Data"].fill_level_percent : 0,
						isInfoWindowOpen : false,
						visible : true,
            title : "1",
						site_id : b["site_id"],
						bin_address : b.Bin_location.bin_address,
						bin_neighbourhood : b.Bin_location["bin_neighbourhood"],
						last_collection_time : b["Bin_live_Data"] ?  b["Bin_live_Data"]["last_collection_time"] : null,
            color_status_name : b["Bin_live_Data"] ? b["Bin_live_Data"]["color_status_name"] : null,
						draggable : false,
            isPreMapping : b["Bin_live_Data"] ? false : true,
            bin_description : b.bin_description ? b.bin_description : b.comments,
            linkToInstallationWizard : "newinstallationwizard" + '/' + this.siteId + '/' + b.bin_letter + b.bin_number,
					}	
          if(marker.bin_id){
            if(!this.markersArrayDic[marker.bin_id]){
              this.markersArrayDic[marker.bin_id] = true;
              this.markersArray.push(marker);
            }		
          }else{
            if(!this.markersArrayDic[marker.bin_name]){
              this.markersArrayDic[marker.bin_name] = true;
              this.markersArray.push(marker);
            }	
          }
				});
			}
		});	  
		// if(bins.length > 500){
		// 	this.moreThanMax = true;
		// }else{
		// 	this.moreThanMax = false;
		// }
	}

  isNumber(val): boolean { return typeof val === 'number'; }

  clickedMarker(bin) {		
    if(this.mapSpinnerActive){return;}
    this.clickedBin = bin;
		let content;
    let isNotPreMappingMarkerBody;	
    let isNotPreMappingMarkerTitle;		
    let isNotPreMappingMarkerHeader;		
    let manageBinLink;
    let wazeLink;	
	  		  
		if(!bin.cluster){
			if(this.isMobile){
        wazeLink = document.createElement('a');
        wazeLink.setAttribute('class','tooltip-waze');
        wazeLink.setAttribute('href',`https://waze.com/ul?ll=${bin.lat},${bin.lng}`);
        let img = document.createElement('img');
        img.setAttribute('class','wazeIcon');
        img.setAttribute('src','assets/images/dashboard/waze.svg');
        img.setAttribute('alt','');
        wazeLink.appendChild(img);
			}	
      if(!bin.isPreMapping){
        isNotPreMappingMarkerBody = document.createElement('div');
        isNotPreMappingMarkerBody.setAttribute('class','tooltip-id');
        isNotPreMappingMarkerBody.setAttribute('style','padding-bottom: 9px;');
        let tooltipText = document.createElement('div');
        tooltipText.setAttribute('class','tooltip-text');
        tooltipText.innerHTML = this.usageType == 1 ? this.translationsObj.MAP_MARKER.VOLUME : this.translationsObj.MAP_MARKER.VOLUME_D;
        isNotPreMappingMarkerBody.appendChild(tooltipText);
        let tooltipMarker = document.createElement('div');
        tooltipMarker.setAttribute('class','tooltip-marker');
        tooltipMarker.setAttribute('style',this.getTooltipStyle(bin.fill_level_percent));
        tooltipMarker.innerHTML = this.usageType == 1 ? bin.fill_level_percent : bin.bin_max_capacity_in_liters * bin.fill_level_percent / 100 + (this.capacityDisplayType !=0 ? 'L' : '%');
        let tooltipArrowMarker = document.createElement('div');
        tooltipArrowMarker.setAttribute('class','tooltip-arrow-marker');
        tooltipMarker.appendChild(tooltipArrowMarker);
        isNotPreMappingMarkerBody.appendChild(tooltipMarker);
        let progressbar = document.createElement('div');
        progressbar.setAttribute('id','progressbar');
        progressbar.setAttribute('class','progress_bar');
        progressbar.setAttribute('style',`background: ${this.getProgressBarStyleWrapper(bin.color_status_name)};border-radius: 10px;margin-bottom: 5px;display:flex;position: relative;`);
        let progressbarInternal = document.createElement('div');
        progressbarInternal.setAttribute('class','progress_bar_internal');
        progressbarInternal.setAttribute('style',`background: ${this.getProgressBarStyleInternal(bin.color_status_name)};width: ${this.getProgressBarWidthInternal(bin.fill_level_percent)};height: 14px;border-radius: 10px; line-height: 15px;`);
        progressbar.appendChild(progressbarInternal);
        isNotPreMappingMarkerBody.appendChild(progressbar);

        isNotPreMappingMarkerTitle = document.createElement('div');
        isNotPreMappingMarkerTitle.setAttribute('class','tooltip-value');
        let binNameHref = document.createElement('a');
        binNameHref.setAttribute('class','binNameHref-truckMap tooltip-value bin-name');
        binNameHref.setAttribute('href',`/binLiveInfoPage?pageName=dashboard&binId=${bin.bin_id}`);
        binNameHref.innerHTML = bin.bin_name;
        isNotPreMappingMarkerTitle.appendChild(binNameHref);
        let span = document.createElement('span');
        span.innerHTML = " | ";
        isNotPreMappingMarkerTitle.appendChild(span);
        let span2 = document.createElement('span');
        span2.innerHTML = bin.bin_id;
        isNotPreMappingMarkerTitle.appendChild(span2);

        isNotPreMappingMarkerHeader = document.createElement('div');
        isNotPreMappingMarkerHeader.setAttribute('class','tooltip-id border-bottom');
        let tooltipAddress = document.createElement('div');
        tooltipAddress.setAttribute('class','tooltip-address');
        tooltipAddress.setAttribute('title',bin.bin_address);
        tooltipAddress.innerHTML = bin.bin_address + (wazeLink ? wazeLink.outerHTML : '');
        isNotPreMappingMarkerHeader.appendChild(tooltipAddress);
        let tooltipAddress2 = document.createElement('div');
        tooltipAddress2.setAttribute('class','tooltip-address');
        tooltipAddress2.setAttribute('title',bin.bin_neighbourhood == null ? '' : bin.bin_neighbourhood);
        tooltipAddress2.innerHTML = bin.bin_neighbourhood == null ? "" : bin.bin_neighbourhood;
        isNotPreMappingMarkerHeader.appendChild(tooltipAddress2);

        manageBinLink = this.translationsObj.TITLES.MANAGE_BIN;
      }else{
        isNotPreMappingMarkerTitle = document.createElement('div');
        isNotPreMappingMarkerTitle.setAttribute('class','tooltip-value');
        let binNameHref = document.createElement('span');
        binNameHref.setAttribute('class','binNameHref-truckMap tooltip-value bin-name');
        binNameHref.innerHTML = bin.bin_name;
        isNotPreMappingMarkerTitle.appendChild(binNameHref);

        isNotPreMappingMarkerHeader = document.createElement('div');
        isNotPreMappingMarkerHeader.setAttribute('class','tooltip-id');
        let tooltipAddress = document.createElement('div');
        tooltipAddress.setAttribute('class','tooltip-address');
        tooltipAddress.setAttribute('title',bin.bin_address);
        tooltipAddress.innerHTML = bin.bin_address + (wazeLink ? wazeLink.outerHTML : '');
        isNotPreMappingMarkerHeader.appendChild(tooltipAddress);
        let tooltipAddress2 = document.createElement('div');
        tooltipAddress2.setAttribute('class','tooltip-address');
        tooltipAddress2.setAttribute('title',bin.bin_neighbourhood == null ? '' : bin.bin_neighbourhood);
        tooltipAddress2.innerHTML = bin.bin_neighbourhood == null ? "" : bin.bin_neighbourhood;
        isNotPreMappingMarkerHeader.appendChild(tooltipAddress2);
        let tooltipAddress3 = document.createElement('div');
        tooltipAddress3.setAttribute('class','tooltip-address');
        tooltipAddress3.setAttribute('title',bin.bin_description);
        tooltipAddress3.innerHTML = bin.bin_description;
        isNotPreMappingMarkerHeader.appendChild(tooltipAddress3);

        isNotPreMappingMarkerBody = document.createElement('button');
        isNotPreMappingMarkerBody.className = 'btnIntallationWizardCarousel';
        isNotPreMappingMarkerBody.addEventListener("click", (e) => {
          this.router.navigate([`/${bin.linkToInstallationWizard}`]);
        });

        isNotPreMappingMarkerBody.innerHTML = this.translationsObj.SITE_MANAGMENT.NEW_INSTALLATION_WIZARED;
      }

      content = document.createElement('div');
      content.setAttribute('class','iw-content');
      content.setAttribute('style','padding-left:unset;padding-right:unset;');
      let tooltipId = document.createElement('div');
      tooltipId.setAttribute('class','tooltip-id');
      tooltipId.appendChild(isNotPreMappingMarkerTitle);
      tooltipId.appendChild(isNotPreMappingMarkerHeader);
      content.appendChild(tooltipId);
      content.appendChild(isNotPreMappingMarkerBody);
      if(!bin.isPreMapping){
        let div = document.createElement('div');
        div.setAttribute('name',bin.bin_id);
        div.setAttribute('style','padding:5px 0px;color:#007bff;cursor: pointer');
        div.setAttribute('id',bin.bin_id);
        div.innerHTML = manageBinLink;
        content.appendChild(div);
      }
		}else{						
			let binTooltips = '';
			for(let i=0; i < bin.item.length; i++){
				let capacityVolume = bin.item[i].Bin_live_Data ? bin.item[i].Bin_live_Data.fill_level_percent : 0;
				let capacityDataSymbol = '%';
				if (this.capacityDisplayType != 0) {
					capacityDataSymbol = 'L';
					capacityVolume = bin.item[i].Bin_thresholds.bin_max_capacity_in_liters * capacityVolume / 100;
				}
				if(this.isMobile){
          wazeLink = document.createElement('a');
          wazeLink.setAttribute('class','tooltip-waze');
          wazeLink.setAttribute('href',`https://waze.com/ul?ll=${bin.lat},${bin.lng}`);
          let img = document.createElement('img');
          img.setAttribute('class','wazeIcon');
          img.setAttribute('src','assets/images/dashboard/waze.svg');
          img.setAttribute('alt','');
          wazeLink.appendChild(img);
				}

        if(!bin.isPreMapping){
          manageBinLink = this.translationsObj.TITLES.MANAGE_BIN;

          isNotPreMappingMarkerBody = document.createElement('div');
          isNotPreMappingMarkerBody.setAttribute('class','tooltip-id');
          isNotPreMappingMarkerBody.setAttribute('style','padding-bottom: 9px;');
          let tooltipText = document.createElement('div');
          tooltipText.setAttribute('class','tooltip-text');
          tooltipText.innerHTML = this.translationsObj.MAP_MARKER.VOLUME;
          isNotPreMappingMarkerBody.appendChild(tooltipText);
          let tooltipMarker = document.createElement('div');
          tooltipMarker.setAttribute('class','tooltip-marker');
          tooltipMarker.setAttribute('style',this.getTooltipStyle(bin.item[i].Bin_live_Data.fill_level_percent));
          tooltipMarker.innerHTML = this.usageType == 1 ? bin.item[i].Bin_live_Data.fill_level_percent : bin.item[i].Bin_thresholds.bin_max_capacity_in_liters * bin.item[i].Bin_live_Data.fill_level_percent / 100 + (this.capacityDisplayType !=0 ? 'L' : '%');
          let tooltipArrowMarker = document.createElement('div');
          tooltipArrowMarker.setAttribute('class','tooltip-arrow-marker');
          tooltipMarker.appendChild(tooltipArrowMarker);
          isNotPreMappingMarkerBody.appendChild(tooltipMarker);
          let progressbar = document.createElement('div');
          progressbar.setAttribute('id','progressbar');
          progressbar.setAttribute('class','progress_bar');
          progressbar.setAttribute('style',`background: ${this.getProgressBarStyleWrapper(bin.item[i].Bin_live_Data.color_status_name)};border-radius: 10px;margin-bottom: 5px;display:flex;position: relative;`);
          let progressbarInternal = document.createElement('div');
          progressbarInternal.setAttribute('class','progress_bar_internal');
          progressbarInternal.setAttribute('style',`background: ${this.getProgressBarStyleInternal(bin.item[i].Bin_live_Data.color_status_name)};width: ${this.getProgressBarWidthInternal(bin.item[i].Bin_live_Data.fill_level_percent)};height: 14px;border-radius: 10px; line-height: 15px;`);
          progressbar.appendChild(progressbarInternal);
          isNotPreMappingMarkerBody.appendChild(progressbar);
          let divBin = document.createElement('div');
          divBin.setAttribute('name',bin.item[i].bin_id);
          divBin.setAttribute('style','padding:5px 0px;color:#007bff;cursor: pointer');
          divBin.setAttribute('id',bin.item[i].bin_id);
          divBin.innerHTML = manageBinLink;
          isNotPreMappingMarkerBody.appendChild(divBin);

          isNotPreMappingMarkerTitle = document.createElement('div');
          isNotPreMappingMarkerTitle.setAttribute('class','tooltip-value');
          let binNameHref = document.createElement('a');
          binNameHref.id = 'clickableItem';
          binNameHref.setAttribute('class','binNameHref-truckMap tooltip-value bin-name');
          binNameHref.setAttribute('href',`/binLiveInfoPage?pageName=workplans&binId=${bin.item[i].bin_id}`);
          binNameHref.innerHTML = bin.item[i].bin_name;
          isNotPreMappingMarkerTitle.appendChild(binNameHref);
          let span = document.createElement('span');
          span.innerHTML = " | ";
          isNotPreMappingMarkerTitle.appendChild(span);
          let span2 = document.createElement('span');
          span2.innerHTML = bin.item[i].bin_id;
          isNotPreMappingMarkerTitle.appendChild(span2);

          isNotPreMappingMarkerHeader = document.createElement('div');
          isNotPreMappingMarkerHeader.setAttribute('class','tooltip-id border-bottom');
          let tooltipAddress = document.createElement('div');
          tooltipAddress.setAttribute('class','tooltip-address');
          tooltipAddress.innerHTML = bin.item[i].Bin_location.bin_address + (wazeLink ? wazeLink.outerHTML : '');
          isNotPreMappingMarkerHeader.appendChild(tooltipAddress);
          let tooltipAddress2 = document.createElement('div');
          tooltipAddress2.setAttribute('class','tooltip-address');
          tooltipAddress2.innerHTML = bin.item[i].Bin_location.bin_neighbourhood == null ? "" : bin.item[i].Bin_location.bin_neighbourhood;
          isNotPreMappingMarkerHeader.appendChild(tooltipAddress2);
        }else{
          let tooltipValue = document.createElement('div');
          tooltipValue.setAttribute('class','tooltip-value');
          let span = document.createElement('span');
          span.setAttribute('class','binNameHref-truckMap tooltip-value bin-name');
          span.setAttribute('id','clickableItem');
          span.innerHTML = bin.item[i].bin_name;
          tooltipValue.appendChild(span);

          let tooltipId = document.createElement('div');
          tooltipId.setAttribute('class','tooltip-id');
          let tooltipAddress = document.createElement('div');
          tooltipAddress.setAttribute('class','tooltip-address');
          tooltipAddress.innerHTML = bin.item[i].Bin_location.bin_address + (wazeLink ? wazeLink.outerHTML : '');
          tooltipId.appendChild(tooltipAddress);
          let tooltipAddress2 = document.createElement('div');
          tooltipAddress2.setAttribute('class','tooltip-address');
          tooltipAddress2.innerHTML = bin.item[i].Bin_location.bin_neighbourhood == null ? "" : bin.item[i].Bin_location.bin_neighbourhood;
          tooltipId.appendChild(tooltipAddress2);
          let tooltipAddress3 = document.createElement('div');
          tooltipAddress3.setAttribute('class','tooltip-address');
          tooltipAddress3.innerHTML = bin.item[i].bin_description;
          tooltipId.appendChild(tooltipAddress3);

          isNotPreMappingMarkerBody = document.createElement('button');
          let buttonId = '';
          if(bin.item[i].bin_id){
            buttonId = bin.item[i].bin_id;
          }else{
            buttonId = bin.item[i].bin_name + bin.item[i].site_id;
          } 
          isNotPreMappingMarkerBody.id = "btnIntallationWizardCluster" + buttonId;
          isNotPreMappingMarkerBody.className = 'btnIntallationWizardCarousel';
          document.addEventListener('click',(e) =>{            
            if(e.target && e.target['id'] == "btnIntallationWizardCluster" + buttonId){
                this.router.navigate([`/${bin.item[i].linkToInstallationWizard}`]);
            }
          });
          isNotPreMappingMarkerBody.innerHTML = this.translationsObj.SITE_MANAGMENT.NEW_INSTALLATION_WIZARED;

          isNotPreMappingMarkerTitle = tooltipValue;  
          isNotPreMappingMarkerHeader = tooltipId;
        }

        let carouselItem = document.createElement('div');
        carouselItem.setAttribute('class',`carousel-item ${i == 0 ? "active" : ""}`);
        let iwContent = document.createElement('div');
        iwContent.setAttribute('class','iw-content');
        iwContent.setAttribute('style','padding-left:unset;padding-right:unset;');
        let tooltipId = document.createElement('div');
        tooltipId.setAttribute('class','tooltip-id');
        tooltipId.appendChild(isNotPreMappingMarkerTitle);
        tooltipId.appendChild(isNotPreMappingMarkerHeader);
        iwContent.appendChild(tooltipId);
        iwContent.appendChild(isNotPreMappingMarkerBody);
        carouselItem.appendChild(iwContent);
        binTooltips += carouselItem.outerHTML;
			}

      let carouselControl = document.createElement('button');
      carouselControl.setAttribute('class','carousel-control');
      carouselControl.setAttribute('type','button');
      carouselControl.setAttribute('data-bs-target','#carouselExampleControls');
      carouselControl.setAttribute('data-bs-slide','prev');
      carouselControl.setAttribute('class','prevNextArrow');
      let img = document.createElement('img');
      img.setAttribute('src','assets/images/dashboard/pagination_arrow.png');
      img.setAttribute('alt','');
      img.setAttribute('class',this.currentLang != 'iw' ? "rotation" : "");
      carouselControl.appendChild(img);
      let span = document.createElement('span');
      span.setAttribute('class','sr-only');
      carouselControl.appendChild(span);

      let span2 = document.createElement('span');
      span2.setAttribute('style','position:relative;bottom:3px;padding: 0px 8px;');
      span2.innerHTML = "|";

      let carouselControl2 = document.createElement('button');
      carouselControl2.setAttribute('class','carousel-control');
      carouselControl2.setAttribute('type','button');
      carouselControl2.setAttribute('data-bs-target','#carouselExampleControls');
      carouselControl2.setAttribute('data-bs-slide','next');

      let img2 = document.createElement('img');
      img2.setAttribute('src','assets/images/dashboard/pagination_arrow.png');
      img2.setAttribute('alt','');
      img2.setAttribute('class',this.currentLang == 'iw' ? "rotation" : "");
      carouselControl2.appendChild(img2);
      let span3 = document.createElement('span');
      span3.setAttribute('class','sr-only');
      carouselControl2.appendChild(span3);

      let carousel = document.createElement('div');
      carousel.setAttribute('id','carouselExampleControls');
      carousel.setAttribute('style','top:9px');
      carousel.setAttribute('class','carousel slide');
      carousel.setAttribute('data-bs-touch','false');
      carousel.setAttribute('data-bs-interval','false');
      let carouselInner = document.createElement('div');
      carouselInner.setAttribute('class','carousel-inner');
      carouselInner.innerHTML = binTooltips;
      carousel.appendChild(carouselInner);  

      content = document.createElement('div');
      content.appendChild(carouselControl);
      content.appendChild(span2);
      content.appendChild(carouselControl2);
      content.appendChild(carousel);
		}

    let node;
    node = document.createElement("div");
    node.id = "infoWindow-popup";
    node.className = 'infoWindow-class';
    node.appendChild(content);

		if(this.moreThanMax){
			setTimeout(() => {					
				this.checkIfInfoWindowExist();
				document.getElementById("binInfoWindow_multiSites").append(node);
			}, 100);
		}else{
			setTimeout(() => {
				this.checkIfInfoWindowExist();
				document.getElementById("binInfoWindow_singleSite").append(node);
			}, 100);
		}	
	}

  getProgressBarStyleWrapper = (binInfo: any) => {		
		if (!binInfo) { return ''; }
		if (binInfo == "GREEN") {
			return 'rgba(52, 208, 141, 0.15)';
		} else if (binInfo == "YELLOW") {
			return 'rgba(226, 224, 85, 0.15)';
		}
		return 'rgba(215, 89, 76, 0.15)';
  }

  getProgressBarStyleInternal = (binInfo: any) => {
    if (!binInfo) { return ''; }
    if (binInfo == "GREEN") {
      return '#34D08D';
    } else if (binInfo == "YELLOW") {
      return '#E2E055';
    }
    return '#D7594C';
  }

  
	openMarkerInfoWindow(marker: MapMarker, markerIndex: number) {
		this.infoWindows.forEach((infoWindow: MapInfoWindow, index: number ) => {
		  if (index === markerIndex) {
			infoWindow.open(marker);
		  } else {
			infoWindow.close();
		  }
		});
	}

  getTooltipStyle = (binInfo: any) => {		
		if(this.currentLang == 'iw'){
			return `margin-right: ${binInfo-7}%`;
		}else{
			return `margin-left: ${binInfo-7}%`;
		}
	}

  getProgressBarWidthInternal = (binInfo: any) => {
    if (!binInfo) { return ''; }
    return `${binInfo}%`;
  }

	checkIfInfoWindowExist(){
		if(document.getElementById('infoWindow-popup')){
			document.getElementById('infoWindow-popup').remove();
		}
	}

  infoWindowClose(infoWindow) {
	}

  private BinToIcon(bin) {	
		let icon = '';		
    if(bin.Bin_live_Data){
      icon = `../../assets/images/bins/all/default-collected-green.svg`;
    }else{
      if(bin.is_bin_not_here == "1"){
        icon = `../../assets/images/bins/all/nonActive.svg`;
      }else{
        icon = `../../assets/images/bins/all/redNotOrdered.png`;
      }
    }
		return icon;
	}

  
  BinToFilter(bin) {	
    if(bin.Bin_live_Data){
      bin.filterCategory = `Green Bins`;
    }else{
      if(bin.is_bin_not_here == "1"){
        bin.filterCategory = `Non Active`;
      }else{
        bin.filterCategory = `Red Bins`;
      }
    }
	}

  private resetMarkers() {
    _.each(this.markersArray, (b) => {
			b["visible"] = false;
		});
		this.clustersArrDic = {};
		this.markersArrayDic = {};
	}

  uploadFile(flag:string) {
    this.numOfGoogleApiResults = 0;
    this.isShowTable = false;
    this.isShowUploadAndOverwriteButton = false;
    this.isPreMappingInfo = true;
    this.totalBinsToAdd = 0;
    this.isEnabledEdit = false;
    let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {type:"binary"});
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        let readExcel = XLSX.utils.sheet_to_json(worksheet,{raw:true});

        readExcel.forEach((rowElement: any, rowIndex, rowArray) => {
          let preInstallationXlsxObj: API.IPreInstallationXlsxObj = {
            num_of_bins: rowElement.num_of_bins,
            original_index: rowIndex,
            bin_letter: null,
            bin_number: null,
            bin_address: rowElement.bin_address.toString().trim(),
            isAddressValid: rowElement.lat && rowElement.lat !='' && rowElement.lon && rowElement.lon !='' ? true : false,
            comments: rowElement.comments,
            bin_lat: rowElement.lat && rowElement.lat !='' ? rowElement.lat : null,
            bin_lng: rowElement.lon && rowElement.lon !='' ? rowElement.lon : null,
            distance_from_start_point: null,
            waze_link: null
          }

          if (rowElement.num_of_bins > 0) {
            this.xlsxContentAsArray.push(preInstallationXlsxObj); 
          }
        });
        _.each(this.xlsxContentAsArray, (item) => {
          this.totalBinsToAdd += item.num_of_bins;
        });
        this.addCoordinates(flag);
      } 
      fileReader.readAsArrayBuffer(this.file);
  }

  distance(point1,point2) {
    return Math.sqrt(Math.pow(point1["bin_lat"] - point2["bin_lat"], 2) + Math.pow(point1["bin_lng"] - point2["bin_lng"], 2))
  }

  async addCoordinates(flag:string) {
    this.isShowProgressBar = true;
    for (let binRowIndex = 0; binRowIndex < this.xlsxContentAsArray.length;binRowIndex++) {

      for (let numberOfBinsIndex = 0; numberOfBinsIndex < this.xlsxContentAsArray[binRowIndex].num_of_bins ;numberOfBinsIndex++) {

        this.progressBarValue = Math.round((binRowIndex / this.xlsxContentAsArray.length) * 100);
        let binRow = this.xlsxContentAsArray[binRowIndex];
        let location = binRow.bin_address;
        let geoCodeLocation = location.split("__")[0];
        let response: any = 0;

        if(this.xlsxContentAsArray[binRowIndex].bin_lat == null || this.xlsxContentAsArray[binRowIndex].bin_lng == null){
          let dataToSend = {
            address: geoCodeLocation,
            localization: this.siteObject.localization,
            lang: this.currentLang
          }
          response = await this.apiService.getGoogleAddressObjFromAddress(dataToSend).toPromise();
        }
        this.provideAddressAndCoordinatesAccordingly(response, binRow);
      }
    }

   this.isShowTable = true;
    if (this.addressesIssuesArray.length > 0) {
      this.isAllAddressesValid = false;
      this.getIssuesTableData();
    }
    else {
      this.setAllAddressesValid(flag);
    }
  }

  provideAddressAndCoordinatesAccordingly(response, binRow) {
    if(response == 0){
      this.addMarkerWithCoordinatesAndAddress(binRow,null);
    }else if (response && response.status === "OK") {
      let preciseLocationIndex =  _.findIndex(response.results, function(o: any) {
        return o.geometry.location_type === 'ROOFTOP' || o.geometry.location_type === 'RANGE_INTERPOLATED';
      });
      
      if (preciseLocationIndex >= 0) {
        let result = response.results[preciseLocationIndex];
        this.addMarkerWithCoordinatesAndAddress(binRow,result);
      }
      else {
        let result = response.results[0];
        this.addMarkerWithCoordinatesAndAddress(binRow,result);
      }     
    }
    else {
      this.addressesIssuesArray.push(binRow);
    }
  }

  addMarkerWithCoordinatesAndAddress(binRow,result) {
    if(result){
      let binLat = result.geometry.location.lat;
      let binLng = result.geometry.location.lng;
      let formattedAddress = result.formatted_address;
      binRow.bin_lat = binLat;
      binRow.bin_lng = binLng;
      binRow.isAddressValid = true;    
      this.addMarker(binRow.bin_lat, binRow.bin_lng, binRow.bin_address, formattedAddress);
    }else{
      this.addMarker(binRow.bin_lat, binRow.bin_lng, binRow.bin_address, binRow.bin_address);
    }
  }

  confirm(funcunality) {
    switch (funcunality) {
      case 'getData':
        break;
      case 'reportBinIsNotHere':
        break;
      case 'reportBinIsHere':
        break;
      case 'removeBin':
        this.removeBin();
        break;
      case 'binHasBeenRemoved':
        break;
      case 'binHasNotBeenRemoved':
        break;
      default: break;
    }
  }

  setAllAddressesValid(flag) {
    this.isAllAddressesValid = true;
    this.sortArrayByLocation(flag);
  }

  allocateBinSticker() {
    this.apiService.getNextFreeStickers(this.siteId, this.totalBinsToAdd,this.binStartSticker).subscribe((response:any) => {
      this.binStartSticker = "";		
      for(let i=0 ; i < response.stickers_array.length;){
        this.finalArray.forEach((binRow, binRowIndex, binRowArray) => {
          if(binRow.bin_letter == undefined && binRow.bin_number == undefined){
            binRow.bin_letter = response.stickers_array[i].charAt(0);
            binRow.bin_number = response.stickers_array[i].substring(1);
            i++;
          }
        });
      }
      this.getTableData();
    });
  }

  displayData() {
    this.isAllAddressesValid = true;
    this.isShowTable = true;
    this.getTableData();
  }

  setContentVisibility(visibility: boolean) {
    if (visibility) {
      this.spinnerActive = false;
    }
    else {
      this.spinnerActive = true;
    }
  }

  async sortArrayByLocation(flag) {
    let tmpWaypoints = [];
    this.directionsService = new google.maps.DirectionsService();
    let waypoints = [];
    this.xlsxContentAsArray.forEach((binRow, binRowIndex, binRowArray) => {
      let location = new google.maps.LatLng(binRow.bin_lat, binRow.bin_lng);
      waypoints.push({
        location: location,
        stopover: true
      });

    });

    let totalWaypoints = _.chunk(waypoints, 23);
    tmpWaypoints = totalWaypoints[0];

    let origin = new google.maps.LatLng(this.siteObject.start_nav_point_lat, this.siteObject.start_nav_point_lng);
    let destination = new google.maps.LatLng(this.siteObject.finish_nav_point_lat, this.siteObject.finish_nav_point_lng);

    let tmpOrigin = origin;
    let tmpDestination = destination;

    totalWaypoints.forEach((chunk, chunkIndex, chunkArray) => {
      if (chunkArray.length > 1) {
        if (chunkIndex > 0) {
          tmpOrigin = new google.maps.LatLng(chunkArray[chunkIndex - 1][chunkArray[chunkIndex - 1].length - 1].location.lat(), chunkArray[chunkIndex - 1][chunkArray[chunkIndex - 1].length - 1].location.lng());

          if (chunkIndex === chunkArray.length - 1) {
            tmpDestination = destination;
          }
          else {
            tmpDestination = new google.maps.LatLng(chunk[chunk.length - 1].location.lat(), chunk[chunk.length - 1].location.lng());
          }

          tmpWaypoints = chunk;
        }
        else {
          tmpDestination = new google.maps.LatLng(chunk[chunk.length - 1].location.lat(), chunk[chunk.length - 1].location.lng());
        }
      }

      this.directionsConfigArray.push(
        {
          origin: tmpOrigin,
          destination: tmpDestination,
          waypoints: tmpWaypoints,
          travelMode: google.maps.TravelMode.DRIVING,
          avoidTolls: false,
          optimizeWaypoints: true
        }
      );
    });

      for (let i = 0; i < this.directionsConfigArray.length;) {

      let waypointsTmpArray = [];
      this.directionsConfigArray[i].waypoints.forEach((waypoint, index, array) => {
        waypointsTmpArray.push(waypoint.location.lat() + ',' + waypoint.location.lng());
      });

      let routeConfigApiElement = {
        origin: this.directionsConfigArray[i].origin.lat() + ',' + this.directionsConfigArray[i].origin.lng(),
        destination: this.directionsConfigArray[i].destination.lat() + ',' + this.directionsConfigArray[i].destination.lng(),
        waypoints: waypointsTmpArray
      };

      let response: any = 0;

      response = await this.apiService.getGoogleDirectionRoute(routeConfigApiElement);

      if (response) {
        if (response.status === "OK") {
          i++;
          this.directionsResponseArray.push(response.routes[0]);
        }
      }

    }

    if (this.directionsResponseArray.length === this.directionsConfigArray.length) {
      let indexOffset = 0;
      this.directionsResponseArray.forEach((directionResponseElement, directionResponseIndex, directionResponseArray) => {
        const { waypoint_order } = directionResponseElement;

        // waypoint_order.forEach((waypointElement, waypointIndex, waypointArray) => {
        //   this.sortedArray.push(this.xlsxContentAsArray[waypointElement + indexOffset]);
        // });

        let sortedArr = [];
        let closest = {};
        let xlsxContentAsArrayBackUp = [...this.xlsxContentAsArray];
        this.startPoint["bin_lat"] = this.siteObject.start_nav_point_lat;
        this.startPoint["bin_lng"] = this.siteObject.start_nav_point_lng;
    
        for(let i=0 ; i<this.xlsxContentAsArray.length ; i++){       
          closest = xlsxContentAsArrayBackUp.reduce((a, b) => this.distance(this.startPoint,a) < this.distance(this.startPoint,b) ? a : b);
          sortedArr.push(closest);
          this.startPoint = {...closest};        
          for(let i=0 ; i<xlsxContentAsArrayBackUp.length ; i++){
            if(xlsxContentAsArrayBackUp[i].bin_address.toString().trim() == this.startPoint["bin_address"].toString().trim() &&
            xlsxContentAsArrayBackUp[i].comments == this.startPoint["comments"] && xlsxContentAsArrayBackUp[i].num_of_bins == this.startPoint["num_of_bins"]
            && xlsxContentAsArrayBackUp[i].bin_lat == this.startPoint["bin_lat"] && xlsxContentAsArrayBackUp[i].bin_lng == this.startPoint["bin_lng"]){
              xlsxContentAsArrayBackUp.splice(i, 1);
              break;
            }
          }
        }
    
        this.sortedArray = [...sortedArr];
        
        indexOffset += waypoint_order.length;
      });

      this.isAllAddressesValid = true;
      this.sliceArrayFromClusters(flag);
    }
  }


  sliceArrayFromClusters(flag:string) {
    if(flag == "new"){
      this.finalArray = [];
    }
    this.sortedArray.forEach((clusterElement, clusterIndex, clusterArray) => {
      for (let i = 0; i < clusterElement.num_of_bins; i++) {
        let copiedClusterElement = Object.assign({}, clusterElement);
        this.finalArray.push(copiedClusterElement);
      }
    });
    this.allocateBinSticker();
  }

  addMarker(lat, lng, originalAddress, suggestedAddress) {

    let infoWindowContent = '<p><span class="info-window-address-title">' + this.translationsObj.SITE_MANAGMENT.ORIGINAL_ADDRESS + '</span>: ' + originalAddress + '</p><p><span class="info-window-address-title">' + this.translationsObj.SITE_MANAGMENT.SUGGESTED_ADDRESS + '</span>: ' + suggestedAddress + '</p>';

    let location = new google.maps.LatLng({
      lat: Number(lat),
      lng: Number(lng)
    });

    let marker = new google.maps.Marker({
      position: location,
      map: this.map,
      icon: '../../assets/images/bins/all/redNotOrdered.png'
    });

    let infowindow = new google.maps.InfoWindow({
      content: infoWindowContent,
    });

    marker.addListener("click", () => {
      infowindow.open(this.map, marker);
    });

    this.markersArray.push(marker);

    this.setCenterByBounds();
  }

  setCenterByBounds() {
    let bounds = new google.maps.LatLngBounds();
      for (const mm of this.markersArray) {
        if(mm.position){
          bounds.extend({ lat: mm.position.lat(), lng: mm.position.lng() });
        }
      }
      this.map.fitBounds(bounds);
  }

  onFileDropped($event) {
    this.isShowMap = true;
    this.dataSource = new MatTableDataSource<any>([]);
    this.file= $event[0];
    this.isShowDropZone = false;
    this.isShowDropZoneToAdd = false;
    this.uploadFile("new");
  }

  onFileDroppedToAdd($event) {
    this.isShowMap = true;
    this.dataSource = new MatTableDataSource<any>([]);
    this.file= $event[0];
    this.isShowDropZone = false;
    this.isShowDropZoneToAdd = false;
    this.uploadFile("add");
  }

  fileBrowseHandler(files) {
    this.isShowMap = true;
    this.file = files[0];
    this.dataSource = new MatTableDataSource<any>([]);
    this.isShowDropZone = false;
    this.isShowDropZoneToAdd = false;
    this.uploadFile("new");  
  }

  fileBrowseHandlerToAdd(files) {
    this.isShowMap = true;
    this.file = files[0];
    this.dataSource = new MatTableDataSource<any>([]);
    this.isShowDropZone = false;
    this.isShowDropZoneToAdd = false;
    this.uploadFile("add");  
  }

  updateTableData() {
    this.dataToDisplayPerPage = [];
    this.tableData = [];
    this.finalArray.forEach((binRow, binRowIndex, binsRowsArray) => {
      binRow.bin_address === "" ? binRow.isValidAddress = false : binRow.isValidAddress = true;
      
      let formmatedAddress = binRow.bin_address.split("__");
      let decodedAddress = formmatedAddress[0].replace(/ /g, '%20');
      let wazeStr = 'https://waze.com/ul?q=' + decodedAddress + '&navigate=yes';
      let tableElement = {
        binStickerId: binRow.bin_letter + binRow.bin_number,
        binAddress: binRow.bin_address.toString().trim(),
        binNeighborhood: binRow.bin_neigborhood == null ? '' : binRow.bin_neigborhood,
        binComments: binRow.comments,
        status_csv: '', 
        binIsNotHere: binRow.is_bin_not_here == 0 || binRow.is_bin_not_here === undefined ? 0 : 1,
        removeBinTableHeader: "",
        binWazeLink: wazeStr,
        linkToMappingTool: this.siteId + '/' + binRow.bin_letter + binRow.bin_number,
        linkToInstallationWizard:this.siteId + '/' + binRow.bin_letter + binRow.bin_number,
        isValidAddress: binRow.isValidAddress,
        bin_lat:binRow.bin_lat,
        bin_lng:binRow.bin_lng
      }

      this.tableData.push(tableElement);
    });

    const tempSearch = new MatTableDataSource<any>(this.tableData);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				this.dataToDisplayPerPage.push(tempSearch.filteredData[index]);
			}
		}

    if (this.dataToDisplayPerPage.length > 0) {
      this.dataSource = new MatTableDataSource(this.dataToDisplayPerPage);
      this.isShowTable = true;
    }
    else {
      this.dataSource = new MatTableDataSource([]);
      if(this.tempFilter != ''){
        this.isShowTable = true;
      }else{
        this.isShowTable = false;
      }
    }

    this.isShowProgressBar = false;

    this.setContentVisibility(true);
  }

  getTableData() {
    this.updateTableData();
    if(this.apiStore.getValue()["preMappingTableFilteredValue"] != ''){
      this.applyFilter(this.apiStore.getValue()["preMappingTableFilteredValue"]);
      this.filteredValue = this.apiStore.getValue()["preMappingTableFilteredValue"]; 
    }
  }

	onPageChange = (dataTableIndexes: any) => {
    this.startIndexTableData = dataTableIndexes.startIndex;
    this.endIndexTableData = dataTableIndexes.endIndex;

    const tableData = [];
    const tempSearch = new MatTableDataSource<any>(this.tableData);
    tempSearch.filter = this.tempFilter;
    this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
      if (tempSearch.filteredData[index]) {
        tableData.push(tempSearch.filteredData[index]);
      }
    }
    this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);	
  }

  getIssuesTableData() {
    const tableData = [];
    this.addressesIssuesArray.forEach((binRow, binRowIndex, binsRowsArray) => {
      let tableElement = {
        binAddress: binRow.bin_address,
        binComments: binRow.comments,
        isAddressValid: binRow.isAddressValid
      }

      tableData.push(tableElement);
    });

    this.addressesIssuesSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
  }

  compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

  sortData(sort: Sort) {
    this.dataToDisplayPerPage = [];
    const data = this.tableData.slice();
		if (!sort.active || sort.direction === '') {
			this.tableData = data;
			return;
		}
		this.tableData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
    const tempSearch = new MatTableDataSource<any>(this.tableData);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				this.dataToDisplayPerPage.push(tempSearch.filteredData[index]);
			}
		}
    if (this.dataToDisplayPerPage.length > 0) {
      this.dataSource = new MatTableDataSource(this.dataToDisplayPerPage);
    }
    else {
      this.dataSource = new MatTableDataSource([]);
    }
	}

  hebRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 מתוך ${length}`; }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} מתוך ${length}`;
  }

  applySticker(sticker: string) {
    if((/[a-zA-Z]/).test(sticker.charAt(0)) && /^\d$/.test(sticker.charAt(1)) && /^\d$/.test(sticker.charAt(2)) && /^\d$/.test(sticker.charAt(3))){
      this.binStartSticker = sticker;
    }
  }

  applyFilter(filterValue: string) {
    this.apiStore.update({ preMappingTableFilteredValue: filterValue });
    filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
    this.updateTableData();
  }

  calculateDistance(lat1, lng1, lat2, lng2) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lng1 - lng2;
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344;

    return dist
  }

  onAddressIssueAddressChange(event, element, index) {
    let address = event.target.value;

    let dataToSend = {
      address: address,
      localization: this.siteObject.localization,
      lang: this.currentLang
    }

    this.apiService.getGoogleAddressObjFromAddress(dataToSend).subscribe((googleAddressFromAddressResponse:any) => {
      let result = googleAddressFromAddressResponse.results[0];

      if (googleAddressFromAddressResponse.status === "OK") {
        this.addressesIssuesArray[index].isAddressValid = true;
        this.addressesIssuesArray[index].bin_address = address;
        this.addressesIssuesArray[index].bin_lat = result.geometry.location.lat;
        this.addressesIssuesArray[index].bin_lng = result.geometry.location.lng;
        this.getIssuesTableData();
      }
      else {
        this.addressesIssuesArray[index].isAddressValid = false;
        this.addressesIssuesArray[index].bin_address = address;
        this.getIssuesTableData();
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.INVALID_ADDRESS,func: 'getData'} });
      }
   });
  }

  updateValidAddress(index, element,flag) {
    let originalIndexInArray = this.addressesIssuesArray[index].original_index;
    this.addMarker(this.addressesIssuesArray[index].bin_lat, this.addressesIssuesArray[index].bin_lng, this.addressesIssuesArray[index].original_address, this.addressesIssuesArray[index].bin_address);
    this.xlsxContentAsArray[originalIndexInArray] = this.addressesIssuesArray[index];
    this.addressesIssuesArray.splice(index, 1);

    if (this.addressesIssuesArray.length === 0) {
      this.spinnerActive = true;
      this.setAllAddressesValid(flag);
    }
    else {
      this.getIssuesTableData();
    }

  }

  pushToApi() {
    this.spinnerExist = true;
    let preMappingApiObj = {
      site_id: this.siteId,
      pre_mapping_arr: this.finalArray,
      is_overwrite: 1
    };

    this.apiService.postPreMappingInfo(preMappingApiObj).subscribe((v:any) => {		
      this.spinnerExist = false;

      if (v != 1) {
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.FAIL_ADD,func: 'getData'} });
      }
      else {
        this.apiService.getPendingPreMappingInfoBySiteId(this.siteId);
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.SUCCESS_ADD,func: 'getData'} });
      }
   });
  }


  overwriteData() {
    this.isShowDropZone = !this.isShowDropZone;
    this.isShowDropZoneToAdd = false;    
  }

  addData() {
    this.isShowDropZoneToAdd = !this.isShowDropZoneToAdd;
    this.isShowDropZone = false;
  }

  reportBinIsNotHere(event) {
    let currentBinIsNotHereVal = 1;
    if (event.binIsNotHere == 1) {
      currentBinIsNotHereVal = 0
    }
    
    let secondMsg = event.binStickerId + ' ' + this.translationsObj.SITE_MANAGMENT.SUCCESS_BIN_IS_NOT_HERE;
    let func = 'reportBinIsNotHere';
    let binLetter = event.binStickerId.slice(0, 1);
    let binNumber = event.binStickerId.slice(1, 4);

    if (currentBinIsNotHereVal === 0) {
      secondMsg = event.binStickerId + ' ' + this.translationsObj.SITE_MANAGMENT.SUCCESS_BIN_IS_HERE;
      func = 'reportBinIsHere';
    }

    let binIsNotHereObject = {
      'site_id': this.siteId,
      "bin_letter": binLetter,
      "bin_number": binNumber,
      "is_bin_not_here": currentBinIsNotHereVal
    }

      this.apiService.setBinIsNotHere(binIsNotHereObject).subscribe((v:any) => {	
      
      if (v === 1) {
        event.binIsNotHere == 0 ? event.binIsNotHere = 1 : event.binIsNotHere = 0;
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: secondMsg, func: func} });
      }
      else {
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.FAIL_BIN_IS_NOT_HERE, func: 'reportBinIsNotHere'} });
      }
      this.apiService.getPendingPreMappingInfoBySiteId(this.siteId);
   });
  }

  askToRemoveBin(element) {
    let secondMsg = this.translationsObj.SITE_MANAGMENT.DELETE_BIN_CONFIRMIATION;
    this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: secondMsg, func: 'removeBin', isCanceldAllowed: true} });
    this.currentBinToRemove = element;
  }

  removeBin() {
    let binLetter = this.currentBinToRemove.binStickerId.slice(0, 1);
    let binNumber = this.currentBinToRemove.binStickerId.slice(1, 4);
    this.apiService.removeRecordFromPreMappingTable(binLetter, binNumber, this.siteId).subscribe((v:any) => {	
      if (v === 1) {
        this.apiService.getPendingPreMappingInfoBySiteId(this.siteId);
        let secondMsg = this.translationsObj.SITE_MANAGMENT.BIN + ' ' + this.currentBinToRemove.binStickerId + ' ' + this.translationsObj.SITE_MANAGMENT.HAS_BEEN_REMOVED;
        let func = 'binHasBeenRemoved';
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: secondMsg, func: func} });
      }
      else {
        let secondMsg = this.translationsObj.SITE_MANAGMENT.FAIL_BIN_REMOVAL;
        let func = 'binHasNotBeenRemoved';
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: secondMsg, func: func} });
      }
   });
  }

  insertNewRow() {
    if (!this.isEnabledEdit) return;
    this.isEnabledEdit = false;
    this.isPreMappingInfo = true;
    this.isShowTable = true;
    this.isCurrentlyInserting = true;
    this.insertBinSpinnerExist = true;

    this.apiService.getNextFreeStickers(this.siteId, 1,this.binStartSticker).subscribe((getNextPreMappingStickerResponse:any) => {	
      this.binStartSticker = "";			
      for(let i=0 ; i < getNextPreMappingStickerResponse.stickers_array.length;i++){
        this.insertBinSpinnerExist = false;
        let binLetter = getNextPreMappingStickerResponse.stickers_array[i].charAt(0);
        let binNumber = getNextPreMappingStickerResponse.stickers_array[i].substring(1);

        let newRow = {
          bin_letter: binLetter,
          bin_number: binNumber,
          bin_address: "",
          bin_lat: "",
          bin_lng: "",
          waze_link: "",
          comments: ""
        }

        let tableElement = {
          binIsNotHere: "",
          removeBinTableHeader: "",
          binStickerId: getNextPreMappingStickerResponse.stickers_array[i],
          binAddress: "",
          binNeighborhood: "",
          binComments: "",
          binWazeLink: "",
          linkToMappingTool: this.siteId + '/' + binLetter + binNumber,
          linkToInstallationWizard:this.siteId + '/' + binLetter + binNumber,
          isValidAddress: false
        }

        this.finalArray.unshift(newRow);
        this.tableData.unshift(tableElement);
        this.getTableData();
      }
   });
  }


  public handleAddressChange(address, element: any, index: number) {

    this.finalArray[index].bin_address = address.formatted_address;

    let preMappingArr = [];

    let binLetter = element.binStickerId.slice(0, 1);
    let binNumber = element.binStickerId.slice(1, 4);
    
    let newRow = {
      bin_letter: binLetter,
      bin_number: binNumber,
      bin_address: address.formatted_address,
      bin_lat: address.geometry.location.lat().toString(),
      bin_lng: address.geometry.location.lng().toString(),
      waze_link: "",
      comments: element.binComments
    };

    preMappingArr.push(newRow);

    let iPreMappingApiObj = {
      site_id: this.siteId,
      pre_mapping_arr: preMappingArr,
      is_overwrite: 0
    };

    this.apiService.postPreMappingInfo(iPreMappingApiObj).subscribe((postPreMappingResponse:any) => {
      this.isCurrentlyInserting = false;	
      this.spinnerExist = false;

      if (postPreMappingResponse === 1) {
        element.binAddress = address.formatted_address;
        element.isValidAddress = true;
      }
   });

  }

  onAddressInputBlur(event, element, index) {
    let address = event.target.value;

    let dataToSend = {
      address: address,
      localization: this.siteObject.localization,
      lang: this.currentLang
    }

    this.apiService.getGoogleAddressObjFromAddress(dataToSend).subscribe((googleAddressFromAddressResponse:any) => {
      if(googleAddressFromAddressResponse == null){return;}
      let result = googleAddressFromAddressResponse.results[0];

      if (googleAddressFromAddressResponse.status === "OK") {
        this.finalArray[index].bin_address = address;

        let preMappingArr = [];

        let binLetter = element.binStickerId.slice(0, 1);
        let binNumber = element.binStickerId.slice(1, 4);
        
        let newRow = {
          bin_letter: binLetter,
          bin_number: binNumber,
          bin_address: address,
          bin_lat: result.geometry.location.lat.toString(),
          bin_lng: result.geometry.location.lng.toString(),
          waze_link: "",
          comments: element.binComments
        };

        preMappingArr.push(newRow);

        let iPreMappingApiObj = {
          site_id: this.siteId,
          pre_mapping_arr: preMappingArr,
          is_overwrite: 0
        };

        this.apiService.postPreMappingInfo(iPreMappingApiObj).subscribe((postPreMappingResponse:any) => {
          this.isCurrentlyInserting = false;
          this.spinnerExist = false;

          if (postPreMappingResponse === 1) {
            element.binAddress = address;
            element.isValidAddress = true;

            this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.SUCCESS_ADDRESS,func: 'getData'} });
          }
          else {
            this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.FAIL_ADDRESS,func: 'getData'} });
          }
        });
      }
      else {
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.FAIL_ADDRESS,func: 'getData'} });
      }
   });
  }

  onBinCommentChange(event, element, index) {
    this.finalArray[index].comments = event.target.value;
    element.binComments = event.target.value;
    let binLetter = element.binStickerId.slice(0, 1);
    let binNumber = element.binStickerId.slice(1, 4);
    
    let binCommentObj = {
      site_id: this.siteId,
      bin_letter: binLetter,
      bin_number: binNumber,
      comments: event.target.value
    };

    this.apiService.updatePreMappingBinComment(binCommentObj).subscribe((v:any) => {	

      if (v != 1) {
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.FAIL_COMMENT,func: 'getData'} });
      }
      else {
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.SUCCESS_COMMENT,func: 'getData'} });
      }
   });
  }

  exportToCsv(){
    let dataToExport = null;
		dataToExport = _.cloneDeep(this.tableData);
    _.each(dataToExport, (item) => {
      item.status_csv = item.binIsNotHere == 1 ? this.translationsObj.SITE_MANAGMENT.NOT_EXIST : this.translationsObj.SITE_MANAGMENT.EXIST;
    });

		const header = 
		{
			'0' : this.translationsObj.SITE_MANAGMENT.STICKER_ID, 
			'1' : this.translationsObj.SITE_MANAGMENT.ADDRESS,
			'2' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
			'3' : this.translationsObj.SITE_MANAGMENT.COMMENTS,
      '4' : this.translationsObj.LIST_COLUMNS.STATUS_DESC
		}

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])

		let source = {
			flatData,
			header
		};	

		let result = source.flatData.map((details) => 
		Object.keys(details).reduce((a, b) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Pre Mapping");
		}else{
			TableUtil.exportArrayToExcel(result, "טרום מיפוי");
		}
  }

  handleClick(event) { 
    if(this.mapSpinnerActive){return;}
    let manageBin = document.getElementById(event.target.id);
    if(manageBin && manageBin.contains(event.target) && manageBin.getAttribute("name") == manageBin.getAttribute("id")){
      let binChosen = this.bins.filter(item => item.bin_id == manageBin.getAttribute("name"));
      let class_name = '';
      if(this.isMobile){
        class_name = 'popup-container-mobile';
      }else{
        class_name = 'bulkBins-container';
      }	
      this.dialog.open(ManageBinModalComponent, { panelClass: class_name , data: {chosenBins : binChosen[0], properties : this.displayedFields} });
    } 
	}
}
